export const plausibleEvent = (name: string): void => {
  fetch("https://plausible.io/api/event", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      url: window.location.href,
      domain: "apitally.io",
    }),
  });
};
