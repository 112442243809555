import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { ListEndpointGroupsWithTrafficResponseItem } from "../backend";
import DetailsModalContent from "../components/DetailsModalContent";
import { formatPeriod } from "../utils/period";

type EndpointGroupDetailsModalProps = {
  endpointGroup?: ListEndpointGroupsWithTrafficResponseItem;
  setEndpointGroup: React.Dispatch<React.SetStateAction<ListEndpointGroupsWithTrafficResponseItem | undefined>>;
  period: string;
};

function EndpointGroupDetailsModal({ endpointGroup, setEndpointGroup, period }: EndpointGroupDetailsModalProps) {
  return (
    <Modal show={endpointGroup !== undefined} onHide={() => setEndpointGroup(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>Endpoint group details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DetailsModalContent
          labelValueMap={{
            "ID": endpointGroup?.id,
            "Name": endpointGroup?.name,
            "Level": endpointGroup?.level,
            "Endpoints": endpointGroup?.endpoints.toLocaleString(),
            "Requests": (
              <>
                {endpointGroup?.requests.toLocaleString()}
                <span className="ms-2 small text-muted">(in last {formatPeriod(period)})</span>
              </>
            ),
            "Error rate":
              endpointGroup?.error_rate
                ?.toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
                .replace("%", " %") || "-",
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setEndpointGroup(undefined)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EndpointGroupDetailsModal;
