import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faHashtag, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { toast } from "react-toastify";

import { ListPlansResponseItem } from "../backend";
import Tooltip from "../components/Tooltip";
import { useGlobal } from "../contexts/GlobalContext";
import "./PlansModal.scss";

export function PlanLimitsItems({ plan }: { plan: ListPlansResponseItem }) {
  const limitIcon = <FontAwesomeIcon icon={faHashtag} className="text-primary me-icon" />;
  const helpIcon = <FontAwesomeIcon icon={faQuestionCircle} className="text-very-muted small ms-icon" />;

  return (
    <>
      <li>
        {limitIcon}
        {plan.limit_apps || "Unlimited"} app{plan.limit_apps !== 1 ? "s" : ""}
      </li>
      <li>
        {limitIcon}
        {plan.limit_users || "Unlimited"} user{plan.limit_users !== 1 ? "s" : ""}
      </li>
      <li>
        {limitIcon}
        {plan.limit_instances || "Unlimited"} app instance{plan.limit_instances !== 1 ? "s" : ""}
        <Tooltip tooltip="Each running replica of your apps that sends data to Apitally counts towards this limit.">
          {helpIcon}
        </Tooltip>
      </li>
      <li>
        {limitIcon}
        {plan.limit_consumers || "Unlimited"} consumer{plan.limit_consumers !== 1 ? "s" : ""}
        <Tooltip tooltip="Maximum number of unique API consumers your apps may identify within 24 hours.">
          {helpIcon}
        </Tooltip>
      </li>
      <li>
        {limitIcon}
        {plan.limit_alert_rules || "Unlimited"} custom alert{plan.limit_users !== 1 ? "s" : ""}
      </li>
    </>
  );
}

export function PlanFeatureItems({ plan }: { plan: ListPlansResponseItem }) {
  const featureIcon = <FontAwesomeIcon icon={faCheck} className="text-primary me-icon" />;
  const helpIcon = <FontAwesomeIcon icon={faQuestionCircle} className="text-very-muted small ms-icon" />;

  const planFeatures = [];
  if (plan.monthly_request_log_quota && plan.monthly_request_log_quota > 0) {
    planFeatures.push({
      name: "Request log",
      tooltip: `Up to ${plan.monthly_request_log_quota.toLocaleString()} logged API\u00a0requests per month.`,
    });
  }
  if (plan.feature_health_checks) {
    planFeatures.push({
      name: "Health checks",
      tooltip: "Monitor your API's availability by sending requests to a health check endpoint every minute.",
    });
  }
  if (plan.feature_chat_integration) {
    planFeatures.push({
      name: "Slack or Teams integration",
      tooltip: "Receive alert notifications in your Slack or Microsoft Teams workspace.",
    });
  }
  if (plan.feature_data_export) {
    planFeatures.push({
      name: "Data export",
      tooltip: "Export data as CSV.",
    });
  }
  if (plan.feature_api_access) {
    planFeatures.push({
      name: "API access",
      tooltip: "Integrate Apitally with your own systems using our API.",
    });
  }

  return (
    <>
      {planFeatures.map((feature) => {
        const tooltip = feature.tooltip ? <Tooltip tooltip={feature.tooltip}>{helpIcon}</Tooltip> : undefined;
        return (
          <li key={feature.name}>
            {featureIcon}
            {feature.name}
            {tooltip}
          </li>
        );
      })}
    </>
  );
}

type PlansModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
};

export default function PlansModal({ show, setShow }: PlansModalProps) {
  const { backendClient, activeTeam, teamPlan, isTeamOwner, userId } = useGlobal();
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    setLoading(0);
  }, [show]);

  const { data: plans } = useQuery({
    queryKey: ["plans"],
    queryFn: () => backendClient!.plans.listPlans(),
    enabled: !!backendClient,
    staleTime: 60 * 60 * 1000,
  });
  const teamQueryParams = { teamId: activeTeam?.id || 0 };
  const { data: team } = useQuery({
    queryKey: ["team", teamQueryParams],
    queryFn: () => backendClient!.teams.getTeam(teamQueryParams),
    enabled: !!backendClient && !!activeTeam,
  });
  const notBillingUser = teamPlan?.subscription?.payment_platform && team?.billing_user?.id !== userId;

  const checkout = async (plan: ListPlansResponseItem) => {
    if (backendClient && activeTeam) {
      setLoading(plan.id);
      const promise = backendClient.stripe.createCheckoutSession({ teamId: activeTeam.id, planId: plan.id });
      toast.promise(promise, {
        pending: "Creating checkout session...",
        success: "Redirecting to checkout page...",
        error: "Failed to create checkout session.",
      });
      window.location.href = await promise;
    }
  };

  const upgrade = async (plan: ListPlansResponseItem) => {
    if (backendClient && activeTeam && teamPlan?.subscription) {
      setLoading(plan.id);
      const promise = backendClient.stripe.createCustomerPortalSession({
        teamId: activeTeam.id,
        planId: plan.id,
        flowType: "subscription_update_confirm",
      });
      toast.promise(promise, {
        pending: "Creating customer portal session...",
        success: "Redirecting to customer portal...",
        error: "Failed to create customer portal session.",
      });
      window.location.href = await promise;
    }
  };

  const checkoutOrUpgrade = async (plan: ListPlansResponseItem) => {
    if (teamPlan?.subscription?.payment_platform) {
      await upgrade(plan);
    } else {
      await checkout(plan);
    }
  };

  const filterPlans = (plans?: ListPlansResponseItem[]) => {
    if (!plans) {
      return [];
    }
    let found = false;
    const result = [];
    for (const plan of plans) {
      if (found || plan.id === teamPlan?.id) {
        found = true;
        result.push(plan);
      }
    }
    return result;
  };

  const formatPrice = (price: number | string) => {
    return Number(price).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
  };

  const planItems = filterPlans(plans).map((plan) => {
    const price = (
      <>
        <span className="fs-2">{formatPrice(plan.price_per_month)}</span>
        <span className="ms-2 text-muted small">/ mo</span>
      </>
    );
    const upgradeButton =
      plan.id !== teamPlan?.id ? (
        <Button
          variant="primary"
          className="float-end"
          style={{ width: "6rem" }}
          disabled={!isTeamOwner || notBillingUser || loading > 0}
          onClick={() => checkoutOrUpgrade(plan)}
        >
          {loading !== plan.id && "Upgrade"}
          {loading === plan.id && <FontAwesomeIcon icon={faSpinner} spin />}
        </Button>
      ) : (
        <Button variant="light" className="float-end" disabled style={{ width: "6rem" }}>
          Current
        </Button>
      );

    return (
      <Card key={plan.id} className={plan.id !== teamPlan?.id ? "border-primary" : undefined}>
        <Card.Body>
          <Container className="p-0">
            <Row>
              <Col xs={12} lg={8}>
                <div className="fw-bold">{plan.name}</div>
                <div className="mt-1">{plan.description}</div>
              </Col>
              <Col lg={2} className="d-none d-lg-block">
                {price}
              </Col>
              <Col lg={2} className="d-none d-lg-block">
                {upgradeButton}
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="small mt-4 features">
                  <PlanLimitsItems plan={plan} />
                </ul>
                {plan.feature_health_checks && (
                  <ul className="small mt-2 features">
                    <PlanFeatureItems plan={plan} />
                  </ul>
                )}
              </Col>
            </Row>
            <Row className="d-flex d-lg-none mt-4">
              <Col xs={6}>{price}</Col>
              <Col xs={6}>{upgradeButton}</Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  });

  let disabledAlert;
  if (notBillingUser) {
    disabledAlert = <>Only the team's billing contact ({team?.billing_user?.name}) can upgrade the plan.</>;
  } else if (!isTeamOwner) {
    disabledAlert = <>Only team members with the owner role can upgrade a team's plan.</>;
  }

  return (
    <Modal className="PlansModal" size="lg" show={show && teamPlan?.upgradable} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Upgrade plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="small text-muted mb-4">Upgrade your team's plan for premium features and higher limits.</p>
        {disabledAlert && <Alert variant="danger">{disabledAlert}</Alert>}
        <Stack gap={3}>{planItems}</Stack>
        <p className="small text-muted mt-4 mb-0">
          Please <a href="mailto:support@apitally.io">contact us</a> if you have any questions or would like to discuss
          your specific requirements.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
