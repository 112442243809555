import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleRight,
  faChartSimple,
  faFingerprint,
  faHashtag,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import ErrorsBarChart from "../charts/ErrorsBarChart";
import ErrorsByConsumerBarChart from "../charts/ErrorsByConsumerBarChart";
import FilterBadges from "../components/FilterBadges";
import Method from "../components/Method";
import MetricChip from "../components/MetricChip";
import ModalSection from "../components/ModalSection";
import Path from "../components/Path";
import StatusCodeBadge from "../components/StatusCodeBadge";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { RequestLogTableWithFilters } from "../tables/RequestLogTable";
import ServerErrorsTable from "../tables/ServerErrorsTable";
import ValidationErrorsTable from "../tables/ValidationErrorsTable";
import { EndpointError } from "../types/Endpoint";
import { checkClientVersion } from "../utils/clientVersion";
import { formatRelativeDateTime } from "../utils/datetime";
import "./EndpointErrorModal.css";

type EndpointErrorModalTabProps = {
  endpointError: EndpointError;
};

function EndpointErrorModalOccurencesTab({ endpointError }: EndpointErrorModalTabProps) {
  const { backendClient } = useGlobal();
  const { app, period, env, consumerId, consumerGroupId } = useFilters();

  const errorDetailsQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    method: endpointError.method,
    path: endpointError.path,
    statusCode: endpointError.status_code,
    period,
  };
  const errorDetailsQuery = useQuery({
    queryKey: ["errorMetrics", errorDetailsQueryParams],
    queryFn: () => backendClient!.errors.getErrorDetails(errorDetailsQueryParams),
    enabled: !!backendClient && !!app,
    placeholderData: undefined,
  });
  const lastOccurrence = errorDetailsQuery.isSuccess
    ? DateTime.fromISO(errorDetailsQuery.data.last_timestamp)
    : undefined;

  return (
    <>
      {endpointError.expected && (
        <Alert variant="danger">
          <span className="fw-bold">This error is marked as expected.</span>
          <br />
          It's excluded from error rate calculations, and requests with this response status code are considered
          successful.
        </Alert>
      )}
      <ModalSection title="Summary">
        <div className="mb-n4 me-n4">
          <MetricChip
            label="Total occurrences"
            icon={faHashtag}
            value={errorDetailsQuery.data?.request_count.toLocaleString() || "-"}
          />
          {!consumerId && (
            <MetricChip
              label="Affected consumers"
              icon={faFingerprint}
              value={errorDetailsQuery.data?.affected_consumers.toLocaleString() || "-"}
            />
          )}
          <MetricChip
            label="Last occurrence"
            icon={faClock}
            value={formatRelativeDateTime(lastOccurrence, true, false) || "-"}
            title={lastOccurrence?.toLocaleString(DateTime.DATETIME_FULL)}
          />
        </div>
      </ModalSection>
      <ModalSection title="Occurrences over time" className="mt-6">
        <div className="border rounded p-4">
          <ErrorsBarChart endpointError={endpointError} displayTitle={false} />
        </div>
      </ModalSection>
      {!consumerId && (
        <ModalSection
          title="Occurrences per consumer"
          titleExtra={
            errorDetailsQuery.data?.affected_consumers && errorDetailsQuery.data.affected_consumers > 10
              ? `top 10 of ${errorDetailsQuery.data.affected_consumers.toLocaleString()}`
              : undefined
          }
          className="mt-6"
        >
          <div className="border rounded p-4">
            <ErrorsByConsumerBarChart endpointError={endpointError} />
          </div>
        </ModalSection>
      )}
      {endpointError.id && (
        <ModalSection title="Most recent occurrences" className="mt-6">
          <RequestLogTableWithFilters
            endpointId={endpointError.id}
            statusCode={endpointError.status_code.toString()}
            limit={5}
          />
        </ModalSection>
      )}
    </>
  );
}

function EndpointErrorModalDetailsTab({ endpointError }: EndpointErrorModalTabProps) {
  const { isDemo } = useGlobal();
  const { app, env } = useFilters();

  const showUpdateClientAlert =
    endpointError.status_code === 500 && !isDemo && !checkClientVersion({ app, env, python: "0.8.0", js: "0.4.0" });

  return (
    <>
      {endpointError.status_code < 500 && (
        <ModalSection title="Validation errors">
          <div className="px-4">
            <ValidationErrorsTable endpoint={endpointError} />
          </div>
        </ModalSection>
      )}
      {endpointError.status_code === 500 && (
        <>
          {showUpdateClientAlert && (
            <Alert variant="danger" className="mb-6">
              Please update the Apitally SDK in your project to capture exception data.
            </Alert>
          )}
          <ModalSection title="Unhandled exceptions">
            <div className="px-4">
              <ServerErrorsTable endpoint={endpointError} />
            </div>
          </ModalSection>
        </>
      )}
    </>
  );
}

type EndpointErrorModalProps = {
  endpointError?: EndpointError;
  onHide: () => void;
};

function EndpointErrorModal({ endpointError, onHide }: EndpointErrorModalProps) {
  const [activeTab, setActiveTab] = useState("occurrences");
  const { app, env } = useFilters();

  const selectTab = useCallback((key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  }, []);

  useEffect(() => {
    setActiveTab("occurrences");
  }, [endpointError]);

  if (!app || !endpointError) {
    return <></>;
  }

  const tabs = [
    {
      eventKey: "occurrences",
      title: "Occurrences",
      icon: faChartSimple,
      content: <EndpointErrorModalOccurencesTab endpointError={endpointError} />,
    },
    {
      eventKey: "details",
      title: "Details",
      icon: faTimesCircle,
      content: [400, 422, 500].includes(endpointError.status_code) && (
        <EndpointErrorModalDetailsTab endpointError={endpointError} />
      ),
    },
  ].filter((tab) => tab.content);

  return (
    <Modal className="ErrorModal tabs" size="xl" show={!!endpointError} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="app-env-name">
            {app.name}
            {env && (
              <>
                <FontAwesomeIcon icon={faAngleRight} className="ms-2 me-2 text-very-muted small" />
                {env.name}
              </>
            )}
            <FontAwesomeIcon icon={faAngleRight} className="ms-2 me-2 text-very-muted small" />
            <span className="text-nowrap">
              <Method method={endpointError.method} /> <Path path={endpointError.path} />
            </span>
          </div>
          <div className="d-flex flex-row flex-nowrap justify-content-start align-items-center gap-3 mt-1">
            <StatusCodeBadge code={endpointError.status_code} />
            <span>{endpointError.status_text}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-1 px-0">
        <Tab.Container activeKey={activeTab} onSelect={selectTab}>
          <Nav variant="tabs">
            {tabs.map((tab) => (
              <Nav.Item key={tab.eventKey} title={tab.title}>
                <Nav.Link eventKey={tab.eventKey}>
                  <FontAwesomeIcon icon={tab.icon} fixedWidth className="d-inline d-lg-none" />
                  <span className="d-none d-lg-inline">{tab.title}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            <div className="p-4 pb-0">
              <FilterBadges showAll hideEndpointFilters />
            </div>
            {tabs.map((tab) => (
              <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey}>
                {tab.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
}

export default EndpointErrorModal;
