import { useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { stringToArray } from "cron-converter";
import { Field, FieldProps, Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { default as BootstrapForm } from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";

import { ListAppsResponseItem } from "../backend";
import CollapsibleModalBody from "../components/CollapsibleModalBody";
import Select, { SelectProps } from "../components/Select";
import Tooltip from "../components/Tooltip";
import UpgradePlanAlert from "../components/UpgradePlanAlert";
import { useGlobal } from "../contexts/GlobalContext";

type FilterFieldProps = {
  appId?: number;
  appEnvId?: number;
} & Omit<SelectProps<number>, "options" | "placeholder" | "isSearchable" | "isClearable">;

function ConsumerFilterSelect({ appId, appEnvId, ...selectProps }: FilterFieldProps) {
  const { apps, backendClient } = useGlobal();

  const appEnv = apps?.find((app) => app.id === appId)?.envs.find((env) => env.id === appEnvId)?.slug;
  const queryParams = { appId: appId || 0, appEnv };
  const { data } = useQuery({
    queryKey: ["consumers", queryParams],
    queryFn: () => backendClient!.consumers.listConsumers(queryParams),
    enabled: !!backendClient && !!appId,
  });
  const options = data?.map((consumer) => ({ label: consumer.name, value: consumer.id })) || [];

  return <Select options={options} placeholder="All consumers" isSearchable isClearable {...selectProps} />;
}

function ConsumerGroupFilterSelect({ appId, appEnvId, ...selectProps }: FilterFieldProps) {
  const { apps, backendClient } = useGlobal();

  const appEnv = apps?.find((app) => app.id === appId)?.envs.find((env) => env.id === appEnvId)?.slug;
  const queryParams = { appId: appId || 0, appEnv };
  const { data } = useQuery({
    queryKey: ["consumerGroups", queryParams],
    queryFn: () => backendClient!.consumers.listConsumerGroups(queryParams),
    enabled: !!backendClient && !!appId,
  });
  const options = data?.map((consumerGroup) => ({ label: consumerGroup.name, value: consumerGroup.id })) || [];

  return <Select options={options} placeholder="All consumer groups" isSearchable isClearable {...selectProps} />;
}

function EndpointFilterSelect({ appId, appEnvId, ...selectProps }: FilterFieldProps) {
  const { backendClient } = useGlobal();

  const queryParams = { appId: appId || 0, appEnvId };
  const { data } = useQuery({
    queryKey: ["endpoints", queryParams],
    queryFn: () => backendClient!.endpoints.listEndpoints(queryParams),
    enabled: !!backendClient && !!appId,
  });
  const options = data?.map((endpoint) => ({ label: `${endpoint.method} ${endpoint.path}`, value: endpoint.id })) || [];

  return <Select options={options} placeholder="All endpoints" isSearchable isClearable {...selectProps} />;
}

function EndpointGroupFilterSelect({ appId, appEnvId, ...selectProps }: FilterFieldProps) {
  const { backendClient } = useGlobal();

  const queryParams = { appId: appId || 0, appEnvId };
  const { data } = useQuery({
    queryKey: ["endpointGroups", queryParams],
    queryFn: () => backendClient!.endpoints.listEndpointGroups(queryParams),
    enabled: !!backendClient && !!appId,
  });
  const options = data?.map((endpointGroup) => ({ label: endpointGroup.name, value: endpointGroup.id })) || [];

  return <Select options={options} placeholder="All endpoint groups" isSearchable isClearable {...selectProps} />;
}

function TimezoneSelect({ ...selectProps }: Omit<SelectProps<string>, "options" | "isSearchable">) {
  const options = useMemo(() => {
    const date = new Date();
    const options = Intl.supportedValuesOf("timeZone").map((timeZone) => {
      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone,
        timeZoneName: "short",
      });
      const parts = formatter.formatToParts(date);
      const zoneName = parts.find((p) => p.type === "timeZoneName")?.value;
      return {
        value: timeZone,
        label: `${timeZone} (${zoneName})`,
      };
    });
    options.unshift({ label: "UTC", value: "UTC" });
    return options;
  }, []);

  return <Select<string> options={options} isSearchable {...selectProps} />;
}

type PeriodSelectProps = {
  multiplesOf?: number | null;
  includeZero?: boolean;
  includeMinusOne?: boolean;
} & Omit<SelectProps<number>, "options" | "isSearchable">;

function PeriodSelect({
  multiplesOf,
  includeZero = false,
  includeMinusOne = false,
  ...selectProps
}: PeriodSelectProps) {
  let options = [
    { label: "1 minute", value: 1 },
    { label: "2 minutes", value: 2 },
    { label: "3 minutes", value: 3 },
    { label: "5 minutes", value: 5 },
    { label: "10 minutes", value: 10 },
    { label: "15 minutes", value: 15 },
    { label: "20 minutes", value: 20 },
    { label: "30 minutes", value: 30 },
    { label: "1 hour", value: 60 },
    { label: "2 hours", value: 120 },
    { label: "3 hours", value: 180 },
    { label: "6 hours", value: 360 },
    { label: "12 hours", value: 720 },
    { label: "24 hours", value: 1440 },
  ];
  if (includeZero) {
    options.unshift({ label: "Immediately", value: 0 });
  }
  if (includeMinusOne) {
    options.unshift({ label: "Never", value: -1 });
  }
  if (multiplesOf) {
    options = options.filter((option) => option.value % multiplesOf === 0 || option.value === -1);
    if (multiplesOf === 2) {
      options.push({ label: "4 minutes", value: 4 });
      options.push({ label: "6 minutes", value: 6 });
      options.push({ label: "8 minutes", value: 8 });
    } else if (multiplesOf === 3) {
      options.push({ label: "6 minutes", value: 6 });
      options.push({ label: "9 minutes", value: 9 });
      options.push({ label: "12 minutes", value: 12 });
    }
    options.sort((a, b) => a.value - b.value);
  }

  return <Select options={options} isSearchable {...selectProps} />;
}

const getThresholdUnitOptions = (metric: string) => {
  if (metric === "error_rate") {
    return ["%"];
  } else if (metric === "data_transferred" || metric === "data_received" || metric === "data_sent") {
    return ["KB", "MB", "GB"];
  } else if (metric === "response_time_p50" || metric === "response_time_p75" || metric === "response_time_p95") {
    return ["ms"];
  }
};

const getDefaultThresholdUnit = (metric: string) => {
  if (metric === "error_rate") {
    return "%";
  } else if (metric === "data_transferred" || metric === "data_received" || metric === "data_sent") {
    return "KB";
  } else if (metric === "response_time_p50" || metric === "response_time_p75" || metric === "response_time_p95") {
    return "ms";
  }
};

type AlertRuleFormValues = {
  name: string;
  description?: string | null;
  app?: ListAppsResponseItem;
  app_id?: number;
  app_env_id?: number;
  metric: string;
  condition: string;
  threshold: number;
  threshold_unit?: string | null;
  threshold_unit_options?: string[];
  period_minutes: number;
  frequency_minutes?: number | null;
  cron_expression?: string | null;
  timezone?: string | null;
  notify_triggered_after_minutes: number;
  notify_resolved_after_minutes: number;
  filter_consumer_id?: number | null;
  filter_consumer_group_id?: number | null;
  filter_endpoint_id?: number | null;
  filter_endpoint_group_id?: number | null;
};

type AlertRuleFormModalProps = {
  alertRuleId?: number;
  onHide: () => void;
};

function AlertRuleFormModal({ alertRuleId, onHide }: AlertRuleFormModalProps) {
  const { activeTeam, apps, activeApp, backendClient, teamPlan, refreshTeamPlan, isTeamAdmin, isDemo } = useGlobal();
  const [initialFormValues, setInitialFormValues] = useState<AlertRuleFormValues>();
  const queryClient = useQueryClient();
  const alertRuleLimitReached =
    !!teamPlan?.limits.alert_rules.allowed && teamPlan.limits.alert_rules.actual >= teamPlan.limits.alert_rules.allowed;
  const disableForm = alertRuleId === 0 && alertRuleLimitReached;

  const queryParams = { alertRuleId: Math.abs(alertRuleId || 0) };
  const query = useQuery({
    queryKey: ["alertRule", queryParams],
    queryFn: () => backendClient!.alerts.getAlertRule(queryParams),
    enabled: !!backendClient && !!alertRuleId,
    placeholderData: undefined,
  });

  useEffect(() => {
    if (alertRuleId === 0 && apps) {
      const preselectedApp = activeApp || apps?.[0];
      setInitialFormValues({
        name: "",
        description: "",
        app: preselectedApp,
        app_id: preselectedApp?.id,
        app_env_id: preselectedApp?.envs[0].id,
        metric: "requests_per_minute",
        condition: ">",
        threshold: 100,
        threshold_unit: null,
        period_minutes: 15,
        frequency_minutes: 5,
        cron_expression: "",
        timezone: "UTC",
        notify_triggered_after_minutes: 0,
        notify_resolved_after_minutes: 0,
        filter_consumer_id: null,
        filter_consumer_group_id: null,
        filter_endpoint_id: null,
        filter_endpoint_group_id: null,
      });
    } else if (alertRuleId && query.data) {
      setInitialFormValues({
        name: query.data.name + (alertRuleId < 0 ? " (copy)" : ""),
        description: query.data.description || "",
        app: apps?.find((app) => app.id === query.data.app.id),
        app_id: query.data.app.id,
        app_env_id: query.data.app_env.id,
        metric: query.data.metric,
        condition: query.data.condition,
        threshold: query.data.threshold,
        threshold_unit: query.data.threshold_unit,
        threshold_unit_options: getThresholdUnitOptions(query.data.metric),
        period_minutes: query.data.period_minutes,
        frequency_minutes: query.data.frequency_minutes || 1,
        cron_expression: query.data.cron_expression || "",
        timezone: query.data.timezone || "",
        notify_triggered_after_minutes: query.data.notify_triggered_after_minutes,
        notify_resolved_after_minutes: query.data.notify_resolved_after_minutes,
        filter_consumer_id: query.data.filter_consumer?.id,
        filter_consumer_group_id: query.data.filter_consumer_group?.id,
        filter_endpoint_id: query.data.filter_endpoint?.id,
        filter_endpoint_group_id: query.data.filter_endpoint_group?.id,
      });
    } else {
      setInitialFormValues(undefined);
    }
  }, [alertRuleId, apps, query.data]);

  const validate = (values: AlertRuleFormValues) => {
    values.name = values.name.trim();
    values.description = values.description?.trim();
    values.cron_expression = values.cron_expression?.trim();
    const errors: {
      name?: string;
      threshold?: string;
      cron_expression?: string;
    } = {};
    if (!values.name) {
      errors.name = "Required";
    } else if (values.name.length < 2) {
      errors.name = "Name must be at least 2 characters";
    }
    if (values.threshold < 0) {
      errors.threshold = "Must be greater than or equal to 0";
    }
    if (values.cron_expression) {
      try {
        stringToArray(values.cron_expression);
      } catch (e) {
        errors.cron_expression = "Invalid expression";
      }
    }
    return errors;
  };

  const createOrUpdateAlertRule = async (values: AlertRuleFormValues) => {
    if (backendClient && activeTeam && alertRuleId !== undefined) {
      if (alertRuleId > 0) {
        const promise = backendClient.alerts.updateAlertRule({
          alertRuleId,
          requestBody: {
            name: values.name,
            description: values.description || null,
            metric: values.metric,
            condition: values.condition,
            threshold: values.threshold,
            threshold_unit: values.threshold_unit || null,
            period_minutes: values.period_minutes,
            frequency_minutes: values.frequency_minutes || null,
            cron_expression: values.cron_expression || null,
            timezone: values.timezone || null,
            notify_triggered_after_minutes: values.notify_triggered_after_minutes || 0,
            notify_resolved_after_minutes: values.notify_resolved_after_minutes || 0,
            filter_consumer_id: values.filter_consumer_id || null,
            filter_consumer_group_id: values.filter_consumer_group_id || null,
            filter_endpoint_id: values.filter_endpoint_id || null,
            filter_endpoint_group_id: values.filter_endpoint_group_id || null,
          },
        });
        toast.promise(promise, {
          pending: "Updating alert...",
          success: "Alert updated!",
          error: "Failed to update alert.",
        });
        await promise;
      } else {
        const promise = backendClient.alerts.createAlertRule({
          requestBody: {
            app_env_id: values.app_env_id || 0,
            name: values.name,
            description: values.description || null,
            metric: values.metric,
            condition: values.condition,
            threshold: values.threshold,
            threshold_unit: values.threshold_unit || null,
            period_minutes: values.period_minutes,
            frequency_minutes: values.frequency_minutes || null,
            cron_expression: values.cron_expression || null,
            timezone: values.timezone || null,
            notify_triggered_after_minutes: values.notify_triggered_after_minutes || 0,
            notify_resolved_after_minutes: values.notify_resolved_after_minutes || 0,
            filter_consumer_id: values.filter_consumer_id || null,
            filter_consumer_group_id: values.filter_consumer_group_id || null,
            filter_endpoint_id: values.filter_endpoint_id || null,
            filter_endpoint_group_id: values.filter_endpoint_group_id || null,
          },
        });
        toast.promise(promise, {
          pending: "Creating alert...",
          success: "Alert created!",
          error: "Failed to create alert.",
        });
        await promise;
      }
    }
  };

  if (!initialFormValues) {
    return <></>;
  }

  const metricOptions = [
    {
      label: "Traffic",
      options: [
        { label: "Requests per minute", value: "requests_per_minute" },
        { label: "Requests", value: "requests" },
        { label: "Consumers", value: "consumers" },
        { label: "Data transferred", value: "data_transferred" },
        { label: "Data received", value: "data_received" },
        { label: "Data sent", value: "data_sent" },
      ],
    },
    {
      label: "Errors",
      options: [
        { label: "Errors", value: "errors" },
        { label: "Client errors", value: "client_errors" },
        { label: "Server errors", value: "server_errors" },
        { label: "Error rate", value: "error_rate" },
      ],
    },
    {
      label: "Performance",
      options: [
        { label: "Response time p50", value: "response_time_p50" },
        { label: "Response time p75", value: "response_time_p75" },
        { label: "Response time p95", value: "response_time_p95" },
        { label: "Apdex score", value: "apdex_score" },
      ],
    },
  ];
  const conditionOptions = [
    { label: "greater than", value: ">" },
    { label: "greater than or equal to", value: ">=" },
    { label: "less than or equal to", value: "<=" },
    { label: "less than", value: "<" },
  ];

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validate}
      onSubmit={async (values, { setSubmitting }) => {
        if (!isTeamAdmin) {
          return;
        }
        try {
          await createOrUpdateAlertRule(values);
          queryClient.invalidateQueries({ queryKey: ["alertRule", { alertRuleId }] });
          queryClient.invalidateQueries({ queryKey: ["alertRules", { teamId: activeTeam?.id }] });
          queryClient.invalidateQueries({ queryKey: ["alertRuleTimelineChart", { alertRuleId }] });
          refreshTeamPlan();
          onHide();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit, isSubmitting, resetForm }) => (
        <Modal
          className="AlertRuleFormModal"
          size="lg"
          show={alertRuleId !== undefined}
          onHide={onHide}
          onExited={() => resetForm()}
          backdrop={Object.keys(touched).length > 0 ? "static" : true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{alertRuleId && alertRuleId > 0 ? "Edit alert" : "Create alert"}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              {disableForm && alertRuleLimitReached && (
                <UpgradePlanAlert>
                  Your team has reached the maximum number of allowed alerts in the {teamPlan?.name}.
                  <br />
                  Please upgrade your team's plan to create more alerts and enjoy additional benefits!
                </UpgradePlanAlert>
              )}

              <BootstrapForm.Group controlId="formName">
                <BootstrapForm.Label>
                  Name <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="name">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Something unexpected happened"
                      maxLength={64}
                      autoComplete="off"
                      isInvalid={!!errors.name && !!touched.name}
                      disabled={disableForm}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.name}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formDescription" className="mt-4">
                <BootstrapForm.Label>Description</BootstrapForm.Label>
                <Field name="description">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      as="textarea"
                      rows={3}
                      placeholder="A short summary of what's going on. Will be included in notifications."
                      maxLength={2048}
                      autoComplete="off"
                      isInvalid={!!errors.description && !!touched.description}
                      disabled={disableForm}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.description}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
              <Row>
                <BootstrapForm.Group as={Col} lg={6} controlId="formApp" className="mt-4">
                  <BootstrapForm.Label>
                    App <span className="text-danger">*</span>
                  </BootstrapForm.Label>
                  <Field name="app_id">
                    {({ field }: FieldProps<number>) => (
                      <Select
                        options={apps?.map((app) => ({ label: app.name, value: app.id }))}
                        isInvalid={!!errors.app_id && !!touched.app_id}
                        isDisabled={!!alertRuleId || disableForm}
                        onChangeExtra={(newValue) => {
                          const app = apps?.find((app) => app.id === newValue?.value);
                          setFieldValue("app", app);
                          setFieldValue("app_env_id", app?.envs[0].id);
                        }}
                        {...field}
                      />
                    )}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">{errors.app_id}</BootstrapForm.Control.Feedback>
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} lg={6} controlId="formAppEnv" className="mt-4">
                  <BootstrapForm.Label>
                    Environment <span className="text-danger">*</span>
                  </BootstrapForm.Label>
                  <Field name="app_env_id">
                    {({ field }: FieldProps<number>) => (
                      <Select
                        options={values.app?.envs.map((env) => ({ label: env.name, value: env.id }))}
                        isInvalid={!!errors.app_env_id && !!touched.app_env_id}
                        isDisabled={!!alertRuleId || disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">{errors.app_env_id}</BootstrapForm.Control.Feedback>
                </BootstrapForm.Group>
              </Row>
              <Row>
                <BootstrapForm.Group as={Col} lg={4} controlId="formMetric" className="mt-4">
                  <BootstrapForm.Label>
                    Metric <span className="text-danger">*</span>
                  </BootstrapForm.Label>
                  <Field name="metric">
                    {({ field }: FieldProps<string>) => (
                      <Select
                        options={metricOptions}
                        isInvalid={!!errors.metric && !!touched.metric}
                        isDisabled={disableForm}
                        onChangeExtra={(newValue) => {
                          const metric = newValue?.value;
                          if (metric) {
                            setFieldValue("threshold_unit", getDefaultThresholdUnit(metric));
                            setFieldValue("threshold_unit_options", getThresholdUnitOptions(metric));
                          }
                        }}
                        {...field}
                      />
                    )}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">{errors.metric}</BootstrapForm.Control.Feedback>
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} lg={4} controlId="formCondition" className="mt-4">
                  <BootstrapForm.Label>
                    Condition <span className="text-danger">*</span>
                  </BootstrapForm.Label>
                  <Field name="condition">
                    {({ field }: FieldProps<string>) => (
                      <Select
                        options={conditionOptions}
                        isInvalid={!!errors.condition && !!touched.condition}
                        isDisabled={disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">{errors.condition}</BootstrapForm.Control.Feedback>
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} lg={4} controlId="formThreshold" className="mt-4">
                  <BootstrapForm.Label>
                    Threshold <span className="text-danger">*</span>
                  </BootstrapForm.Label>
                  <InputGroup className={classNames({ "is-invalid": !!errors.threshold && !!touched.threshold })}>
                    <Field name="threshold">
                      {({ field }: FieldProps<string>) => (
                        <BootstrapForm.Control
                          type="number"
                          min={0}
                          disabled={disableForm}
                          isInvalid={!!errors.threshold && !!touched.threshold}
                          {...field}
                        />
                      )}
                    </Field>
                    {values.threshold_unit_options && values.threshold_unit_options.length === 1 && (
                      <InputGroup.Text>{values.threshold_unit}</InputGroup.Text>
                    )}
                    {values.threshold_unit_options && values.threshold_unit_options.length > 1 && (
                      <DropdownButton variant="light" title={values.threshold_unit} align="end">
                        {values.threshold_unit_options.map((unit) => (
                          <Dropdown.Item
                            key={unit}
                            eventKey={unit}
                            onClick={() => setFieldValue("threshold_unit", unit)}
                            active={values.threshold_unit === unit}
                          >
                            {unit}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    )}
                  </InputGroup>
                  <BootstrapForm.Control.Feedback type="invalid">{errors.threshold}</BootstrapForm.Control.Feedback>
                </BootstrapForm.Group>
              </Row>
              <Row>
                <BootstrapForm.Group as={Col} lg={6} controlId="formPeriod" className="mt-4">
                  <BootstrapForm.Label>
                    Aggregation window <span className="text-danger">*</span>
                  </BootstrapForm.Label>
                  <Field name="period_minutes">
                    {({ field }: FieldProps<number>) => <PeriodSelect isDisabled={disableForm} {...field} />}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.period_minutes}
                  </BootstrapForm.Control.Feedback>
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} lg={6} controlId="formFrequency" className="mt-4">
                  <BootstrapForm.Label>Check every</BootstrapForm.Label>
                  <Field name="frequency_minutes">
                    {({ field }: FieldProps<number>) => (
                      <PeriodSelect
                        isDisabled={disableForm}
                        onChangeExtra={() => {
                          setFieldValue("notify_triggered_after_minutes", 0);
                          setFieldValue("notify_resolved_after_minutes", 0);
                        }}
                        {...field}
                      />
                    )}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.frequency_minutes}
                  </BootstrapForm.Control.Feedback>
                </BootstrapForm.Group>
              </Row>
            </Modal.Body>
            <CollapsibleModalBody
              title="Additional filters"
              initialCollapsed={
                !values.filter_consumer_id &&
                !values.filter_consumer_group_id &&
                !values.filter_endpoint_id &&
                !values.filter_endpoint_group_id
              }
            >
              <Row className="mt-n4">
                <BootstrapForm.Group as={Col} lg={6} controlId="formFilterConsumer" className="mt-4">
                  <BootstrapForm.Label>Consumer</BootstrapForm.Label>
                  <Field name="filter_consumer_id">
                    {({ field }: FieldProps<number>) => (
                      <ConsumerFilterSelect
                        appId={values.app_id}
                        appEnvId={values.app_env_id}
                        isDisabled={disableForm}
                        onChangeExtra={() => {
                          setFieldValue("filter_consumer_group_id", null);
                        }}
                        {...field}
                      />
                    )}
                  </Field>
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} lg={6} controlId="formFilterConsumerGroup" className="mt-4">
                  <BootstrapForm.Label>Consumer group</BootstrapForm.Label>
                  <Field name="filter_consumer_group_id">
                    {({ field }: FieldProps<number>) => (
                      <ConsumerGroupFilterSelect
                        appId={values.app_id}
                        appEnvId={values.app_env_id}
                        isDisabled={!!values.filter_consumer_id || disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                </BootstrapForm.Group>
              </Row>
              <Row>
                <BootstrapForm.Group as={Col} lg={6} controlId="formFilterEndpoint" className="mt-4">
                  <BootstrapForm.Label>Endpoint</BootstrapForm.Label>
                  <Field name="filter_endpoint_id">
                    {({ field }: FieldProps<number>) => (
                      <EndpointFilterSelect
                        appId={values.app_id}
                        appEnvId={values.app_env_id}
                        isDisabled={disableForm}
                        onChangeExtra={() => {
                          setFieldValue("filter_endpoint_group_id", null);
                        }}
                        {...field}
                      />
                    )}
                  </Field>
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} lg={6} controlId="formFilterEndpointGroup" className="mt-4">
                  <BootstrapForm.Label>Endpoint group</BootstrapForm.Label>
                  <Field name="filter_endpoint_group_id">
                    {({ field }: FieldProps<number>) => (
                      <EndpointGroupFilterSelect
                        appId={values.app_id}
                        appEnvId={values.app_env_id}
                        isDisabled={!!values.filter_endpoint_id || disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                </BootstrapForm.Group>
              </Row>
            </CollapsibleModalBody>
            <CollapsibleModalBody title="Advanced options" initialCollapsed={!values.cron_expression}>
              <Row className="mt-n4">
                <BootstrapForm.Group as={Col} lg={6} controlId="formCronExpression" className="mt-4">
                  <BootstrapForm.Label>Cron schedule for checks</BootstrapForm.Label>
                  <Field name="cron_expression">
                    {({ field }: FieldProps<string>) => (
                      <BootstrapForm.Control
                        type="text"
                        placeholder="*/5 * * * *"
                        maxLength={128}
                        autoComplete="off"
                        isInvalid={!!errors.cron_expression && !!touched.cron_expression}
                        disabled={disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.cron_expression}
                  </BootstrapForm.Control.Feedback>
                  <BootstrapForm.Text muted>
                    This overrides the <i>Check every</i> field above.
                  </BootstrapForm.Text>
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} lg={6} controlId="formTimezone" className="mt-4">
                  <BootstrapForm.Label>Timezone</BootstrapForm.Label>
                  <Field name="timezone">
                    {({ field }: FieldProps<string>) => (
                      <TimezoneSelect isDisabled={!values.cron_expression || disableForm} {...field} />
                    )}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">{errors.condition}</BootstrapForm.Control.Feedback>
                  <BootstrapForm.Text muted>The cron expression is evaluated using this timezone.</BootstrapForm.Text>
                </BootstrapForm.Group>
              </Row>
              <Row>
                <BootstrapForm.Group as={Col} lg={6} controlId="formNotifyTriggeredAfterMinutes" className="mt-4">
                  <BootstrapForm.Label>Notify after triggered for</BootstrapForm.Label>
                  <Field name="notify_triggered_after_minutes">
                    {({ field }: FieldProps<number>) => (
                      <PeriodSelect
                        includeZero
                        multiplesOf={values.frequency_minutes}
                        isDisabled={disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.notify_triggered_after_minutes}
                  </BootstrapForm.Control.Feedback>
                  <BootstrapForm.Text muted>
                    Reduce noise by delaying notifications until threshold has been breached for X minutes.
                  </BootstrapForm.Text>
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} lg={6} controlId="formNotifyResolvedAfterMinutes" className="mt-4">
                  <BootstrapForm.Label>Notify after resolved for</BootstrapForm.Label>
                  <Field name="notify_resolved_after_minutes">
                    {({ field }: FieldProps<number>) => (
                      <PeriodSelect
                        includeZero
                        includeMinusOne
                        multiplesOf={values.frequency_minutes}
                        isDisabled={disableForm}
                        {...field}
                      />
                    )}
                  </Field>
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.notify_resolved_after_minutes}
                  </BootstrapForm.Control.Feedback>
                  <BootstrapForm.Text muted>
                    Reduce noise by delaying notifications until alert condition has been resolved for X minutes.
                  </BootstrapForm.Text>
                </BootstrapForm.Group>
              </Row>
            </CollapsibleModalBody>
            <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>
                Discard
              </Button>
              <Tooltip condition={isDemo && !isTeamAdmin} tooltip="Not allowed in demo" placement="bottom">
                <Button
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={disableForm || isSubmitting || !isTeamAdmin}
                >
                  Submit
                </Button>
              </Tooltip>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default AlertRuleFormModal;
