import { useQuery } from "@tanstack/react-query";
import { BarController, BarElement, Chart as ChartJS, LinearScale, Title, Tooltip, TooltipItem } from "chart.js";
import "chartjs-adapter-luxon";
import { memo } from "react";
import { Bar } from "react-chartjs-2";

import ChartContainer from "../components/ChartContainer";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getColor } from "../utils/colors";

ChartJS.register(LinearScale, BarController, BarElement, Title, Tooltip);

type PayloadSizeHistogramProps = {
  payloadType: "request" | "response";
  endpoint?: Endpoint;
};

function PayloadSizeHistogram({ payloadType, endpoint }: PayloadSizeHistogramProps) {
  const { backendClient } = useGlobal();
  const { app, period, env, consumerId, consumerGroupId, statusCode } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    method: endpoint?.method || "",
    path: endpoint?.path || "",
    consumerId,
    consumerGroupId,
    statusCode,
    period,
  };
  let query;
  if (payloadType === "request") {
    query = useQuery({
      queryKey: ["requestSizeHistogram", queryParams],
      queryFn: () => backendClient!.traffic.getRequestSizeHistogram(queryParams),
      enabled: !!backendClient && !!app && !!endpoint,
    });
  } else if (payloadType === "response") {
    query = useQuery({
      queryKey: ["responseSizeHistogram", queryParams],
      queryFn: () => backendClient!.traffic.getResponseSizeHistogram(queryParams),
      enabled: !!backendClient && !!app && !!endpoint,
    });
  }

  let chart;
  if (query && query.isSuccess) {
    const primaryColor = getColor("primary");
    const secondaryColor = getColor("secondary");
    const color = payloadType === "response" ? primaryColor : secondaryColor;
    const hyphen = " – "; // punctuation space and en dash
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      font: {
        family: "DM Sans",
      },
      scales: {
        x: {
          type: "linear" as const,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          display: true,
          suggestedMax: 25,
          ticks: {
            precision: 0,
            font: {
              family: "DM Sans",
            },
            callback: function (value: string | number) {
              return Number(value).toLocaleString() + " KB";
            },
          },
        },
        y: {
          grid: {
            display: true,
            color: "#faf9fb",
          },
          border: {
            display: false,
          },
          beginAtZero: true,
          ticks: {
            font: {
              family: "DM Sans",
            },
            color: "#adb5bd",
          },
        },
      },
      animation: {
        duration: 0,
      },
      plugins: {
        tooltip: {
          mode: "index" as const,
          callbacks: {
            title: function (context: TooltipItem<"bar">[]) {
              const x = context[0].parsed.x;
              return `${x.toLocaleString()}${hyphen}${(x + query.data.bin_size).toLocaleString()} KB`;
            },
            label: function (context: TooltipItem<"bar">) {
              const value = context.parsed.y.toLocaleString();
              return ` ${value} requests`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
    };
    const chartData = {
      labels: query.data.bins,
      datasets: [
        {
          data: query.data.counts,
          backgroundColor: color,
          hoverBackgroundColor: color,
          barPercentage: 1,
          categoryPercentage: 1,
          borderWidth: { left: 1, right: 0, top: 0, bottom: 0 },
          borderColor: "#ffffff00",
        },
      ],
    };
    chart = <Bar data={chartData} options={chartOptions} />;
  }

  return <ChartContainer>{chart}</ChartContainer>;
}

export default memo(PayloadSizeHistogram);
