import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

import CustomIcon from "./CustomIcon";
import "./MetricChip.scss";
import Tooltip from "./Tooltip";

type MetricChipProps = {
  label: React.ReactNode;
  icon: IconDefinition | string;
  iconClassName?: string;
  value: React.ReactNode;
  extra?: string;
  title?: string;
  onClick?: () => void;
  className?: string;
  tooltip?: React.ReactNode;
};

function MetricChip({
  label,
  icon,
  iconClassName = "text-secondary",
  value,
  extra,
  title,
  onClick,
  className,
  tooltip,
}: MetricChipProps) {
  const iconElement =
    typeof icon === "string" ? (
      <CustomIcon src={icon} className={`me-2 small ${iconClassName}`} />
    ) : (
      <FontAwesomeIcon icon={icon as IconDefinition} className={`me-2 small ${iconClassName}`} />
    );
  return (
    <Tooltip condition={!!tooltip} tooltip={tooltip} placement="bottom">
      <div className={classNames("MetricChip", className, { clickable: !!onClick })} onClick={onClick}>
        <div className="label text-muted small">{label}</div>
        <div className="value text-secondary" title={title}>
          {iconElement}
          {value}
          {extra && <span className="extra text-very-muted">{extra}</span>}
        </div>
      </div>
    </Tooltip>
  );
}

export default MetricChip;
