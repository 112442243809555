import {
  faCheckCircle,
  faCircleDown,
  faCircleUp,
  faFaceAngry,
  faFaceFrownOpen,
  faFaceGrinStars,
  faFaceMeh,
  faFaceSmile,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAngleRight,
  faArrowRightArrowLeft,
  faBug,
  faChartSimple,
  faInfo,
  faSpinner,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Markdown from "react-markdown";
import { useNavigate, useSearchParams } from "react-router-dom";

import { GetEndpointResponse, GetTrafficMetricsResponse } from "../backend";
import DataTransferredBarChart from "../charts/DataTransferredBarChart";
import ErrorsBarChart from "../charts/ErrorsBarChart";
import PayloadSizeHistogram from "../charts/PayloadSizeHistogram";
import RequestsBarChart from "../charts/RequestsBarChart";
import RequestsByConsumerBarChart from "../charts/RequestsByConsumerBarChart";
import ResponseTimeHistogram from "../charts/ResponseTimeHistogram";
import ResponseTimeLineChart from "../charts/ResponseTimeLineChart";
import FilterBadge from "../components/FilterBadge";
import FilterBadges from "../components/FilterBadges";
import MethodPath from "../components/MethodPath";
import MetricChip from "../components/MetricChip";
import ModalSection from "../components/ModalSection";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { RequestLogTableWithFilters } from "../tables/RequestLogTable";
import ServerErrorsTable from "../tables/ServerErrorsTable";
import ValidationErrorsTable from "../tables/ValidationErrorsTable";
import { Endpoint } from "../types/Endpoint";
import { formatDataSize, formatResponseTime } from "../utils/numbers";
import "./EndpointModal.css";

type EndpointModalTabProps = {
  endpoint: Endpoint;
  metrics?: GetTrafficMetricsResponse;
  selectTab?: (key: string) => void;
};

function EndpointModalInfoTab({ endpointInfo }: { endpointInfo: GetEndpointResponse }) {
  if (!endpointInfo.summary && !endpointInfo.description) {
    return <></>;
  }
  return (
    <>
      {endpointInfo.summary && (
        <ModalSection title="Summary">
          <div className="border rounded px-3 py-2">{endpointInfo.summary}</div>
        </ModalSection>
      )}
      {endpointInfo.description && (
        <ModalSection title="Description" className={endpointInfo.summary ? "mt-6" : undefined}>
          <div className="border rounded px-3 py-2">
            <Markdown>{endpointInfo.description}</Markdown>
          </div>
        </ModalSection>
      )}
    </>
  );
}

function EndpointModalRequestsTab({ endpoint, metrics, selectTab }: EndpointModalTabProps) {
  const { consumerId } = useFilters();
  const successfulRequestCount = metrics
    ? metrics.total_request_count - metrics.client_error_count - metrics.server_error_count
    : undefined;
  const clientErrorRate =
    metrics && metrics.client_error_count > 0 ? metrics.client_error_count / metrics.total_request_count : undefined;
  const serverErrorRate =
    metrics && metrics.server_error_count > 0 ? metrics.server_error_count / metrics.total_request_count : undefined;
  return (
    <>
      {endpoint.excluded && (
        <Alert variant="danger">
          <span className="fw-bold">This endpoint is marked as excluded.</span>
          <br />
          Requests to this endpoint are excluded from traffic metric calculations and charts (except in this modal).
        </Alert>
      )}
      <ModalSection title="Summary">
        <div className="mb-n4 me-n4">
          <MetricChip
            label="Total requests"
            icon="/icons/hashtag-regular.svg"
            value={metrics?.total_request_count.toLocaleString() || "-"}
          />
          <MetricChip
            label="Requests per minute"
            icon="/icons/gauge-regular.svg"
            value={metrics?.requests_per_minute.toLocaleString(undefined, { maximumFractionDigits: 2 }) || "-"}
          />
          <MetricChip
            label="Successful requests"
            icon={faCheckCircle}
            iconClassName="text-primary"
            value={successfulRequestCount?.toLocaleString() || "-"}
          />
          <MetricChip
            label="Client errors"
            icon={faXmarkCircle}
            iconClassName="text-danger"
            value={metrics?.client_error_count.toLocaleString() || "-"}
            extra={clientErrorRate
              ?.toLocaleString(undefined, { style: "percent", maximumFractionDigits: 1 })
              .replace("%", " %")}
            onClick={selectTab && metrics?.client_error_count ? () => selectTab("errors") : undefined}
          />
          <MetricChip
            label="Server errors"
            icon="/icons/bug-regular.svg"
            iconClassName="text-danger"
            value={metrics?.server_error_count.toLocaleString() || "-"}
            extra={serverErrorRate
              ?.toLocaleString(undefined, { style: "percent", maximumFractionDigits: 1 })
              .replace("%", " %")
              .replace(/^0 %$/, "")}
            onClick={selectTab && metrics?.server_error_count ? () => selectTab("errors") : undefined}
          />
        </div>
      </ModalSection>
      <ModalSection title="Requests over time" className="mt-6">
        <div className="border rounded p-4">
          <RequestsBarChart endpoint={endpoint} displayTitle={false} />
        </div>
      </ModalSection>
      {!consumerId && (
        <ModalSection
          title="Requests per consumer"
          titleExtra={
            metrics?.unique_consumer_count && metrics?.unique_consumer_count > 10
              ? `top 10 of ${metrics?.unique_consumer_count.toLocaleString()}`
              : undefined
          }
          className="mt-6"
        >
          <div className="border rounded p-4">
            <RequestsByConsumerBarChart endpoint={endpoint} />
          </div>
        </ModalSection>
      )}
      {endpoint.id && (
        <ModalSection title="Most recent requests" className="mt-6">
          <RequestLogTableWithFilters endpointId={endpoint.id} expectedItems={metrics?.total_request_count} />
        </ModalSection>
      )}
    </>
  );
}

function EndpointModalErrorsTab({ endpoint }: EndpointModalTabProps) {
  const { backendClient } = useGlobal();
  const { app, period, env, consumerId, consumerGroupId, statusCode } = useFilters();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const statusCodeCountsQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    method: endpoint.method,
    path: endpoint.path,
    consumerId,
    consumerGroupId,
    statusCode,
    period,
  };
  const statusCodeCountsQuery = useQuery({
    queryKey: ["statusCodeCounts", statusCodeCountsQueryParams],
    queryFn: () => backendClient!.traffic.getStatusCodeCounts(statusCodeCountsQueryParams),
    enabled: !!backendClient && !!app,
  });
  const totalRequestCount = statusCodeCountsQuery.data?.reduce((acc, item) => acc + item.request_count, 0) || 0;
  const errorStatusCodeCounts = statusCodeCountsQuery.data?.filter((item) => item.status_code >= 400);
  const failedRequestCount = errorStatusCodeCounts?.reduce((acc, item) => acc + item.request_count, 0) || 0;
  const hasValidationErrors = errorStatusCodeCounts?.some((item) => item.status_code == 400 || item.status_code == 422);
  const hasServerErrors = errorStatusCodeCounts?.some((item) => item.status_code >= 500);

  return (
    <>
      <ModalSection title="Errors by status code">
        {app && errorStatusCodeCounts && errorStatusCodeCounts.length > 0 && (
          <div className="mb-n4 me-n4">
            {errorStatusCodeCounts.map((item) => (
              <MetricChip
                key={item.status_code}
                label={`${item.status_code} ${item.description || "Unknown Status"}`}
                icon={item.expected ? faCheckCircle : item.status_code >= 500 ? faBug : faXmarkCircle}
                iconClassName={item.expected ? "text-primary" : "text-danger"}
                value={item.request_count.toLocaleString()}
                extra={(item.request_count / totalRequestCount)
                  .toLocaleString(undefined, { style: "percent", maximumFractionDigits: 1 })
                  .replace("%", " %")
                  .replace(/^0 %$/, "")}
                onClick={
                  endpoint.id
                    ? () =>
                        navigate({
                          pathname: `/errors/${app.slug}/${endpoint.id}-${item.status_code}`,
                          search: searchParams.toString(),
                        })
                    : undefined
                }
                className={item.expected ? "expected" : undefined}
                tooltip={item.expected ? "Counts as successful because it has been marked as expected." : undefined}
              />
            ))}
          </div>
        )}
        {errorStatusCodeCounts && errorStatusCodeCounts.length === 0 && (
          <div className="border rounded px-4 py-6 text-center">No failed requests in the selected period.</div>
        )}
      </ModalSection>
      <ModalSection title="Client & server errors over time" className="mt-6">
        <div className="border rounded p-4">
          <ErrorsBarChart endpoint={endpoint} displayTitle={false} />
        </div>
      </ModalSection>
      {hasValidationErrors && (
        <ModalSection title="Validation errors" titleExtra="top 10" className="mt-6">
          <div className="px-4">
            <ValidationErrorsTable endpoint={endpoint} limit={10} />
          </div>
        </ModalSection>
      )}
      {hasServerErrors && (
        <ModalSection title="Unhandled exceptions" titleExtra="top 10" className="mt-6">
          <div className="px-4">
            <ServerErrorsTable endpoint={endpoint} limit={10} />
          </div>
        </ModalSection>
      )}
      {endpoint.id && (
        <ModalSection title="Most recent client & server errors" className="mt-6">
          <RequestLogTableWithFilters
            endpointId={endpoint.id}
            statusCode="4xx,5xx"
            expectedItems={failedRequestCount}
          />
        </ModalSection>
      )}
    </>
  );
}

function EndpointModalResponseTimesTab({ endpoint }: EndpointModalTabProps) {
  const { backendClient } = useGlobal();
  const { app, period, env, consumerId, consumerGroupId, statusCode } = useFilters();
  const navigate = useNavigate();

  const metricsQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    method: endpoint.method,
    path: endpoint.path,
    consumerId,
    consumerGroupId,
    statusCode,
    period,
  };
  const metricsQuery = useQuery({
    queryKey: ["performanceMetrics", metricsQueryParams],
    queryFn: () => backendClient!.performance.getPerformanceMetrics(metricsQueryParams),
    enabled: !!backendClient && !!app,
    placeholderData: undefined,
  });

  const slowRequestCount =
    metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0
      ? metricsQuery.data.apdex_tolerated_count + metricsQuery.data.apdex_frustrated_count
      : undefined;
  let apdexScoreIcon = faFaceSmile;
  if (metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0) {
    if (metricsQuery.data.apdex_score >= 0.93) {
      apdexScoreIcon = faFaceGrinStars;
    } else if (metricsQuery.data.apdex_score >= 0.85) {
      apdexScoreIcon = faFaceSmile;
    } else if (metricsQuery.data.apdex_score >= 0.7) {
      apdexScoreIcon = faFaceMeh;
    } else if (metricsQuery.data.apdex_score >= 0.5) {
      apdexScoreIcon = faFaceFrownOpen;
    } else {
      apdexScoreIcon = faFaceAngry;
    }
  }

  return (
    <>
      <ModalSection title="Summary">
        <div className="mb-n4 me-n4">
          <MetricChip
            label="Apdex score"
            icon={apdexScoreIcon}
            value={
              metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0
                ? metricsQuery.data.apdex_score.toLocaleString(undefined, {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  })
                : "-"
            }
          />
          <MetricChip
            label="Slow requests"
            icon={faSpinner}
            value={metricsQuery.isSuccess ? (slowRequestCount || 0).toLocaleString() : "-"}
            extra={metricsQuery.isSuccess ? `of ${metricsQuery.data?.total_request_count.toLocaleString()}` : undefined}
            tooltip={
              metricsQuery.isSuccess &&
              metricsQuery.data.apdex_tolerated_count + metricsQuery.data.apdex_frustrated_count > 0 ? (
                <div className="text-start">
                  <div>
                    <div className="very-small text-muted-tooltip">
                      {metricsQuery.data.target_response_time_ms.toLocaleString()} -{" "}
                      {(4 * metricsQuery.data.target_response_time_ms).toLocaleString()} ms
                    </div>
                    <div>
                      {metricsQuery.data.apdex_tolerated_count.toLocaleString()} request
                      {metricsQuery.data.apdex_tolerated_count !== 1 ? "s" : ""}
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="very-small text-muted-tooltip">
                      More than {(4 * metricsQuery.data.target_response_time_ms).toLocaleString()} ms
                    </div>
                    <div>
                      {metricsQuery.data.apdex_frustrated_count.toLocaleString()} request
                      {metricsQuery.data.apdex_frustrated_count !== 1 ? "s" : ""}
                    </div>
                  </div>
                </div>
              ) : undefined
            }
            onClick={
              app &&
              endpoint.id &&
              metricsQuery.isSuccess &&
              metricsQuery.data.apdex_tolerated_count + metricsQuery.data.apdex_frustrated_count > 0
                ? () =>
                    navigate(
                      `/request-log/${app.slug}?endpoint=${endpoint.id}&min_response_time=${metricsQuery.data.target_response_time_ms}`,
                    )
                : undefined
            }
          />
          <MetricChip
            label="50th percentile"
            icon="/icons/stopwatch-regular.svg"
            value={
              metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0
                ? formatResponseTime(metricsQuery.data.response_time_p50)
                : "-"
            }
          />
          <MetricChip
            label="75th percentile"
            icon="/icons/stopwatch-regular.svg"
            value={
              metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0
                ? formatResponseTime(metricsQuery.data.response_time_p75)
                : "-"
            }
          />
          <MetricChip
            label="95th percentile"
            icon="/icons/stopwatch-regular.svg"
            value={
              metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0
                ? formatResponseTime(metricsQuery.data.response_time_p95)
                : "-"
            }
          />
        </div>
      </ModalSection>
      <ModalSection title="Response times over time" className="mt-6">
        <div className="border rounded p-4">
          <ResponseTimeLineChart endpoint={endpoint} displayTitle={false} />
        </div>
      </ModalSection>
      <ModalSection title="Histogram of response times" className="mt-6">
        <div className="border rounded p-4">
          <ResponseTimeHistogram endpoint={endpoint} />
        </div>
      </ModalSection>
      {!!slowRequestCount && endpoint.id && (
        <ModalSection title="Most recent slow requests" className="mt-6">
          <RequestLogTableWithFilters
            endpointId={endpoint.id}
            minResponseTime={metricsQuery.data?.target_response_time_ms}
            expectedItems={slowRequestCount}
          />
        </ModalSection>
      )}
    </>
  );
}

function EndpointModalPayloadSizesTab({ endpoint, metrics }: EndpointModalTabProps) {
  const showRequestSize = !["get", "head", "delete"].includes(endpoint.method.toLowerCase());

  return (
    <>
      <ModalSection title="Summary">
        <div className="mb-n4 me-n4">
          <MetricChip
            label="Total data transferred"
            icon="/icons/arrow-right-arrow-left-regular.svg"
            value={metrics ? formatDataSize(metrics.total_data_transferred) : "-"}
          />
          {showRequestSize && (
            <MetricChip
              label="Average request size"
              icon={faCircleDown}
              value={
                metrics && metrics.request_size_avg != null && metrics.total_request_count > 0
                  ? formatDataSize(metrics.request_size_avg)
                  : "-"
              }
            />
          )}
          <MetricChip
            label="Average response size"
            icon={faCircleUp}
            value={
              metrics && metrics.response_size_avg != null && metrics.total_request_count > 0
                ? formatDataSize(metrics.response_size_avg)
                : "-"
            }
          />
        </div>
      </ModalSection>
      <ModalSection title="Data transferred over time" className="mt-6">
        <div className="border rounded p-4">
          <DataTransferredBarChart endpoint={endpoint} displayTitle={false} />
        </div>
      </ModalSection>
      {showRequestSize && (
        <ModalSection title="Histogram of request sizes" className="mt-6">
          <div className="border rounded p-4">
            <PayloadSizeHistogram payloadType="request" endpoint={endpoint} />
          </div>
        </ModalSection>
      )}
      <ModalSection title="Histogram of response sizes" className="mt-6">
        <div className="border rounded p-4">
          <PayloadSizeHistogram payloadType="response" endpoint={endpoint} />
        </div>
      </ModalSection>
    </>
  );
}

type EndpointModalProps = {
  endpoint?: Endpoint;
  onHide: () => void;
  initialTab?: string;
};

function EndpointModal({ endpoint, onHide, initialTab = "requests" }: EndpointModalProps) {
  const { backendClient } = useGlobal();
  const { app, period, env, consumerId, consumerGroupId, statusCode } = useFilters();
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [endpoint]);

  const selectTab = useCallback((key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  }, []);

  const metricsQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    method: endpoint?.method,
    path: endpoint?.path,
    statusCode,
    period,
  };
  const metricsQuery = useQuery({
    queryKey: ["trafficMetrics", metricsQueryParams],
    queryFn: () => backendClient!.traffic.getTrafficMetrics(metricsQueryParams),
    enabled: !!backendClient && !!app && !!endpoint,
    placeholderData: undefined,
  });
  const endpointQueryParams = { appId: app?.id || 0, endpointId: endpoint?.id || 0 };
  const endpointQuery = useQuery({
    queryKey: ["endpoint", endpointQueryParams],
    queryFn: () => backendClient!.endpoints.getEndpoint(endpointQueryParams),
    enabled: !!backendClient && !!app && !!endpoint?.id,
  });

  if (!app || !endpoint) {
    return;
  }

  const tabs = [
    {
      eventKey: "info",
      title: "Info",
      icon: faInfo,
      content: endpointQuery?.data && (!!endpointQuery.data.summary || !!endpointQuery.data.description) && (
        <EndpointModalInfoTab endpointInfo={endpointQuery.data} />
      ),
    },
    {
      eventKey: "requests",
      title: "Requests",
      icon: faChartSimple,
      content: <EndpointModalRequestsTab endpoint={endpoint} metrics={metricsQuery.data} selectTab={selectTab} />,
    },
    {
      eventKey: "errors",
      title: "Errors",
      icon: faBug,
      content: <EndpointModalErrorsTab endpoint={endpoint} />,
    },
    {
      eventKey: "response-times",
      title: "Response times",
      icon: faStopwatch,
      content: <EndpointModalResponseTimesTab endpoint={endpoint} metrics={metricsQuery.data} />,
    },
    {
      eventKey: "data-transferred",
      title: "Data transferred",
      icon: faArrowRightArrowLeft,
      content: <EndpointModalPayloadSizesTab endpoint={endpoint} metrics={metricsQuery.data} />,
    },
  ].filter((tab) => tab.content);

  const additionalBadges =
    activeTab === "response-times" && endpointQuery.isSuccess ? (
      <FilterBadge
        icon="/icons/stopwatch-regular.svg"
        label="Response time threshold"
        value={`${endpointQuery.data.target_response_time_ms.toLocaleString()} ms`}
      />
    ) : undefined;

  return (
    <Modal className="EndpointModal tabs" size="xl" show={!!endpoint} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="app-env-name">
            {app.name}
            {env && (
              <>
                <FontAwesomeIcon icon={faAngleRight} className="ms-2 me-2 text-very-muted small" />
                {env.name}
              </>
            )}
          </div>
          <div className="method-path">
            <MethodPath method={endpoint.method} path={endpoint.path} />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-1 px-0">
        <Tab.Container activeKey={activeTab} onSelect={selectTab}>
          <Nav variant="tabs">
            {tabs.map((tab) => (
              <Nav.Item key={tab.eventKey} title={tab.title}>
                <Nav.Link eventKey={tab.eventKey}>
                  <FontAwesomeIcon icon={tab.icon} fixedWidth className="d-inline d-lg-none" />
                  <span className="d-none d-lg-inline">{tab.title}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            {activeTab !== "info" && (
              <div className="p-4 pb-0">
                <FilterBadges showAll hideEndpointFilters additionalBadges={additionalBadges} />
              </div>
            )}
            {tabs.map((tab) => (
              <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey}>
                {tab.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
}

export default EndpointModal;
