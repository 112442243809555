import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import useAppSlug from "../hooks/useAppSlug";
import { getInitialPeriod, isCustomPeriod, validatePeriod } from "../utils/period";

type FilterContextType = {
  app?: ListAppsResponseItem;
  appSlug?: string;
  period: string;
  setPeriod: (period: string) => void;
  resetPeriod: () => void;
  env?: AppEnvItem;
  setEnv: (env?: AppEnvItem) => void;
  consumerId?: number;
  setConsumerId: (consumerId?: number) => void;
  consumerGroupId?: number;
  setConsumerGroupId: (consumerGroupId?: number) => void;
  endpointId?: number;
  setEndpointId: (endpointId?: number) => void;
  endpointGroupId?: number;
  setEndpointGroupId: (endpointGroupId?: number) => void;
  method?: string;
  setMethod: (method?: string) => void;
  statusCode?: string;
  setStatusCode: (method?: string) => void;
  url?: string;
  setUrl: (url?: string) => void;
  requestBody?: string;
  setRequestBody: (requestBody?: string) => void;
  responseBody?: string;
  setResponseBody: (responseBody?: string) => void;
  minRequestSize?: number;
  maxRequestSize?: number;
  setRequestSizeRange: (minRequestSize?: number, maxRequestSize?: number) => void;
  minResponseSize?: number;
  maxResponseSize?: number;
  setResponseSizeRange: (minResponseSize?: number, maxResponseSize?: number) => void;
  minResponseTime?: number;
  maxResponseTime?: number;
  setResponseTimeRange: (minResponseTime?: number, maxResponseTime?: number) => void;
};

const FilterContext = React.createContext<FilterContextType | undefined>(undefined);

export function useFilters() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a FilterContextProvider");
  }
  return context;
}

type FilterContextProviderProps = {
  disableConsumer?: boolean;
  disableConsumerGroup?: boolean;
  disableEndpoint?: boolean;
  disableEndpointGroup?: boolean;
  disableMethod?: boolean;
  disableStatusCode?: boolean;
  children: React.ReactNode;
};

export function FilterContextProvider({
  disableConsumer = false,
  disableConsumerGroup = false,
  disableEndpoint = false,
  disableEndpointGroup = false,
  disableMethod = false,
  disableStatusCode = false,
  children,
}: FilterContextProviderProps) {
  const { app, appSlug } = useAppSlug();
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParam = (key: string, value?: string) => {
    setSearchParams(
      (searchParams) => {
        if (value) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
        return searchParams;
      },
      { replace: true },
    );
  };
  const setSessionStorage = (key: string, value?: string) => {
    if (value) {
      sessionStorage.setItem(key, value);
    } else {
      sessionStorage.removeItem(key);
    }
  };

  // Period - sessionStorage
  const storePeriod = (period: string) => {
    if (isCustomPeriod(period)) {
      sessionStorage.setItem("customPeriod", period);
    } else {
      sessionStorage.setItem("period", period);
      sessionStorage.removeItem("customPeriod");
    }
  };
  const setPeriod = (period: string) => {
    setSearchParam("period", period);
    storePeriod(period);
  };
  const periodSearchParam = searchParams.get("period");
  const period = periodSearchParam && validatePeriod(periodSearchParam) ? periodSearchParam : getInitialPeriod();
  const resetPeriod = () => {
    if (isCustomPeriod(period)) {
      setPeriod(getInitialPeriod({ allowCustom: false }));
    }
  };

  useEffect(() => {
    storePeriod(period);
  }, [period]);

  // App env - sessionStorage
  const envSlugSearchParam = searchParams.get("env");
  const envSlugSessionStorageKey = `env:${app?.id}`;
  const envSlugSessionStorage = sessionStorage.getItem(envSlugSessionStorageKey);
  const envSlug = envSlugSearchParam || envSlugSessionStorage || undefined;
  const env = app?.envs.find((env) => env.slug === envSlug);
  const setEnv = (env?: AppEnvItem) => {
    setSearchParam("env", env?.slug);
    setSessionStorage(envSlugSessionStorageKey, env?.active ? env.slug : undefined);
  };

  useEffect(() => {
    setSessionStorage(envSlugSessionStorageKey, env?.active ? env.slug : undefined);
  }, [env]);

  // Consumer - sessionStorage
  const consumerSearchParamNp = parseInt(searchParams.get("_consumer") || "0");
  const dontPersistConsumerId = consumerSearchParamNp > 0;
  const consumerSearchParam = parseInt(searchParams.get("consumer") || "0");
  const consumerSessionStorageKey = `consumer:${app?.id}`;
  const consumerSessionStorage = parseInt(sessionStorage.getItem(consumerSessionStorageKey) || "0");
  const consumerId = !disableConsumer
    ? consumerSearchParamNp || consumerSearchParam || consumerSessionStorage || undefined
    : undefined;
  const setConsumerId = (consumerId?: number) => {
    setSearchParam("consumer", consumerId?.toString());
    setSearchParam("_consumer", undefined);
    setSessionStorage(consumerSessionStorageKey, consumerId?.toString());
  };

  useEffect(() => {
    if (!disableConsumer && !dontPersistConsumerId) {
      setSessionStorage(consumerSessionStorageKey, consumerId?.toString());
    }
  }, [consumerId]);

  // Consumer group - sessionStorage
  const consumerGroupSearchParamNp = parseInt(searchParams.get("_consumer_group") || "0");
  const dontPersistConsumerGroupId = consumerGroupSearchParamNp > 0;
  const consumerGroupSearchParam = parseInt(searchParams.get("consumer_group") || "0");
  const consumerGroupSessionStorageKey = `consumerGroup:${app?.id}`;
  const consumerGroupSessionStorage = parseInt(sessionStorage.getItem(consumerGroupSessionStorageKey) || "0");
  const consumerGroupId = !disableConsumerGroup
    ? consumerGroupSearchParamNp || consumerGroupSearchParam || consumerGroupSessionStorage || undefined
    : undefined;
  const setConsumerGroupId = (consumerGroupId?: number) => {
    setSearchParam("consumer_group", consumerGroupId?.toString());
    setSearchParam("_consumer_group", undefined);
    setSessionStorage(consumerGroupSessionStorageKey, consumerGroupId?.toString());
  };

  useEffect(() => {
    if (!disableConsumerGroup && !dontPersistConsumerGroupId) {
      setSessionStorage(consumerGroupSessionStorageKey, consumerGroupId?.toString());
    }
  }, [consumerGroupId]);

  // Endpoint - sessionStorage
  const endpointSearchParamNp = parseInt(searchParams.get("_endpoint") || "0");
  const dontPersistEndpointId = endpointSearchParamNp > 0;
  const endpointSearchParam = parseInt(searchParams.get("endpoint") || "0");
  const endpointSessionStorageKey = `endpoint:${app?.id}`;
  const endpointSessionStorage = parseInt(sessionStorage.getItem(endpointSessionStorageKey) || "0");
  const endpointId = !disableEndpoint
    ? endpointSearchParamNp || endpointSearchParam || endpointSessionStorage || undefined
    : undefined;
  const setEndpointId = (endpointId?: number) => {
    setSearchParam("endpoint", endpointId?.toString());
    setSearchParam("_endpoint", undefined);
    setSessionStorage(endpointSessionStorageKey, endpointId?.toString());
  };

  useEffect(() => {
    if (!disableEndpoint && !dontPersistEndpointId) {
      setSessionStorage(endpointSessionStorageKey, endpointId?.toString());
    }
  }, [endpointId]);

  // Endpoint group - sessionStorage
  const endpointGroupSearchParamNp = parseInt(searchParams.get("_endpoint_group") || "0");
  const dontPersistEndpointGroupId = endpointGroupSearchParamNp > 0;
  const endpointGroupSearchParam = parseInt(searchParams.get("endpoint_group") || "0");
  const endpointGroupSessionStorageKey = `endpointGroup:${app?.id}`;
  const endpointGroupSessionStorage = parseInt(sessionStorage.getItem(endpointGroupSessionStorageKey) || "0");
  const endpointGroupId = !disableEndpointGroup
    ? endpointGroupSearchParamNp || endpointGroupSearchParam || endpointGroupSessionStorage || undefined
    : undefined;
  const setEndpointGroupId = (endpointGroupId?: number) => {
    setSearchParam("endpoint_group", endpointGroupId?.toString());
    setSearchParam("_endpoint_group", undefined);
    setSessionStorage(endpointGroupSessionStorageKey, endpointGroupId?.toString());
  };

  useEffect(() => {
    if (!disableEndpointGroup && !dontPersistEndpointGroupId) {
      setSessionStorage(endpointGroupSessionStorageKey, endpointGroupId?.toString());
    }
  }, [endpointGroupId]);

  // Method - sessionStorage
  const methodSearchParamNp = searchParams.get("_method");
  const dontPersistMethod = !!methodSearchParamNp;
  const methodSearchParam = searchParams.get("method");
  const methodSessionStorageKey = `method:${app?.id}`;
  const methodSessionStorage = sessionStorage.getItem(methodSessionStorageKey);
  const method = !disableMethod
    ? methodSearchParamNp || methodSearchParam || methodSessionStorage || undefined
    : undefined;
  const setMethod = (method?: string) => {
    setSearchParam("method", method);
    setSearchParam("_method", undefined);
    setSessionStorage(methodSessionStorageKey, method);
  };

  useEffect(() => {
    if (!disableMethod && !dontPersistMethod) {
      setSessionStorage(methodSessionStorageKey, method);
    }
  }, [method]);

  // Status code - sessionStorage
  const statusCodeSearchParamNp = searchParams.get("_status_code");
  const dontPersistStatusCode = !!statusCodeSearchParamNp;
  const statusCodeSearchParam = searchParams.get("status_code");
  const statusCodeSessionStorageKey = `statusCode:${app?.id}`;
  const statusCodeSessionStorage = sessionStorage.getItem(statusCodeSessionStorageKey);
  const statusCode = !disableStatusCode
    ? statusCodeSearchParamNp || statusCodeSearchParam || statusCodeSessionStorage || undefined
    : undefined;
  const setStatusCode = (statusCode?: string) => {
    setSearchParam("status_code", statusCode);
    setSearchParam("_status_code", undefined);
    setSessionStorage(statusCodeSessionStorageKey, statusCode);
  };

  useEffect(() => {
    if (!disableStatusCode && !dontPersistStatusCode) {
      setSessionStorage(statusCodeSessionStorageKey, statusCode);
    }
  }, [statusCode]);

  // URL
  const url = searchParams.get("url") || undefined;
  const setUrl = (url?: string) => {
    setSearchParam("url", url);
  };

  // Request body
  const requestBody = searchParams.get("request_body") || undefined;
  const setRequestBody = (requestBody?: string) => {
    setSearchParam("request_body", requestBody);
  };

  // Response body
  const responseBody = searchParams.get("response_body") || undefined;
  const setResponseBody = (responseBody?: string) => {
    setSearchParam("response_body", responseBody);
  };

  // Request size
  const minRequestSize = parseFloat(searchParams.get("min_request_size") || "0") || undefined;
  const maxRequestSize = parseFloat(searchParams.get("max_request_size") || "0") || undefined;
  const setRequestSizeRange = (minRequestSize?: number, maxRequestSize?: number) => {
    setSearchParam("min_request_size", minRequestSize?.toString());
    setSearchParam("max_request_size", maxRequestSize?.toString());
  };

  // Response size
  const minResponseSize = parseFloat(searchParams.get("min_response_size") || "0") || undefined;
  const maxResponseSize = parseFloat(searchParams.get("max_response_size") || "0") || undefined;
  const setResponseSizeRange = (minResponseSize?: number, maxResponseSize?: number) => {
    setSearchParam("min_response_size", minResponseSize?.toString());
    setSearchParam("max_response_size", maxResponseSize?.toString());
  };

  // Response time
  const minResponseTime = parseInt(searchParams.get("min_response_time") || "0") || undefined;
  const maxResponseTime = parseInt(searchParams.get("max_response_time") || "0") || undefined;
  const setResponseTimeRange = (minResponseTime?: number, maxResponseTime?: number) => {
    setSearchParam("min_response_time", minResponseTime?.toString());
    setSearchParam("max_response_time", maxResponseTime?.toString());
  };

  useEffect(() => {
    if (app) {
      // Ensure query params are in sync with app state when page loads or app changes
      setPeriod(period);
      setEnv(env);
      if (!disableConsumer && !dontPersistConsumerId) {
        setConsumerId(consumerId);
      }
      if (!disableConsumerGroup && !dontPersistConsumerGroupId) {
        setConsumerGroupId(consumerGroupId);
      }
      if (!disableEndpoint && !dontPersistEndpointId) {
        setEndpointId(endpointId);
      }
      if (!disableEndpointGroup && !dontPersistEndpointGroupId) {
        setEndpointGroupId(endpointGroupId);
      }
      if (!disableMethod && !dontPersistMethod) {
        setMethod(method);
      }
      if (!disableStatusCode && !dontPersistStatusCode) {
        setStatusCode(statusCode);
      }
    }
  }, [app]);

  return (
    <FilterContext.Provider
      value={{
        app,
        appSlug,
        period,
        setPeriod,
        resetPeriod,
        env,
        setEnv,
        consumerId,
        setConsumerId,
        consumerGroupId,
        setConsumerGroupId,
        endpointId,
        setEndpointId,
        endpointGroupId,
        setEndpointGroupId,
        method,
        setMethod,
        statusCode,
        setStatusCode,
        url,
        setUrl,
        requestBody,
        setRequestBody,
        responseBody,
        setResponseBody,
        minRequestSize,
        maxRequestSize,
        setRequestSizeRange,
        minResponseSize,
        maxResponseSize,
        setResponseSizeRange,
        minResponseTime,
        maxResponseTime,
        setResponseTimeRange,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export default FilterContextProvider;
