import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faLock, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import { useNavigate, useSearchParams } from "react-router-dom";

import TableCard from "../components/TableCard";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { RequestLogTableRow } from "../RequestLog";
import "./RequestLogTable.css";

type RequestLogTableProps = {
  appId: number;
  appEnv?: string;
  consumerId?: number;
  consumerGroupId?: number;
  endpointId?: number;
  endpointGroupId?: number;
  statusCode?: string;
  minResponseTime?: number;
  period?: string;
  limit?: number;
  expectedItems?: number;
};

function RequestLogTable({
  appId,
  appEnv,
  consumerId,
  consumerGroupId,
  endpointId,
  endpointGroupId,
  statusCode,
  minResponseTime,
  period,
  limit = 5,
  expectedItems = 5,
}: RequestLogTableProps) {
  const { backendClient, apps, teamPlan } = useGlobal();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const app = apps?.find((app) => app.id === appId);
  const requestLoggingAllowed =
    !!teamPlan && (teamPlan.monthly_request_log_quota === null || teamPlan.monthly_request_log_quota > 0);

  const queryParams = {
    appId,
    appEnv,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    statusCode,
    minResponseTime,
    period,
    limit,
  };
  const query = useQuery({
    queryKey: ["requestLogTable", queryParams],
    queryFn: () => backendClient!.requestLog.getRequestLog(queryParams),
    enabled: !!backendClient && !!appId,
  });

  const getRequestLogSearchParams = () => {
    const searchParamsCopy = new URLSearchParams(searchParams);
    if (consumerId) {
      searchParamsCopy.set("_consumer", consumerId.toString());
    }
    if (consumerGroupId) {
      searchParamsCopy.set("_consumer_group", consumerGroupId.toString());
    }
    if (endpointId) {
      searchParamsCopy.set("_endpoint", endpointId.toString());
    }
    if (endpointGroupId) {
      searchParamsCopy.set("_endpoint_group", endpointGroupId.toString());
    }
    if (statusCode) {
      searchParamsCopy.set("_status_code", statusCode);
    }
    if (minResponseTime) {
      searchParamsCopy.set("min_response_time", minResponseTime.toString());
    }
    return searchParamsCopy;
  };

  if (!requestLoggingAllowed) {
    return (
      <Card>
        <Card.Body className="py-6 text-center">
          <FontAwesomeIcon icon={faLock} className="me-2 text-very-muted" />
          Request logging is not included in your plan.
        </Card.Body>
      </Card>
    );
  }

  const tableFooter =
    (query.isPending && expectedItems > 0) || (query.isSuccess && query.data.length > 0) ? (
      <div className="small">
        <Button
          variant="link"
          onClick={() => {
            navigate({
              pathname: `/request-log/${app?.slug}`,
              search: getRequestLogSearchParams().toString(),
            });
          }}
        >
          <FontAwesomeIcon icon={faUpRightFromSquare} className="me-icon" />
          Show in request log
        </Button>
      </div>
    ) : undefined;

  return (
    <TableCard
      responsive
      footer={tableFooter}
      hover={query.isSuccess && query.data.length > 0}
      borderTop={false}
      className="RequestLogTable align-top border-light"
    >
      <thead>
        <tr>
          <th style={{ width: 40 }}></th>
          <th style={{ width: 110 }}>Time</th>
          <th style={{ width: 100 }}>Status</th>
          <th>Request</th>
          <th style={{ width: 40 }}></th>
        </tr>
      </thead>
      <tbody>
        {query.data?.map((item, index) => (
          <RequestLogTableRow
            key={index}
            item={item}
            env={appEnv}
            openItemModal={(item) => {
              navigate({
                pathname: `/request-log/${app?.slug}/${item.request_uuid}`,
                search: getRequestLogSearchParams().toString(),
              });
            }}
          />
        ))}
        {query.isPending &&
          [...Array(Math.max(1, Math.min(expectedItems, limit)))].map((e, i) => (
            <Placeholder key={i} as="tr" animation="glow">
              <td style={{ width: 40 }}>
                <FontAwesomeIcon icon={faClock} className="ms-2 text-very-muted" />
              </td>
              <td style={{ width: 120 }}>
                <div>
                  <Placeholder xs={8} />
                </div>
                <div className="small text-muted">
                  <Placeholder xs={6} />
                </div>
              </td>
              <td>
                <Placeholder bg="primary" style={{ width: 40 }} />
              </td>
              <td>
                <div>
                  <Placeholder xs={6} />
                </div>
                <div className="small text-muted">
                  <Placeholder xs={4} />
                </div>
              </td>
              <td style={{ width: 40 }}></td>
            </Placeholder>
          ))}
        {query.isSuccess && query.data.length === 0 && (
          <tr>
            <td colSpan={5} className="py-6 text-center">
              No logged requests.
            </td>
          </tr>
        )}
      </tbody>
    </TableCard>
  );
}

type RequestLogTableWithFiltersProps = {
  endpointId?: number;
  statusCode?: string;
  minResponseTime?: number;
  limit?: number;
  expectedItems?: number;
};

export function RequestLogTableWithFilters({
  endpointId,
  statusCode,
  minResponseTime,
  limit,
  expectedItems,
}: RequestLogTableWithFiltersProps) {
  const {
    app,
    env,
    period,
    consumerId,
    consumerGroupId,
    endpointId: endpointIdFilter,
    endpointGroupId,
    statusCode: statusCodeFilter,
  } = useFilters();

  return (
    <RequestLogTable
      appId={app?.id || 0}
      appEnv={env?.slug}
      consumerId={consumerId}
      consumerGroupId={consumerGroupId}
      endpointId={endpointId || endpointIdFilter}
      endpointGroupId={endpointGroupId}
      statusCode={statusCode || statusCodeFilter}
      minResponseTime={minResponseTime}
      period={period}
      limit={limit}
      expectedItems={expectedItems}
    />
  );
}

export default RequestLogTable;
