import { faChartBar, faCircleQuestion, faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faBoltLightning,
  faBug,
  faChartColumn,
  faChartSimple,
  faEye,
  faFingerprint,
  faPenToSquare,
  faPlus,
  faRotateRight,
  faScroll,
  faToggleOff,
  faToggleOn,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Tab from "react-bootstrap/Tab";
import ToggleButton from "react-bootstrap/ToggleButton";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import "./Consumers.css";
import { ListConsumerGroupsWithTrafficResponseItem, ListConsumersWithTrafficResponseItem } from "./backend";
import ConsumersBarChart from "./charts/ConsumersBarChart";
import AppDropdown from "./components/AppDropdown";
import AppEnvDropdown, { AppEnvDropdownItems } from "./components/AppEnvDropdown";
import FilterBadges from "./components/FilterBadges";
import MainLayout from "./components/MainLayout";
import MetricNavItem from "./components/MetricNavItem";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import Pagination from "./components/Pagination";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import SparklineGraph from "./components/SparklineGraph";
import TableCard, { ColumnHeader, SortBy } from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellDropdown from "./components/TableCellDropdown";
import TableCellWithBar from "./components/TableCellWithBar";
import TeaserCard from "./components/TeaserCard";
import Tooltip from "./components/Tooltip";
import { useConfirmation } from "./contexts/ConfirmationContext";
import FilterContextProvider, { useFilters } from "./contexts/FilterContext";
import { useGlobal } from "./contexts/GlobalContext";
import ConsumerDetailsModal from "./modals/ConsumerDetailsModal";
import ConsumerFormModal from "./modals/ConsumerFormModal";
import ConsumerGroupDetailsModal from "./modals/ConsumerGroupDetailsModal";
import ConsumerGroupFormModal from "./modals/ConsumerGroupFormModal";
import CustomPeriodModal from "./modals/CustomPeriodModal";
import { formatRelativeDateTime } from "./utils/datetime";
import { formatPeriod, isCustomPeriod } from "./utils/period";

function ConsumersTeaser() {
  const { apps, launchDemo } = useGlobal();
  const { app } = useFilters();
  const navigate = useNavigate();
  const appFrameworkSlug = app?.framework ? app.framework.toLowerCase().replace(" ", "-") : undefined;

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <PageHeader buttons={pageHeaderButtons}>
        <>Consumers</>
        {apps?.length ? (
          <AppDropdown onSelect={(key) => key !== null && navigate(`/consumers/${key}`)} />
        ) : (
          <span className="text-muted">No apps set up yet</span>
        )}
      </PageHeader>
      <TeaserCard icon={faFingerprint}>
        <h2>Understand usage patterns of individual API consumers</h2>
        <p className="mt-4">
          Gain deep insights into how each consumer uses your API by associating requests with a consumer identifier.
          It's just one extra line of code to add to your project.
        </p>
        <ul className="my-4">
          <li>
            <b>Adoption:</b> Keep track of new and existing consumers, measure API adoption over time
          </li>
          <li>
            <b>Activity:</b> Easily identify your most active API consumers
          </li>
          <li>
            <b>Groups:</b> Assign consumers to groups and analyze them together
          </li>
          <li>
            <b>Search:</b> Find specific consumers or groups by name or identifier
          </li>
        </ul>
        <p className="mt-4">
          On this page you'll see charts showing unique API consumers over time as well as details about all individual
          consumers. You can easily navigate to the other dashboards while filtering to specific consumers or groups for
          further insights.
        </p>
        {appFrameworkSlug && (
          <>
            <p className="mt-4">
              To use this feature, configure your application to identify consumers. Check out our setup guide to get
              started.
            </p>
            <div className="mt-6">
              <Button
                variant="primary"
                onClick={() =>
                  window.open(`https://docs.apitally.io/frameworks/${appFrameworkSlug}#identify-consumers`, "_blank")
                }
              >
                Setup guide
              </Button>
            </div>
          </>
        )}
      </TeaserCard>
    </MainLayout>
  );
}

function Consumers() {
  const { apps, activeApp, backendClient, isTeamAdmin, isDemo } = useGlobal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [metricTab, setMetricTab] = useState("total-consumers");
  const [sortedConsumers, setSortedConsumers] = useState<ListConsumersWithTrafficResponseItem[]>();
  const [sortedConsumerGroups, setSortedConsumerGroups] = useState<ListConsumerGroupsWithTrafficResponseItem[]>();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<ListConsumersWithTrafficResponseItem[]>();
  const [searchResultGroups, setSearchResultGroups] = useState<ListConsumerGroupsWithTrafficResponseItem[]>();
  const [sortBy, setSortBy] = useState<SortBy>({ column: "requests", direction: "desc" });
  const [page, setPage] = useState(1);
  const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
  const [consumerDetailsModalConsumer, setConsumerDetailsModalConsumer] =
    useState<ListConsumersWithTrafficResponseItem>();
  const [consumerGroupDetailsModalConsumerGroup, setConsumerGroupDetailsModalConsumerGroup] =
    useState<ListConsumerGroupsWithTrafficResponseItem>();
  const [consumerFormModalConsumer, setConsumerFormModalConsumer] = useState<ListConsumersWithTrafficResponseItem>();
  const [consumerGroupFormModalConsumerGroupId, setConsumerGroupFormModalConsumerGroupId] = useState<
    number | undefined
  >();
  const { app, appSlug, env, setEnv, period, setPeriod } = useFilters();
  const { maybeConfirm } = useConfirmation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;
  const itemsPerPage = 25;

  const groupsSearchParam = searchParams.get("groups") || "false";
  const showGroups = groupsSearchParam === "true";
  const setShowGroups = (showGroups: boolean) => {
    setSearchParams(
      (searchParams) => {
        if (showGroups) {
          searchParams.set("groups", "true");
        } else {
          searchParams.delete("groups");
        }
        return searchParams;
      },
      { replace: true },
    );
  };

  useEffect(() => {
    if (app) {
      document.title = `Consumers - ${app.name} - Apitally`;
    } else {
      document.title = `Consumers - Apitally`;
    }
  }, [app]);

  const consumersQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    period,
  };
  const consumersQuery = useQuery({
    queryKey: ["consumersWithTraffic", consumersQueryParams],
    queryFn: () => backendClient!.consumers.listConsumersWithTraffic(consumersQueryParams),
    enabled: !!backendClient && !!app && !showGroups,
  });
  const consumerGroupsQuery = useQuery({
    queryKey: ["consumerGroupsWithTraffic", consumersQueryParams],
    queryFn: () => backendClient!.consumers.listConsumerGroupsWithTraffic(consumersQueryParams),
    enabled: !!backendClient && !!app && showGroups,
  });
  const requestsSparklinesQueryParams = {
    ...consumersQueryParams,
    groups: showGroups,
  };
  const requestsSparklinesQuery = useQuery({
    queryKey: ["consumerRequestsSparklines", requestsSparklinesQueryParams],
    queryFn: () => backendClient!.traffic.getConsumerRequestsSparklines(requestsSparklinesQueryParams),
    enabled: !!backendClient && !!app,
  });
  const metricsQuery = useQuery({
    queryKey: ["consumerMetrics", consumersQueryParams],
    queryFn: () => backendClient!.consumers.getConsumerMetrics(consumersQueryParams),
    enabled: !!backendClient && !!app,
  });

  useEffect(() => {
    if (!showGroups) {
      let data = consumersQuery.data;
      if (data) {
        if (metricTab === "new-consumers") {
          const filteredData = data.filter((item) => item.is_new);
          if (filteredData.length > 0) {
            data = filteredData;
          }
        }
        data = sortData(data, sortBy);
      }
      setSortedConsumers(data);
    } else {
      setSortedConsumerGroups(consumerGroupsQuery.data ? sortData(consumerGroupsQuery.data, sortBy) : undefined);
    }
  }, [consumersQuery.data, consumerGroupsQuery.data, metricTab, sortBy, showGroups]);

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      if (showGroups) {
        setSearchResult([]);
        setSearchResultGroups(
          sortedConsumerGroups?.filter((item) => searchTerms.every((term) => item.name.toLowerCase().includes(term))),
        );
      } else {
        setSearchResult(
          sortedConsumers?.filter((item) =>
            searchTerms.every(
              (term) =>
                item.name.toLowerCase().includes(term) ||
                item.identifier.toLowerCase().includes(term) ||
                item.group?.name.toLowerCase().includes(term),
            ),
          ),
        );
        setSearchResultGroups([]);
      }
    } else {
      setSearchResult(!showGroups ? sortedConsumers : []);
      setSearchResultGroups(showGroups ? sortedConsumerGroups : []);
    }
    setPage(1);
  }, [sortedConsumers, sortedConsumerGroups, search, showGroups]);

  useEffect(() => {
    setSearch("");
    setPage(1);
  }, [app, env, showGroups]);

  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  const sortData = <T extends ListConsumersWithTrafficResponseItem | ListConsumerGroupsWithTrafficResponseItem>(
    data: T[],
    sortBy: SortBy,
  ): T[] => {
    const sort = (a: T, b: T) => {
      const sign = sortBy.direction === "asc" ? 1 : -1;
      if (sortBy.column === "name") {
        return a.name.localeCompare(b.name) * sign;
      } else if (sortBy.column === "requests") {
        return (a.requests - b.requests) * sign;
      } else if (sortBy.column === "error_rate") {
        return ((a.error_rate || 0) - (b.error_rate || 0)) * sign;
      } else if (sortBy.column === "last_request") {
        return (
          (DateTime.fromISO(a.last_request_at || "1970-01-01T00:00:00Z").toMillis() -
            DateTime.fromISO(b.last_request_at || "1970-01-01T00:00:00Z").toMillis()) *
          sign
        );
      } else {
        return 0;
      }
    };
    return data?.slice().sort(sort);
  };

  const deleteConsumerGroup = async (consumerGroup: ListConsumerGroupsWithTrafficResponseItem) => {
    if (backendClient && app) {
      const promise = backendClient.consumers.deleteConsumerGroup({ appId: app.id, consumerGroupId: consumerGroup.id });
      toast.promise(promise, {
        pending: "Deleting consumer group...",
        success: "Consumer group deleted!",
        error: "Failed to delete consumer group.",
      });
      await promise;
      ["consumers", "consumersWithTraffic", "consumerGroups", "consumerGroupsWithTraffic"].forEach((key) => {
        queryClient.invalidateQueries({ queryKey: [key, { appId: app.id }] });
      });
    }
  };

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <ConsumersTeaser />;
  } else if (!appSlug) {
    return (
      <MainLayout>
        <Navigate to={`/consumers/${activeApp ? activeApp.slug : apps[0].slug}`} />
      </MainLayout>
    );
  } else if (!app) {
    return (
      <MainLayout>
        <Navigate to="/consumers" />
      </MainLayout>
    );
  } else if (!app.has_consumers) {
    return <ConsumersTeaser />;
  }

  const consumerMaxRequestCount = searchResult?.reduce((max, item) => Math.max(max, item.requests), 0);
  const consumerGroupMaxRequestCount = searchResultGroups?.reduce((max, item) => Math.max(max, item.requests), 0);
  const consumerMaxErrorRate = searchResult?.reduce((max, item) => Math.max(max, item.error_rate || 0), 0);
  const consumerGroupMaxErrorRate = searchResultGroups?.reduce((max, item) => Math.max(max, item.error_rate || 0), 0);

  const consumerRows = searchResult?.slice(itemsPerPage * (page - 1), itemsPerPage * page).map((consumer) => {
    const firstRequestDateTime = DateTime.fromISO(consumer.first_request_at);
    const lastRequestDateTime = DateTime.fromISO(consumer.last_request_at);
    const linkQueryParams = new URLSearchParams();
    linkQueryParams.set("period", period);
    linkQueryParams.set("consumer", consumer.id.toString());
    if (env) {
      linkQueryParams.set("env", env.slug);
    }
    const trafficLink = `/traffic/${app?.slug}?${linkQueryParams}`;
    const errorsLink = `/errors/${app?.slug}?${linkQueryParams}`;
    const performanceLink = `/performance/${app?.slug}?${linkQueryParams}`;
    const requestLogLink = `/request-log/${app?.slug}?${linkQueryParams}`;

    return (
      <tr key={`consumer-${consumer.id}`}>
        <td style={{ width: 40 }}>
          <FontAwesomeIcon icon={faFingerprint} className="ms-2 text-very-muted" />
        </td>
        <td>
          <div>
            <Link to={trafficLink}>{consumer.name}</Link>
            {consumer.is_new && (
              <Tooltip
                tooltip={`Made their first request on ${firstRequestDateTime.toLocaleString(DateTime.DATETIME_MED)}`}
                placement="top"
              >
                <Badge pill bg="primary" className="ms-2 outline-primary cursor-default">
                  New
                </Badge>
              </Tooltip>
            )}
          </div>
          <div className="small text-muted text-nowrap">
            {consumer.group && (
              <>
                <span className="d-inline-block text-nowrap">{consumer.group.name}</span>
                <span className="mx-1" style={{ opacity: 0.5 }}>
                  /
                </span>
              </>
            )}
            <span className="d-inline-block text-nowrap">
              <code style={{ color: "unset" }}>{consumer.identifier}</code>
            </span>
          </div>
        </td>
        <td style={{ width: 360 }}>
          <SparklineGraph
            data={requestsSparklinesQuery.data?.[consumer.id]}
            linkTo={trafficLink}
            width={280}
            marginRight={20}
          />
        </td>
        <TableCellWithBar
          showBar={sortBy.column !== "error_rate"}
          value={consumer.requests}
          maxValue={consumerMaxRequestCount}
        >
          {consumer.requests.toLocaleString()}
        </TableCellWithBar>
        <TableCellWithBar
          showBar={sortBy.column === "error_rate"}
          value={consumer.error_rate}
          maxValue={consumerMaxErrorRate}
        >
          {consumer.error_rate
            ?.toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
            .replace("%", " %") || "-"}
        </TableCellWithBar>
        <td>
          {!isCustomPeriod(period) ? (
            <span title={lastRequestDateTime.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
              <FontAwesomeIcon icon={faClock} className="me-2 text-secondary" />
              {formatRelativeDateTime(lastRequestDateTime)}
            </span>
          ) : (
            "-"
          )}
        </td>
        <TableCellDropdown>
          <Dropdown.Item as="button" onClick={() => setConsumerDetailsModalConsumer(consumer)}>
            <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
            Consumer details
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Header>Filter dashboards</Dropdown.Header>
          <Dropdown.Item as="button" onClick={() => navigate(trafficLink)}>
            <FontAwesomeIcon icon={faChartSimple} fixedWidth className="text-secondary" />
            Traffic
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => navigate(errorsLink)}>
            <FontAwesomeIcon icon={faBug} fixedWidth className="text-secondary" />
            Errors
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => navigate(performanceLink)}>
            <FontAwesomeIcon icon={faBoltLightning} fixedWidth className="text-secondary" />
            Performance
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => navigate(requestLogLink)}>
            <FontAwesomeIcon icon={faScroll} fixedWidth className="text-secondary" />
            Request log
          </Dropdown.Item>
          {isTeamAdminOrDemo && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item as="button" onClick={() => setConsumerFormModalConsumer(consumer)}>
                <FontAwesomeIcon icon={faPenToSquare} fixedWidth className="text-secondary" />
                Edit consumer
              </Dropdown.Item>
            </>
          )}
        </TableCellDropdown>
      </tr>
    );
  });
  const consumerGroupRows = searchResultGroups
    ?.slice(itemsPerPage * (page - 1), itemsPerPage * page)
    .map((consumerGroup) => {
      const lastRequestDateTime = consumerGroup.last_request_at
        ? DateTime.fromISO(consumerGroup.last_request_at)
        : undefined;
      const linkQueryParams = new URLSearchParams();
      linkQueryParams.set("period", period);
      linkQueryParams.set("consumer_group", consumerGroup.id.toString());
      if (env) {
        linkQueryParams.set("env", env.slug);
      }
      const trafficLink = `/traffic/${app?.slug}?${linkQueryParams}`;
      const errorsLink = `/errors/${app?.slug}?${linkQueryParams}`;
      const performanceLink = `/performance/${app?.slug}?${linkQueryParams}`;
      const requestLogLink = `/request-log/${app?.slug}?${linkQueryParams}`;

      return (
        <tr key={`consumer-group-${consumerGroup.id}`}>
          <td style={{ width: 40 }}>
            <FontAwesomeIcon icon={faChartBar} className="ms-2 text-very-muted" />
          </td>
          <td>
            <div>
              <Link to={trafficLink}>{consumerGroup.name}</Link>
            </div>
            <div className="small text-muted">
              {consumerGroup.consumers.length} consumer{consumerGroup.consumers.length != 1 ? "s" : ""}
            </div>
          </td>
          <td style={{ width: 360 }}>
            <SparklineGraph
              data={requestsSparklinesQuery.data?.[consumerGroup.id]}
              linkTo={trafficLink}
              width={280}
              marginRight={20}
            />
          </td>
          <TableCellWithBar
            showBar={sortBy.column !== "error_rate"}
            value={consumerGroup.requests}
            maxValue={consumerGroupMaxRequestCount}
          >
            {consumerGroup.requests.toLocaleString()}
          </TableCellWithBar>
          <TableCellWithBar
            showBar={sortBy.column === "error_rate"}
            value={consumerGroup.error_rate}
            maxValue={consumerGroupMaxErrorRate}
          >
            {consumerGroup.error_rate
              ?.toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
              .replace("%", " %") || "-"}
          </TableCellWithBar>
          <td>
            {!isCustomPeriod(period) ? (
              <span title={lastRequestDateTime?.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                <FontAwesomeIcon icon={faClock} className="me-2 text-secondary" />
                {formatRelativeDateTime(lastRequestDateTime)}
              </span>
            ) : (
              "-"
            )}
          </td>
          <TableCellDropdown>
            <Dropdown.Item as="button" onClick={() => setConsumerGroupDetailsModalConsumerGroup(consumerGroup)}>
              <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
              Consumer group details
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Filter dashboards</Dropdown.Header>
            <Dropdown.Item as="button" onClick={() => navigate(trafficLink)}>
              <FontAwesomeIcon icon={faChartSimple} fixedWidth className="text-secondary" />
              Traffic
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => navigate(errorsLink)}>
              <FontAwesomeIcon icon={faBug} fixedWidth className="text-secondary" />
              Errors
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => navigate(performanceLink)}>
              <FontAwesomeIcon icon={faBoltLightning} fixedWidth className="text-secondary" />
              Performance
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => navigate(requestLogLink)}>
              <FontAwesomeIcon icon={faScroll} fixedWidth className="text-secondary" />
              Request log
            </Dropdown.Item>
            {isTeamAdminOrDemo && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={() => setConsumerGroupFormModalConsumerGroupId(consumerGroup.id)}>
                  <FontAwesomeIcon icon={faPenToSquare} fixedWidth className="text-secondary" />
                  Edit consumer group
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  as="button"
                  className="text-danger"
                  disabled={!isTeamAdmin}
                  onClick={() =>
                    maybeConfirm({
                      title: "Delete consumer group",
                      body: (
                        <p>
                          Are you sure you want to delete the consumer group <b>{consumerGroup.name}</b>?
                        </p>
                      ),
                      onConfirm: () => deleteConsumerGroup(consumerGroup),
                      confirmButtonVariant: "danger",
                      confirmButtonText: "Delete",
                    })
                  }
                >
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                  Delete consumer group
                </Dropdown.Item>
              </>
            )}
          </TableCellDropdown>
        </tr>
      );
    });

  const toggleShowGroups = () => {
    setSearchResult(undefined);
    setSearchResultGroups(undefined);
    setShowGroups(!showGroups);
  };

  const getEmptyTableMessageRow = (message: React.ReactNode) => {
    return (
      <tr>
        <td colSpan={7} className="text-center py-6 px-4">
          {message}
        </td>
      </tr>
    );
  };

  const pageHeaderButtons = [
    <PeriodDropdown
      key="period-dropdown"
      align="end"
      period={period}
      setPeriod={setPeriod}
      setShowCustomPeriodModal={setShowCustomPeriodModal}
    />,
    <AppEnvDropdown key="app-env-dropdown" appEnv={env} setAppEnv={setEnv} />,
    <ToggleButton
      key="show-groups-toggle"
      id="show-groups-toggle"
      value="true"
      type="checkbox"
      variant="outline-light"
      checked={showGroups}
      onChange={toggleShowGroups}
    >
      <FontAwesomeIcon icon={showGroups ? faToggleOn : faToggleOff} className="me-2 text-secondary" />
      Show groups
    </ToggleButton>,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={toggleShowGroups} active={showGroups}>
        <FontAwesomeIcon icon={showGroups ? faToggleOn : faToggleOff} fixedWidth />
        Show groups
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Select period</Dropdown.Header>
      <PeriodDropdownItems period={period} setPeriod={setPeriod} />
      <Dropdown.Item active={isCustomPeriod(period)} onClick={() => setShowCustomPeriodModal(true)}>
        Custom
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Filter by environment</Dropdown.Header>
      <AppEnvDropdownItems appEnv={env} setAppEnv={setEnv} />
    </Dropdown.Menu>
  );
  const searchHeader =
    (!showGroups && !(consumersQuery.isSuccess && consumersQuery.data.length == 0)) ||
    (showGroups && !(consumerGroupsQuery.isSuccess && consumerGroupsQuery.data.length == 0)) ? (
      <TableCardSearchHeader
        search={search}
        setSearch={setSearch}
        placeholder={!showGroups ? "Search consumers" : "Search consumer groups"}
      />
    ) : undefined;
  let tableFooter = undefined;
  if (showGroups && isTeamAdminOrDemo && consumerGroupsQuery.isSuccess && consumerGroupsQuery.data.length > 0) {
    tableFooter = (
      <div className="small">
        <Button variant="link" onClick={() => setConsumerGroupFormModalConsumerGroupId(0)}>
          <FontAwesomeIcon icon={faPlus} className="me-icon" />
          Create consumer group
        </Button>
      </div>
    );
  } else if (
    !showGroups &&
    consumersQuery.isSuccess &&
    consumersQuery.data.length > itemsPerPage &&
    searchResult &&
    searchResult.length > 0
  ) {
    const showingItemsFrom = itemsPerPage * (page - 1) + 1;
    const showingItemsTo = Math.min(itemsPerPage * page, searchResult.length);
    const numberOfPages = Math.ceil(searchResult.length / itemsPerPage);
    tableFooter = (
      <Stack direction="horizontal">
        <div className="small text-muted">
          Showing consumers {showingItemsFrom.toLocaleString()}-{showingItemsTo.toLocaleString()} of{" "}
          {searchResult.length.toLocaleString()}
        </div>
        <div className="ms-auto">
          <Pagination page={page} numberOfPages={numberOfPages} setPage={setPage} />
        </div>
      </Stack>
    );
  }

  const graphColumnInfo = (
    <Tooltip placement="bottom" tooltip={`Showing requests over the last ${formatPeriod(period)}`}>
      <FontAwesomeIcon icon={faCircleQuestion} className="ms-2 text-body-tertiary" />
    </Tooltip>
  );

  const showPlaceholder = (!showGroups && consumersQuery.isPending) || (showGroups && consumerGroupsQuery.isPending);
  const emptyTable = !consumerRows?.length && !consumerGroupRows?.length;

  return (
    <MainLayout>
      <div className="Consumers">
        <CustomPeriodModal show={showCustomPeriodModal} setShow={setShowCustomPeriodModal} />
        <ConsumerDetailsModal
          consumer={consumerDetailsModalConsumer}
          setConsumer={setConsumerDetailsModalConsumer}
          onEditClick={() => {
            const consumer = consumerDetailsModalConsumer;
            setConsumerDetailsModalConsumer(undefined);
            setConsumerFormModalConsumer(consumer);
          }}
          period={period}
        />
        <ConsumerGroupDetailsModal
          consumerGroup={consumerGroupDetailsModalConsumerGroup}
          setConsumerGroup={setConsumerGroupDetailsModalConsumerGroup}
          onEditClick={() => {
            const consumerGroup = consumerGroupDetailsModalConsumerGroup;
            setConsumerGroupDetailsModalConsumerGroup(undefined);
            setConsumerGroupFormModalConsumerGroupId(consumerGroup?.id);
          }}
          period={period}
        />
        {isTeamAdminOrDemo && (
          <>
            <ConsumerFormModal consumer={consumerFormModalConsumer} setConsumer={setConsumerFormModalConsumer} />
            <ConsumerGroupFormModal
              consumerGroupId={consumerGroupFormModalConsumerGroupId}
              setConsumerGroupId={setConsumerGroupFormModalConsumerGroupId}
            />
          </>
        )}
        <PageHeader breakpoint="xl" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Consumers</>
          <AppDropdown onSelect={(appSlug) => appSlug !== null && navigate(`/consumers/${appSlug}`)} />
        </PageHeader>
        <PlanLimitsExceededAlert />
        <FilterBadges />
        {app.has_consumers && (
          <>
            <Card className="my-4 mb-lg-6 bt">
              <Card.Body className="p-0">
                <Tab.Container
                  activeKey={metricTab}
                  onSelect={(key) => {
                    if (key) {
                      setMetricTab(key);
                    }
                  }}
                >
                  <Nav>
                    <Container fluid>
                      <Row>
                        <MetricNavItem
                          eventKey="total-consumers"
                          label="Total consumers"
                          value={metricsQuery.data?.total_consumers.toLocaleString()}
                          icon={faChartColumn}
                          sm={6}
                        />
                        <MetricNavItem
                          eventKey="new-consumers"
                          label="New consumers"
                          value={metricsQuery.data?.new_consumers.toLocaleString()}
                          icon={faChartColumn}
                          description="Consumers that have made their first request in the selected period"
                          sm={6}
                        />
                      </Row>
                      <Row>
                        <Col sm={12} className="py-2 text-center chart">
                          <Tab.Content>
                            <Tab.Pane eventKey="total-consumers">
                              <ConsumersBarChart />
                            </Tab.Pane>
                            <Tab.Pane eventKey="new-consumers">
                              <ConsumersBarChart onlyNew />
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Container>
                  </Nav>
                </Tab.Container>
              </Card.Body>
            </Card>
            <TableCard
              responsive
              header={searchHeader}
              footer={tableFooter}
              hover={consumerRows && consumerRows.length > 0}
            >
              <thead>
                <tr className={emptyTable && !showPlaceholder ? "d-none d-md-table-row" : undefined}>
                  <th style={{ width: 40 }}></th>
                  <ColumnHeader
                    name="name"
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    defaultSortDirection="asc"
                    className="name-column"
                  >
                    {!showGroups ? "Consumer name" : "Consumer group name"}
                  </ColumnHeader>
                  <th>Graph{graphColumnInfo}</th>
                  <ColumnHeader name="requests" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
                    Requests
                  </ColumnHeader>
                  <ColumnHeader name="error_rate" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
                    Error rate
                  </ColumnHeader>
                  <ColumnHeader
                    name="last_request"
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    defaultSortDirection="desc"
                    disabled={isCustomPeriod(period)}
                  >
                    Last request
                  </ColumnHeader>
                  <th style={{ width: 40 }}></th>
                </tr>
              </thead>
              <tbody>
                {consumerRows}
                {consumerGroupRows}
                {showPlaceholder && (
                  <Placeholder as="tr" animation="glow">
                    <td style={{ width: 40 }}></td>
                    <td>
                      <div className="text-primary">
                        <Placeholder xs={6} />
                      </div>
                      <div className="small text-body-secondary">
                        <Placeholder xs={8} style={{ width: showGroups ? 90 : undefined }} />
                      </div>
                    </td>
                    <td style={{ width: 360 }}></td>
                    <td>
                      <Placeholder xs={4} />
                    </td>
                    <td>
                      <Placeholder xs={4} />
                    </td>
                    <td>
                      <Placeholder xs={8} />
                    </td>
                    <td style={{ width: 40 }}></td>
                  </Placeholder>
                )}
                {!showGroups &&
                  consumersQuery.isSuccess &&
                  consumersQuery.data.length === 0 &&
                  getEmptyTableMessageRow(<>No consumers have been identified for this environment yet.</>)}
                {showGroups &&
                  consumerGroupsQuery.isSuccess &&
                  consumerGroupsQuery.data.length === 0 &&
                  getEmptyTableMessageRow(
                    <>
                      <div>
                        {!env
                          ? "There aren't any consumer groups for this application yet."
                          : "No consumer groups match the current filter criteria."}
                      </div>
                      {isTeamAdminOrDemo && (
                        <div className="mt-6">
                          <Button variant="primary" onClick={() => setConsumerGroupFormModalConsumerGroupId(0)}>
                            Create consumer group
                          </Button>
                        </div>
                      )}
                    </>,
                  )}
                {!showGroups &&
                  consumersQuery.isSuccess &&
                  consumersQuery.data.length > 0 &&
                  search &&
                  searchResult &&
                  searchResult.length === 0 &&
                  getEmptyTableMessageRow("Your search didn't match any consumers.")}
                {showGroups &&
                  consumerGroupsQuery.isSuccess &&
                  consumerGroupsQuery.data.length > 0 &&
                  search &&
                  searchResultGroups &&
                  searchResultGroups.length === 0 &&
                  getEmptyTableMessageRow("Your search didn't match any consumer groups.")}
              </tbody>
            </TableCard>
          </>
        )}
      </div>
    </MainLayout>
  );
}

function ConsumersWithFilterContext() {
  return (
    <FilterContextProvider
      disableConsumer
      disableConsumerGroup
      disableEndpoint
      disableEndpointGroup
      disableMethod
      disableStatusCode
    >
      <Consumers />
    </FilterContextProvider>
  );
}

export default ConsumersWithFilterContext;
