import { DateTime } from "luxon";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { ListConsumerGroupsWithTrafficResponseItem } from "../backend";
import DetailsModalContent from "../components/DetailsModalContent";
import ModalHeaderEditButton from "../components/ModalHeaderEditButton";
import { formatRelativeDateTime } from "../utils/datetime";
import { formatPeriod } from "../utils/period";

type ConsumerGroupDetailsModalProps = {
  consumerGroup?: ListConsumerGroupsWithTrafficResponseItem;
  setConsumerGroup: React.Dispatch<React.SetStateAction<ListConsumerGroupsWithTrafficResponseItem | undefined>>;
  onEditClick: () => void;
  period: string;
};

function ConsumerGroupDetailsModal({
  consumerGroup,
  setConsumerGroup,
  onEditClick,
  period,
}: ConsumerGroupDetailsModalProps) {
  const lastRequestDateTime = consumerGroup?.last_request_at
    ? DateTime.fromISO(consumerGroup.last_request_at)
    : undefined;

  return (
    <Modal show={consumerGroup !== undefined} onHide={() => setConsumerGroup(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>Consumer group details</Modal.Title>
        <ModalHeaderEditButton onClick={onEditClick} />
      </Modal.Header>
      <Modal.Body>
        <DetailsModalContent
          labelValueMap={{
            "ID": consumerGroup?.id,
            "Name": consumerGroup?.name,
            "Consumers": (
              <ul className="my-0 px-0" style={{ listStyle: "none" }}>
                {consumerGroup?.consumers.map((consumer) => <li key={consumer.id}>{consumer.name}</li>)}
              </ul>
            ),
            "Requests": (
              <>
                {consumerGroup?.requests.toLocaleString()}
                <span className="ms-2 small text-muted">(in last {formatPeriod(period)})</span>
              </>
            ),
            "Error rate":
              consumerGroup?.error_rate
                ?.toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
                .replace("%", " %") || "-",
            "Last request": (
              <span title={lastRequestDateTime?.toLocaleString(DateTime.DATETIME_FULL)}>
                {formatRelativeDateTime(lastRequestDateTime)}
              </span>
            ),
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setConsumerGroup(undefined)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConsumerGroupDetailsModal;
