import { parseChangelogItems } from "./utils";

const changelog = [
  {
    date: "2025-02-15",
    title: "Data export",
    description: `
      The traffic dashboard and request log now include a button to export the data to a CSV file.

      *Available on the Premium plan.*
    `,
  },
  {
    date: "2025-02-09",
    title: "Most recent requests",
    description: `
      The endpoint insights modal now includes tables of the most recent logged requests, with the ability to jump to the request log for more details.

      *Only applies if you have enabled request logging.*
    `,
    image: "most-recent-requests.webp",
  },
  {
    date: "2025-01-28",
    title: "New payment provider",
    description: `
      Apitally now uses Stripe for subscriptions and payments. All existing customers have been migrated from Lemon Squeezy.
    `,
  },
  {
    date: "2024-12-13",
    title: "Request log",
    description: `
      It's finally here! You can now opt-in to log individual API requests and responses to Apitally.

      The request log then shows you a chronological list of requests, allowing you to inspect details such as the full URL, headers, payloads and more.

      *Available on the Starter and Premium plan.*
    `,
  },
  {
    date: "2024-11-12",
    title: "Microsoft Teams integration",
    description: `
      You can now receive alert notifications in a Microsoft Teams channel! The integration can be configured on the Settings page.

      *Available on the Starter and Premium plan.*
    `,
    image: "teams-integration.webp",
  },
  {
    date: "2024-10-28",
    title: "Slack integration",
    description: `
      You can now receive alert notifications in a Slack channel! The integration can be configured on the Settings page.

      *Available on the Starter and Premium plan.*
    `,
  },
  {
    date: "2024-10-15",
    title: "Custom alerts",
    description: `
      This is a big one! You can now set up custom alerts based on requests, errors, and performance metrics.

      Various filtering and scheduling options are available for maximum flexibility. Try it out and please share your feedback!
    `,
    image: "custom-alerts.webp",
  },
  {
    date: "2024-09-27",
    title: "Changelog",
    description: `
      You're looking at the new changelog in Apitally! From now on we'll keep you updated about new features and improvements here.
    `,
  },
  {
    date: "2024-09-26",
    title: "Persistent filters",
    description: `
      Filters applied on a dashboard are now persisted when you navigate to a different page.
    `,
  },
  {
    date: "2024-09-25",
    title: "Click & drag on charts to select period",
    description: `
      You can now click and drag on any timeseries chart in Apitally to filter the dashboard to a custom period. Try it out!
    `,
    image: "period-selection.webp",
  },
  {
    date: "2024-09-19",
    title: "Delete account",
    description: `
      Users can now delete their account from the settings page.
    `,
    image: "delete-account.webp",
  },
  {
    date: "2024-09-12",
    title: "Updated consumers dashboard",
    description: `
      The consumers dashboard now shows metrics at the top and highlights new consumers.

      The table is now filtered to only show consumers that have made requests in the selected period.
    `,
    image: "new-consumers.webp",
  },
  {
    date: "2024-08-28",
    title: "Requests per minute",
    description: `
      The traffic dashboard now includes a metric and chart for number of requests per minute.
    `,
    image: "requests-per-minute-2.webp",
  },
  {
    date: "2024-08-27",
    title: "Metrics on errors dashboard",
    description: `
      The errors dashboard now shows error count and error rate metrics at the top. Clicking on the metrics updates the chart and table below.
    `,
    image: "error-metrics-4.webp",
  },
  {
    date: "2024-08-26",
    title: "New performance dashboard",
    description: `
      The new performance dashboard is all about Apdex scores and response time percentiles. Check it out!

      You can now also configure Apdex thresholds for your apps and individual endpoints.
    `,
    image: "performance-dashboard-3.webp",
  },
  {
    date: "2024-08-07",
    title: "New endpoint groups page",
    description: `
      Got many endpoints? The new endpoint groups page help you understand usage of different parts of your API.

      Endpoints are grouped automatically based on common path prefixes.
    `,
    image: "endpoint-groups-2.webp",
  },
  {
    date: "2024-07-26",
    title: "Redesign",
    description: `
      The Apitally dashboard now has a fresh new look with a sidebar for easier navigation.
    `,
  },
];

export default parseChangelogItems(changelog);
