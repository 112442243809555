import { useQuery } from "@tanstack/react-query";
import {
  BarController,
  BarElement,
  Chart as ChartJS,
  LinearScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-luxon";
import { getReasonPhrase } from "http-status-codes";
import { memo } from "react";
import { Bar } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import ChartContainer from "../components/ChartContainer";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint, EndpointError } from "../types/Endpoint";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(LinearScale, TimeSeriesScale, BarController, BarElement, Title, Tooltip);

type ErrorsBarChartProps = {
  endpoint?: Endpoint;
  endpointError?: EndpointError;
  enabled?: boolean;
  displayTitle?: boolean;
  filterErrorType?: "client" | "server";
};

function ErrorsBarChart({
  endpoint,
  endpointError,
  enabled = true,
  displayTitle = true,
  filterErrorType,
}: ErrorsBarChartProps) {
  const { backendClient } = useGlobal();
  const {
    app,
    period,
    setPeriod,
    resetPeriod,
    env,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
  } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method: endpointError?.method || endpoint?.method || method,
    path: endpointError?.path || endpoint?.path,
    statusCode: endpointError?.status_code || statusCode,
    period,
  };
  const query = useQuery({
    queryKey: ["errorsChart", queryParams],
    queryFn: () => backendClient!.errors.getErrorsChart(queryParams),
    enabled: !!backendClient && !!app && enabled,
  });

  let chart;
  if (query.isSuccess && query.data.length > 0) {
    let title = "Number of client and server errors";
    if (filterErrorType === "client") {
      title = "Number of client errors";
    } else if (filterErrorType === "server") {
      title = "Number of server errors";
    }
    const dangerColor = getColor("danger");
    const chartOptions = merge(
      getChartOptions({
        labels: query.data[0].time_windows,
        title: displayTitle ? title : undefined,
        tooltipTotalFooter: true,
        setPeriod,
        resetPeriod,
      }),
      {
        scales: {
          x: { stacked: true },
          y: { stacked: true, ticks: { precision: 0 } },
        },
        plugins: {
          tooltip: {
            filter: (context: TooltipItem<"bar">) => {
              return context.parsed.y > 0;
            },
            itemSort: (context1: TooltipItem<"bar">, context2: TooltipItem<"bar">) => {
              if (context1.dataset.label === context2.dataset.label) {
                return 0;
              } else {
                return context1.dataset.label === "Server error" ? 1 : -1;
              }
            },
            callbacks: {
              label: (context: TooltipItem<"bar">) => {
                const statusCodeCounts = (context.dataset as any).statusCodeCounts[context.dataIndex] as number[][];
                return statusCodeCounts.map(([statusCode, count]) => {
                  return ` ${statusCode} ${getReasonPhrase(statusCode)}: ${count.toLocaleString()}`;
                });
              },
            },
          },
        },
      },
    );
    const chartData = {
      labels: query.data[0].time_windows,
      datasets: query.data
        .filter(
          (dataset) =>
            !filterErrorType ||
            (filterErrorType === "client" && dataset.error_type === "Client error") ||
            (filterErrorType === "server" && dataset.error_type === "Server error"),
        )
        .map((dataset) => ({
          label: dataset.error_type,
          data: dataset.request_counts,
          statusCodeCounts: dataset.status_code_counts,
          backgroundColor: dataset.error_type === "Server error" ? dangerColor : "#dca6a6",
          hoverBackgroundColor: dataset.error_type === "Server error" ? dangerColor : "#dca6a6",
        })),
    };
    chart = <Bar data={chartData} options={chartOptions} />;
  }

  return <ChartContainer>{chart}</ChartContainer>;
}

export default memo(ErrorsBarChart);
