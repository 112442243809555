import { useAuth0 } from "@auth0/auth0-react";
import { ErrorBoundary } from "@sentry/react";
import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AcceptInvite from "./AcceptInvite";
import AdminTeams from "./AdminTeams";
import Alerts from "./Alerts";
import AppSetupInstructions from "./AppSetupInstructions";
import Apps from "./Apps";
import Consumers from "./Consumers";
import EndpointGroups from "./EndpointGroups";
import Errors from "./Errors";
import "./Main.css";
import Onboarding from "./Onboarding";
import Performance from "./Performance";
import RequestLog from "./RequestLog";
import Settings from "./Settings";
import SlackRedirect from "./SlackRedirect";
import Traffic from "./Traffic";
import Uptime from "./Uptime";
import MainLayout from "./components/MainLayout";
import PageSpinner from "./components/PageSpinner";
import { useGlobal } from "./contexts/GlobalContext";

function Main() {
  const { logout } = useAuth0();
  const { teams, activeTeam, setActiveTeam, userError, onboardingComplete, isSiteAdmin } = useGlobal();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const teamSearchParam = searchParams.get("team");
    if (teamSearchParam && teams && activeTeam) {
      const teamId = parseInt(teamSearchParam);
      if (!isNaN(teamId) && activeTeam.id !== teamId) {
        const team = teams.find((team) => team.id === teamId);
        if (team) {
          setActiveTeam(team);
        }
      }
      searchParams.delete("team");
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }
  }, [searchParams, teams, activeTeam]);

  if (userError) {
    return (
      <MainLayout>
        <Alert variant="danger" className="mt-5 mt-xl-6">
          {userError}
          <div className="mt-4">
            <Button variant="danger" onClick={() => logout()}>
              Back to login
            </Button>
          </div>
        </Alert>
      </MainLayout>
    );
  } else if (searchParams.has("team")) {
    return <PageSpinner />;
  }

  const errorFallback = (
    <MainLayout>
      <Alert variant="danger" className="mt-5 mt-xl-6">
        Oops, something went wrong. Please reload the page to continue.
      </Alert>
    </MainLayout>
  );

  return (
    <div className="Main h-100">
      <ErrorBoundary fallback={errorFallback}>
        <Routes>
          <Route path="/invite/:code/accept" element={<AcceptInvite />} />
          {onboardingComplete === false ? (
            <>
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="*" element={<Navigate to="/onboarding" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Navigate to="/apps" />} />
              <Route path="/apps" element={<Apps />} />
              <Route path="/apps/:appSlug?/setup" element={<AppSetupInstructions />} />
              <Route path="/request-log/:appSlug?/:requestUuid?" element={<RequestLog />} />
              <Route path="/traffic/:appSlug?/:endpointId?" element={<Traffic />} />
              <Route path="/errors/:appSlug?/:endpointErrorId?" element={<Errors />} />
              <Route path="/performance/:appSlug?/:endpointId?" element={<Performance />} />
              <Route path="/consumers/:appSlug?" element={<Consumers />} />
              <Route path="/endpoint-groups/:appSlug?" element={<EndpointGroups />} />
              <Route path="/uptime" element={<Uptime />} />
              <Route path="/alerts/:alertRuleId?/:action?" element={<Alerts />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/slack" element={<SlackRedirect />} />
              <Route path="/onboarding" element={<Navigate to="/" />} />
              {isSiteAdmin && <Route path="/admin/teams" element={<AdminTeams />} />}
            </>
          )}
        </Routes>
        <ToastContainer position="bottom-right" />
      </ErrorBoundary>
    </div>
  );
}

export default Main;
