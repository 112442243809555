import * as Sentry from "@sentry/react";
import { keepPreviousData, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PostHogProvider } from "posthog-js/react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import Auth0ProviderWithNavigate from "./contexts/Auth0ProviderWithNavigate";
import { ConfirmationContextProvider } from "./contexts/ConfirmationContext";
import GlobalContextProvider from "./contexts/GlobalContext";
import "./index.scss";
import Main from "./Main";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    enableTracing: false,
    profilesSampleRate: 0,
    replaysSessionSampleRate: 0,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
      placeholderData: keepPreviousData,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: import.meta.env.VITE_PUBLIC_POSTHOG_API_HOST,
        ui_host: import.meta.env.VITE_PUBLIC_POSTHOG_UI_HOST,
      }}
    >
      <Auth0ProviderWithNavigate>
        <QueryClientProvider client={queryClient}>
          <GlobalContextProvider>
            <ConfirmationContextProvider>
              <Main />
            </ConfirmationContextProvider>
          </GlobalContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Auth0ProviderWithNavigate>
    </PostHogProvider>
  </BrowserRouter>,
);
