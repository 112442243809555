import { DateTime } from "luxon";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import { ListAppsResponseItem } from "../backend";
import AppEnvBadge from "../components/AppEnvBadge";
import DetailsModalContent from "../components/DetailsModalContent";
import ModalHeaderEditButton from "../components/ModalHeaderEditButton";

type AppDetailsModalProps = {
  app?: ListAppsResponseItem;
  setApp: React.Dispatch<React.SetStateAction<ListAppsResponseItem | undefined>>;
  onEditClick: () => void;
};

function AppDetailsModal({ app, setApp, onEditClick }: AppDetailsModalProps) {
  const envBadges = app?.envs.map((env) => {
    const badge = <AppEnvBadge key={env.id} env={env} className="me-1" />;
    return (
      <Link key={env.id} to={`/traffic/${app.slug}?env=${env.slug}`}>
        {badge}
      </Link>
    );
  });
  const createdDate = app?.created_at ? DateTime.fromISO(app?.created_at) : undefined;

  return (
    <Modal size="lg" show={app !== undefined} onHide={() => setApp(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>App details</Modal.Title>
        <ModalHeaderEditButton onClick={onEditClick} />
      </Modal.Header>
      <Modal.Body>
        <DetailsModalContent
          labelValueMap={{
            "ID": app?.id,
            "Name": app?.name,
            "Framework": app?.framework,
            "Environments": envBadges,
            "Client ID": <code>{app?.client_id}</code>,
            "Created": (
              <span className="text-nowrap" title={createdDate?.toLocaleString(DateTime.DATETIME_FULL)}>
                {createdDate?.toLocaleString(DateTime.DATE_FULL)}
              </span>
            ),
          }}
          modalSize="lg"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setApp(undefined)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AppDetailsModal;
