import { faBell, faBellSlash, faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faBellSlash as faBellSlashSolid,
  faBell as faBellSolid,
  faClone,
  faCog,
  faEye,
  faEyeSlash,
  faFilterCircleXmark,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import classNames from "classnames";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import "./Alerts.css";
import { ListAlertRulesResponse, ListAlertRulesResponseItem } from "./backend";
import CustomIcon from "./components/CustomIcon";
import CustomDropdown from "./components/Dropdown";
import FilterBadge from "./components/FilterBadge";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import PageSubHeader from "./components/PageSubHeader";
import TableCard, { ColumnHeader, SortBy } from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellDropdown from "./components/TableCellDropdown";
import TeaserCard from "./components/TeaserCard";
import Tooltip from "./components/Tooltip";
import { useConfirmation } from "./contexts/ConfirmationContext";
import { useGlobal } from "./contexts/GlobalContext";
import AlertRuleFormModal from "./modals/AlertRuleFormModal";
import AlertRuleModal from "./modals/AlertRuleModal";
import { formatRelativeDateTime } from "./utils/datetime";
import { formatMinutes } from "./utils/period";

type AlertRuleStateBadgeProps = {
  state?: string;
};

export function AlertRuleStateBadge({ state }: AlertRuleStateBadgeProps) {
  if (!state) {
    return;
  }
  return (
    <Badge
      className={classNames({
        "outline-primary": state === "ok",
        "outline-danger": state === "triggered",
        "outline-secondary": state !== "ok" && state !== "triggered",
      })}
    >
      {state == "ok" ? "OK" : capitalize(state?.replace("_", " "))}
    </Badge>
  );
}

function AlertsTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <PageHeader buttons={pageHeaderButtons}>
        <>Alerts</>
        <span className="text-muted">No apps set up yet</span>
      </PageHeader>
      <TeaserCard icon={faBellSolid}>
        <h2>Get notified immediately when things go wrong</h2>
        <p className="mt-4">
          Here you can create custom alerts based on API requests, errors, and performance metrics. Various filtering
          and check scheduling options offer maximum flexibility. You can get notified via email, Slack or Microsoft
          Teams when an alert is triggered.
        </p>
        <p>Below are some examples for alerts you could set up:</p>
        <ul>
          <li>A specific endpoint or endpoint group received more than 500 requests in an hour</li>
          <li>An API consumer has made less than 100 requests in the last 24 hours (except weekends)</li>
          <li>The median response time for an endpoint is higher than 1 second</li>
          <li>The Apdex score for the last hour of requests has dropped below 0.93</li>
          <li>More than 1 GB of data was received in the last 5 minutes</li>
          <li>The error rate has been higher than 5% in the last 15 minutes</li>
          <li>There have been any server errors</li>
        </ul>
      </TeaserCard>
    </MainLayout>
  );
}

type AlertRulesTableProps = {
  appId?: number;
  query: UseQueryResult<ListAlertRulesResponse, unknown>;
  type: "custom" | "builtin";
  openAlertRuleDetailsModal: (alertRule: ListAlertRulesResponseItem) => void;
  toggleMuted: (alertRule: ListAlertRulesResponseItem, muted: boolean) => void;
  duplicateAlertRule: (alertRule: ListAlertRulesResponseItem) => void;
  deleteAlertRule: (alertRule: ListAlertRulesResponseItem) => void;
};

function AlertRulesTable({
  appId,
  query,
  type,
  openAlertRuleDetailsModal,
  toggleMuted,
  duplicateAlertRule,
  deleteAlertRule,
}: AlertRulesTableProps) {
  const { apps, isTeamAdmin, isDemo } = useGlobal();
  const [sortedData, setSortedData] = useState<ListAlertRulesResponseItem[]>();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<ListAlertRulesResponseItem[]>();
  const [sortBy, setSortBy] = useState<SortBy>({ column: "last_triggered", direction: "desc" });
  const [showDisabled, setShowDisabled] = useState(false);
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;
  const app = apps?.find((app) => app.id === appId);
  const navigate = useNavigate();
  const { maybeConfirm } = useConfirmation();

  useEffect(() => {
    const filteredData = query.data?.filter(
      (alertRule) =>
        (type === "custom" && alertRule.type === "custom") || (type === "builtin" && alertRule.type !== "custom"),
    );
    setSortedData(filteredData ? sortData(filteredData, sortBy) : undefined);
  }, [query.data, sortBy]);

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        sortedData?.filter((item) =>
          searchTerms.every(
            (term) =>
              item.name.toLowerCase().includes(term) ||
              item.description?.toLowerCase().includes(term) ||
              item.trigger?.toLowerCase().includes(term) ||
              item.app.name.toLowerCase().includes(term) ||
              item.app_env.name.toLowerCase().includes(term),
          ),
        ),
      );
    } else {
      setSearchResult(sortedData);
    }
  }, [app, search, sortedData]);

  const sortData = <T extends ListAlertRulesResponseItem>(data: T[], sortBy: SortBy): T[] => {
    const getStateIndex = (state: string) => {
      return ["ok", "triggered"].indexOf(state);
    };
    const sort = (a: T, b: T) => {
      const sign = sortBy.direction === "asc" ? 1 : -1;
      if (sortBy.column === "name") {
        return a.name.localeCompare(b.name) * sign;
      } else if (sortBy.column === "state") {
        return (getStateIndex(a.state) - getStateIndex(b.state)) * sign;
      } else if (sortBy.column === "last_triggered") {
        return (
          (DateTime.fromISO(a.last_triggered_at || "1970-01-01T00:00:00Z").toMillis() -
            DateTime.fromISO(b.last_triggered_at || "1970-01-01T00:00:00Z").toMillis()) *
          sign
        );
      } else if (sortBy.column === "app") {
        return a.app !== b.app
          ? a.app.name.localeCompare(b.app.name) * sign
          : (a.app_env.order || 0) - (b.app_env.order || 0) * sign;
      } else {
        return 0;
      }
    };
    return data?.slice().sort(sort);
  };

  const allAlertRulesDisabled = sortedData?.every((alertRule) => alertRule.state === "disabled");
  const someAlertRulesDisabled = sortedData?.some((alertRule) => alertRule.state === "disabled");
  const alertRuleRows = searchResult
    ?.filter((alertRule) => !appId || alertRule.app.id === appId)
    .filter((alertRule) => showDisabled || alertRule.state !== "disabled" || allAlertRulesDisabled)
    .map((alertRule) => {
      const lastTriggeredAt = DateTime.fromISO(alertRule.last_triggered_at || "");
      const filterCount =
        (alertRule.filters?.consumer ? 1 : 0) +
        (alertRule.filters?.consumer_group ? 1 : 0) +
        (alertRule.filters?.endpoint ? 1 : 0) +
        (alertRule.filters?.endpoint_group ? 1 : 0);
      const filterTooltip = (
        <div className="text-start mt-n2">
          {alertRule.filters?.consumer && (
            <>
              <div className="very-small text-muted-tooltip mt-2">Consumer</div>
              <div>{alertRule.filters?.consumer?.name}</div>
            </>
          )}
          {alertRule.filters?.consumer_group && (
            <>
              <div className="very-small text-muted-tooltip mt-2">Consumer group</div>
              <div>{alertRule.filters?.consumer_group?.name}</div>
            </>
          )}
          {alertRule.filters?.endpoint && (
            <>
              <div className="very-small text-muted-tooltip mt-2">Endpoint</div>
              <div>{alertRule.filters?.endpoint?.name}</div>
            </>
          )}
          {alertRule.filters?.endpoint_group && (
            <>
              <div className="very-small text-muted-tooltip mt-2">Endpoint group</div>
              <div>{alertRule.filters?.endpoint_group?.name}</div>
            </>
          )}
        </div>
      );
      return (
        <tr
          key={`${alertRule.app_env.id}:${alertRule.type}:${alertRule.id || 0}`}
          className="cursor-pointer"
          onClick={(e) => {
            const cell = (e.target as HTMLElement).closest("td");
            if (!cell?.classList.contains("TableCellDropdown")) {
              openAlertRuleDetailsModal(alertRule);
            }
          }}
        >
          <td
            style={{ width: 40 }}
            className={classNames("ps-3", {
              "text-very-muted": alertRule.state !== "triggered",
              "text-danger": alertRule.state === "triggered",
            })}
          >
            {alertRule.state === "triggered" && !alertRule.muted ? (
              <CustomIcon fixedWidth src="/icons/bell-ring-regular.svg" />
            ) : (
              <Tooltip
                condition={alertRule.state === "disabled" || alertRule.muted}
                tooltip={alertRule.muted ? "Notifications muted" : "Checks disabled"}
                placement="left"
              >
                <FontAwesomeIcon
                  fixedWidth
                  icon={alertRule.state === "disabled" || alertRule.muted ? faBellSlash : faBell}
                />
              </Tooltip>
            )}
          </td>
          <td>
            <div className="text-nowrap">{alertRule.name}</div>
            <div className="small text-muted text-nowrap">
              {alertRule.type === "health_check_failed" && <>Request to health check endpoint failed</>}
              {alertRule.type === "liveness_check_failed" && <>Stopped receiving heartbeats</>}
              {alertRule.type === "custom" && (
                <>
                  {alertRule.trigger} over {formatMinutes(alertRule.period_minutes || 0)}
                  {filterCount > 0 && (
                    <>
                      <span className="mx-1" style={{ opacity: 0.5 }}>
                        /
                      </span>
                      <Tooltip tooltip={filterTooltip} placement="top">
                        <span>Filters apply</span>
                      </Tooltip>
                    </>
                  )}
                </>
              )}
            </div>
          </td>
          <td style={{ minWidth: 80 }}>
            <AlertRuleStateBadge state={alertRule.state} />
          </td>
          <td>
            <span title={lastTriggeredAt.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
              <FontAwesomeIcon icon={faClock} className="me-2 text-secondary" />
              {formatRelativeDateTime(lastTriggeredAt) || "Never"}
            </span>
          </td>
          <td>
            <div className="text-nowrap">{alertRule.app.name}</div>
            <div className="small text-muted text-nowrap">{alertRule.app_env.name}</div>
          </td>
          <TableCellDropdown>
            <Dropdown.Item as="button" onClick={() => openAlertRuleDetailsModal(alertRule)}>
              <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
              Alert details
            </Dropdown.Item>
            {alertRule.type === "custom" && alertRule.id && isTeamAdminOrDemo && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={() => navigate(`/alerts/${alertRule.id}/edit`)}>
                  <FontAwesomeIcon icon={faPenToSquare} fixedWidth className="text-secondary" />
                  Edit alert
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => duplicateAlertRule(alertRule)}>
                  <FontAwesomeIcon icon={faClone} fixedWidth className="text-secondary" />
                  Duplicate alert
                </Dropdown.Item>
                {alertRule.muted ? (
                  <Dropdown.Item as="button" disabled={!isTeamAdmin} onClick={() => toggleMuted(alertRule, false)}>
                    <FontAwesomeIcon icon={faBellSolid} fixedWidth className="text-secondary" />
                    Unmute notifications
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item as="button" disabled={!isTeamAdmin} onClick={() => toggleMuted(alertRule, true)}>
                    <FontAwesomeIcon icon={faBellSlashSolid} fixedWidth className="text-secondary" />
                    Mute notifications
                  </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                  as="button"
                  className="text-danger"
                  disabled={!isTeamAdmin}
                  onClick={() =>
                    maybeConfirm({
                      title: "Delete alert",
                      body: (
                        <p>
                          Are you sure you want to delete the alert <b>{alertRule.name}</b>?
                        </p>
                      ),
                      onConfirm: () => deleteAlertRule(alertRule),
                      confirmButtonVariant: "danger",
                      confirmButtonText: "Delete",
                    })
                  }
                >
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                  Delete alert
                </Dropdown.Item>
              </>
            )}
            {(alertRule.type === "liveness_check_failed" || alertRule.type === "health_check_failed") &&
              isTeamAdminOrDemo && (
                <>
                  <Dropdown.Divider />
                  {alertRule.type === "health_check_failed" && (
                    <Dropdown.Item
                      as="button"
                      disabled={!isTeamAdmin}
                      onClick={() => navigate(`/uptime?configureHealthChecks=${alertRule.app.id}`)}
                    >
                      <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" />
                      Configure health checks
                    </Dropdown.Item>
                  )}
                  {alertRule.type === "liveness_check_failed" && (
                    <Dropdown.Item
                      as="button"
                      disabled={!isTeamAdmin}
                      onClick={() => navigate(`/apps?editApp=${alertRule.app.id}`)}
                    >
                      <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" />
                      Configure uptime alerts
                    </Dropdown.Item>
                  )}
                  {alertRule.state !== "disabled" && (
                    <>
                      {alertRule.muted ? (
                        <Dropdown.Item
                          as="button"
                          disabled={!isTeamAdmin}
                          onClick={() => toggleMuted(alertRule, false)}
                        >
                          <FontAwesomeIcon icon={faBellSolid} fixedWidth className="text-secondary" />
                          Unmute notifications
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item as="button" disabled={!isTeamAdmin} onClick={() => toggleMuted(alertRule, true)}>
                          <FontAwesomeIcon icon={faBellSlashSolid} fixedWidth className="text-secondary" />
                          Mute notifications
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                </>
              )}
          </TableCellDropdown>
        </tr>
      );
    });

  const searchHeader = !(query.isSuccess && query.data.length == 0) ? (
    <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search alerts" />
  ) : undefined;
  const tableFooter =
    someAlertRulesDisabled && !allAlertRulesDisabled ? (
      <div className="small">
        {!showDisabled && (
          <Button variant="link" onClick={() => setShowDisabled(true)}>
            <FontAwesomeIcon icon={faEye} className="me-icon" />
            Show disabled alerts
          </Button>
        )}
        {showDisabled && (
          <Button variant="link" onClick={() => setShowDisabled(false)}>
            <FontAwesomeIcon icon={faEyeSlash} className="me-icon" />
            Hide disabled alerts
          </Button>
        )}
      </div>
    ) : undefined;

  return (
    <TableCard
      className="AlertRulesTable"
      responsive
      hover={alertRuleRows && alertRuleRows.length > 0}
      header={searchHeader}
      footer={tableFooter}
    >
      <thead>
        <tr>
          <th style={{ width: 40 }}></th>
          <ColumnHeader name="name" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
            Alert name
          </ColumnHeader>
          <ColumnHeader name="state" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
            State
          </ColumnHeader>
          <ColumnHeader name="last_triggered" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
            Last triggered
          </ColumnHeader>
          <ColumnHeader name="app" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
            App
          </ColumnHeader>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {alertRuleRows}
        {query.isPending && (
          <Placeholder as="tr" animation="glow">
            <td style={{ width: 40 }}></td>
            <td>
              <div>
                <Placeholder xs={6} />
              </div>
              <div className="small text-body-secondary">
                <Placeholder xs={4} />
              </div>
            </td>
            <td className="text-primary">
              <Placeholder xs={4} />
            </td>
            <td>
              <Placeholder xs={6} />
            </td>
            <td>
              <div>
                <Placeholder xs={6} />
              </div>
              <div className="small text-body-secondary">
                <Placeholder xs={2} />
              </div>
            </td>
            <td style={{ width: 40 }}></td>
          </Placeholder>
        )}
        {query.isSuccess && !!sortedData && sortedData.length === 0 && (
          <tr>
            <td colSpan={6} className="text-center py-6 px-4">
              You haven't created any alerts{appId ? " for this app" : ""} yet.
            </td>
          </tr>
        )}
        {query.isSuccess && !!sortedData && sortedData.length > 0 && !!searchResult && searchResult.length === 0 && (
          <tr>
            <td colSpan={6} className="text-center py-6 px-4">
              Your search doesn't match any alerts.
            </td>
          </tr>
        )}
      </tbody>
    </TableCard>
  );
}

function Alerts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeTeam, apps, backendClient, isTeamAdmin, isDemo, refreshTeamPlan, refreshAlertCounts } = useGlobal();
  const [appId, setAppId] = useState<number | undefined>(parseInt(searchParams.get("app") || "0", 10) || undefined);
  const [formModalAlertRuleId, setFormModalAlertRuleId] = useState<number>();
  const [detailsModalAlertRule, setDetailsModalAlertRule] = useState<ListAlertRulesResponseItem>();
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;
  const app = apps?.find((app) => app.id === appId);
  const { alertRuleId, action } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    document.title = `Alerts - Apitally`;
  }, []);

  useEffect(() => {
    if (appId?.toString() !== searchParams.get("app")) {
      setSearchParams((searchParams) => {
        if (appId) {
          searchParams.set("app", appId.toString());
        } else {
          searchParams.delete("app");
        }
        return searchParams;
      });
    }
  }, [appId]);

  const queryParams = { teamId: activeTeam?.id || 0 };
  const query = useQuery({
    queryKey: ["alertRules", queryParams],
    queryFn: () => backendClient!.alerts.listAlertRules(queryParams),
    enabled: !!backendClient && !!activeTeam,
    refetchInterval: 60000,
  });

  const findAlertRule = (alertRuleId: string) => {
    if (!isNaN(parseInt(alertRuleId))) {
      return query.data?.find((item) => item.id === parseInt(alertRuleId));
    } else {
      const [alertType, appEnvId] = splitAlertRuleSlug(alertRuleId);
      if (alertType && appEnvId) {
        return query.data?.find(
          (item) => item.type === alertType.replaceAll("-", "_") && item.app_env.id === parseInt(appEnvId),
        );
      }
    }
  };

  useEffect(() => {
    if (alertRuleId) {
      const alertRule = findAlertRule(alertRuleId);
      if (alertRule) {
        if (action) {
          setDetailsModalAlertRule(undefined);
          if (action === "edit" && alertRule.id && alertRule.id !== formModalAlertRuleId) {
            setFormModalAlertRuleId(alertRule.id);
          }
        } else {
          setFormModalAlertRuleId(undefined);
          if (
            (alertRule.id && alertRule.id !== detailsModalAlertRule?.id) ||
            (!alertRule.id &&
              (alertRule?.type !== detailsModalAlertRule?.type ||
                alertRule?.app_env.id !== detailsModalAlertRule?.app_env.id))
          ) {
            setDetailsModalAlertRule(alertRule);
          }
        }
      }
    } else if (!alertRuleId) {
      if (detailsModalAlertRule) {
        setDetailsModalAlertRule(undefined);
      }
      if (formModalAlertRuleId && formModalAlertRuleId > 0) {
        setFormModalAlertRuleId(undefined);
      }
    }
  }, [alertRuleId, action, query.data, detailsModalAlertRule, formModalAlertRuleId]);

  const toggleMuted = async (alertRule: ListAlertRulesResponseItem, muted: boolean) => {
    if (!backendClient) {
      return;
    }
    let promise;
    if (alertRule.type === "custom" && alertRule.id) {
      promise = backendClient.alerts.updateAlertRule({ alertRuleId: alertRule.id, requestBody: { muted } });
    } else if (alertRule.type === "liveness_check_failed" || alertRule.type === "health_check_failed") {
      const requestBody =
        alertRule.type === "health_check_failed" ? { health_check_muted: muted } : { liveness_check_muted: muted };
      promise = backendClient.apps.updateAppEnv({
        appId: alertRule.app.id,
        appEnvId: alertRule.app_env.id,
        requestBody,
      });
    } else {
      return;
    }
    if (muted) {
      toast.promise(promise, {
        pending: "Muting alert notifications...",
        success: "Alert notifications muted!",
        error: "Failed to mute alert notifications.",
      });
    } else {
      toast.promise(promise, {
        pending: "Unmuting alert notifications...",
        success: "Alert notifications unmuted!",
        error: "Failed to unmute alert notifications.",
      });
    }
    await promise;
    query.refetch();
    refreshAlertCounts();
    if (alertRule.id) {
      queryClient.invalidateQueries({ queryKey: ["alertRule", { alertRuleId: alertRule.id }] });
    } else if (detailsModalAlertRule == alertRule) {
      setDetailsModalAlertRule((prevAlertRule) => (prevAlertRule ? { ...prevAlertRule, muted } : undefined));
    }
  };

  const duplicateAlertRule = (alertRule: ListAlertRulesResponseItem) => {
    setFormModalAlertRuleId(alertRule.id ? alertRule.id * -1 : undefined);
  };

  const deleteAlertRule = async (alertRule: ListAlertRulesResponseItem) => {
    if (!backendClient) {
      return;
    }
    if (alertRule.type === "custom" && alertRule.id) {
      const promise = backendClient.alerts.deleteAlertRule({ alertRuleId: alertRule.id });
      toast.promise(promise, {
        pending: "Deleting alert...",
        success: "Alert deleted!",
        error: "Failed to delete alert.",
      });
      await promise;
      query.refetch();
      refreshTeamPlan();
      refreshAlertCounts();
    }
  };

  const getAlertRuleSlug = (alertRule: ListAlertRulesResponseItem) => {
    if (alertRule.id) {
      return alertRule.id.toString();
    } else if (alertRule.app_env.id && alertRule.type) {
      return `${alertRule.type.replaceAll("_", "-")}-${alertRule.app_env.id}`;
    }
  };

  const splitAlertRuleSlug = (slug: string) => {
    const i = slug.lastIndexOf("-");
    return i === -1 ? [slug, ""] : [slug.slice(0, i), slug.slice(i + 1)];
  };

  const openAlertRuleDetailsModal = (alertRule: ListAlertRulesResponseItem) => {
    setDetailsModalAlertRule(alertRule);
    const alertRuleSlug = getAlertRuleSlug(alertRule);
    navigate({ pathname: `/alerts/${alertRuleSlug}`, search: searchParams.toString() });
  };

  const closeAlertRuleDetailsModal = () => {
    setDetailsModalAlertRule(undefined);
    if (alertRuleId) {
      navigate({ pathname: "/alerts", search: searchParams.toString() });
    }
  };

  const closeAlertRuleFormModal = () => {
    setFormModalAlertRuleId(undefined);
    if (alertRuleId && action) {
      navigate({ pathname: "/alerts", search: searchParams.toString() });
    }
  };

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <AlertsTeaser />;
  }

  const pageHeaderButtons = [
    <CustomDropdown
      key="app-dropdown"
      title={app?.name || "All apps"}
      icon="/icons/filter-regular.svg"
      align="end"
      className="ms-auto"
      onSelect={(appId: any) => appId !== null && setAppId(parseInt(appId, 10) || undefined)}
    >
      <Dropdown.Menu>
        <Dropdown.Item key={0} eventKey={0} active={!appId}>
          All apps
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>Filter by app</Dropdown.Header>
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
          {apps?.map((app) => (
            <Dropdown.Item key={app.id} eventKey={app.id} active={app.id === appId}>
              {app.name}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </CustomDropdown>,
  ];
  if (isTeamAdminOrDemo) {
    pageHeaderButtons.push(
      <Button
        key="create-alert-rule-button"
        variant="primary"
        className="float-end"
        onClick={() => setFormModalAlertRuleId(0)}
      >
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Create alert
      </Button>,
    );
  }
  const pageHeaderDropdown =
    isTeamAdminOrDemo || appId ? (
      <Dropdown.Menu>
        {isTeamAdminOrDemo && (
          <Dropdown.Item as="button" onClick={() => setFormModalAlertRuleId(0)}>
            <FontAwesomeIcon icon={faPlus} fixedWidth />
            Create alert
          </Dropdown.Item>
        )}
        {appId && (
          <Dropdown.Item as="button" onClick={() => setAppId(undefined)}>
            <FontAwesomeIcon icon={faFilterCircleXmark} fixedWidth />
            Clear filter
          </Dropdown.Item>
        )}
        <Dropdown.Divider />
        <Dropdown.Header>Filter by app</Dropdown.Header>
        {apps?.map((app) => (
          <Dropdown.Item key={app.id} active={app.id === appId} onClick={() => setAppId(app.id)}>
            {app.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    ) : undefined;

  return (
    <MainLayout>
      <div className="Alerts">
        <AlertRuleModal
          alertRule={detailsModalAlertRule}
          onHide={closeAlertRuleDetailsModal}
          toggleMuted={toggleMuted}
        />
        <AlertRuleFormModal alertRuleId={formModalAlertRuleId} onHide={closeAlertRuleFormModal} />
        <PageHeader breakpoint="md" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          Alerts
        </PageHeader>
        {appId && (
          <div className="small d-md-none mb-4">
            <FilterBadge
              icon="/icons/filter-regular.svg"
              label="App"
              value={apps?.find((app) => app.id === appId)?.name}
              removeFilter={() => setAppId(undefined)}
            />
          </div>
        )}
        <AlertRulesTable
          appId={appId}
          query={query}
          type="custom"
          openAlertRuleDetailsModal={openAlertRuleDetailsModal}
          toggleMuted={toggleMuted}
          duplicateAlertRule={duplicateAlertRule}
          deleteAlertRule={deleteAlertRule}
        />
        <PageSubHeader description="These alerts are created automatically for uptime and health checks.">
          <>Uptime alerts</>
        </PageSubHeader>
        <AlertRulesTable
          appId={appId}
          query={query}
          type="builtin"
          openAlertRuleDetailsModal={openAlertRuleDetailsModal}
          toggleMuted={toggleMuted}
          duplicateAlertRule={duplicateAlertRule}
          deleteAlertRule={deleteAlertRule}
        />
      </div>
    </MainLayout>
  );
}

export default Alerts;
