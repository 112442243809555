import { Form, Formik } from "formik";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import FilterBadges from "../components/FilterBadges";
import UpgradePlanAlert from "../components/UpgradePlanAlert";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";

type RequestLogExportModalProps = {
  show: boolean;
  onHide: () => void;
};

function RequestLogExportModal({ show, onHide }: RequestLogExportModalProps) {
  const { backendClient, teamPlan } = useGlobal();
  const {
    app,
    env,
    period,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
    url,
    requestBody,
    responseBody,
    minRequestSize,
    maxRequestSize,
    minResponseSize,
    maxResponseSize,
    minResponseTime,
    maxResponseTime,
  } = useFilters();
  const exportAllowed = !!teamPlan?.features.data_export;

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          if (backendClient && app) {
            const promise = backendClient.requestLog.exportRequestLogCsv({
              appId: app?.id || 0,
              appEnv: env?.slug,
              period,
              consumerId,
              consumerGroupId,
              endpointId,
              endpointGroupId,
              method,
              statusCode,
              url,
              requestBody,
              responseBody,
              minRequestSize,
              maxRequestSize,
              minResponseSize,
              maxResponseSize,
              minResponseTime,
              maxResponseTime,
            });
            toast.promise(promise, {
              pending: "Exporting data...",
              success: "Data exported!",
              error: "Failed to export data.",
            });
            const downloadUrl = await promise;
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.click();
          }
          onHide();
          resetForm();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, resetForm, isValid }) => (
        <Modal show={show} onHide={onHide} onExited={() => resetForm()}>
          <Modal.Header closeButton>
            <Modal.Title>Export request log as CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!exportAllowed && (
              <UpgradePlanAlert>
                Data export is a premium feature that is not included in the {teamPlan?.name}. Please upgrade your
                team's plan to export data and enjoy additional benefits!
              </UpgradePlanAlert>
            )}
            <div className="mb-4">
              <FilterBadges showAll />
            </div>
            <Form onSubmit={handleSubmit}>
              <div className="small text-muted">
                <i>Note:</i> The export is limited to 1 million requests.
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()} disabled={isSubmitting || !isValid || !exportAllowed}>
              Export
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

export default RequestLogExportModal;
