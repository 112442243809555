import {
  faAngleRight,
  faBan,
  faChartArea,
  faChartColumn,
  faChartSimple,
  faCircleExclamation,
  faCog,
  faEye,
  faEyeSlash,
  faFileCsv,
  faFilter,
  faRotateRight,
  faScroll,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Tab from "react-bootstrap/Tab";
import { Link, Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import semver from "semver";

import "./Traffic.css";
import { TrafficEndpointsTableItem } from "./backend";
import DataTransferredBarChart from "./charts/DataTransferredBarChart";
import ErrorRatesLineChart from "./charts/ErrorRatesLineChart";
import RequestsBarChart from "./charts/RequestsBarChart";
import RequestsPerMinuteLineChart from "./charts/RequestsPerMinuteLineChart";
import AppDropdown from "./components/AppDropdown";
import AppEnvDropdown, { AppEnvDropdownItems } from "./components/AppEnvDropdown";
import CustomIcon from "./components/CustomIcon";
import FilterBadges from "./components/FilterBadges";
import FilterOffcanvas from "./components/FilterOffcanvas";
import MainLayout from "./components/MainLayout";
import MethodPath from "./components/MethodPath";
import MetricNavItem from "./components/MetricNavItem";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import Pagination from "./components/Pagination";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import TableCard, { ColumnHeader, SortBy } from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellDropdown from "./components/TableCellDropdown";
import TableCellWithBar from "./components/TableCellWithBar";
import TeaserCard from "./components/TeaserCard";
import Tooltip from "./components/Tooltip";
import FilterContextProvider, { useFilters } from "./contexts/FilterContext";
import { useGlobal } from "./contexts/GlobalContext";
import CustomPeriodModal from "./modals/CustomPeriodModal";
import EndpointConfigModal from "./modals/EndpointConfigModal";
import EndpointModal from "./modals/EndpointModal";
import TrafficExportModal from "./modals/TrafficExportModal";
import { Endpoint } from "./types/Endpoint";
import { formatDataSize } from "./utils/numbers";
import { isCustomPeriod } from "./utils/period";

function TrafficEndpointsTable() {
  const { backendClient, isTeamAdmin, isDemo } = useGlobal();
  const {
    app,
    env,
    consumerId,
    consumerGroupId,
    endpointId,
    setEndpointId,
    endpointGroupId,
    method,
    statusCode,
    period,
  } = useFilters();
  const [showExcluded, setShowExcluded] = useState(false);
  const [modalEndpoint, setModalEndpoint] = useState<Endpoint>();
  const [endpointConfigModalEndpoint, setEndpointConfigModalEndpoint] = useState<Endpoint>();
  const [sortedData, setSortedData] = useState<TrafficEndpointsTableItem[]>();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<TrafficEndpointsTableItem[]>();
  const [sortBy, setSortBy] = useState<SortBy>({ column: "requests", direction: "desc" });
  const [page, setPage] = useState(1);
  const { endpointId: modalEndpointId } = useParams();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const itemsPerPage = 50;

  let endpointCount = 1;
  if (endpointId) {
    endpointCount = 1;
  } else if (endpointGroupId) {
    endpointCount = 3;
  } else if (app && env) {
    endpointCount = app.envs.find((e) => e.slug === env.slug)?.endpoint_count || 1;
  } else {
    const envEndpointCounts = app?.envs.map((env) => env.endpoint_count) || [];
    endpointCount = Math.max(1, ...envEndpointCounts);
  }

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    period,
    method,
    statusCode,
  };
  const query = useQuery({
    queryKey: ["trafficEndpointsTable", queryParams],
    queryFn: () => backendClient!.traffic.getTrafficEndpointsTable(queryParams),
    enabled: !!backendClient && !!app,
  });
  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  const includedData = query.data?.filter((item) => !item.excluded);
  const maxRequestCount = includedData?.reduce((max, item) => Math.max(max, item.total_request_count), 0);
  const maxErrorRate = includedData?.reduce((max, item) => Math.max(max, item.error_rate), 0);
  const maxDataTransferred = includedData?.reduce((max, item) => Math.max(max, item.data_transferred), 0);
  const noDataReceivedYet = query.isSuccess && query.data.length === 0;
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  useEffect(() => {
    setSortedData(query.data ? sortData(query.data, sortBy) : undefined);
  }, [query.data, sortBy]);

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        sortedData?.filter((item) =>
          searchTerms.every(
            (term) => item.path.toLowerCase().includes(term) || item.method.toLowerCase().includes(term),
          ),
        ),
      );
    } else {
      setSearchResult(sortedData);
    }
    setPage(1);
  }, [search, sortedData]);

  useEffect(() => {
    setSearch("");
    setPage(1);
  }, [app]);

  useEffect(() => {
    if (modalEndpointId && query.data) {
      const endpoint = query.data.find((item) => item.id === parseInt(modalEndpointId));
      if (endpoint?.id !== modalEndpoint?.id) {
        setModalEndpoint(endpoint);
      }
    }
  }, [modalEndpointId, query.data]);

  const openEndpointModal = (endpoint: Endpoint) => {
    setModalEndpoint(endpoint);
    if (app && endpoint.id) {
      navigate({ pathname: `/traffic/${app.slug}/${endpoint.id}`, search: searchParams.toString() });
    }
  };

  const closeEndpointModal = () => {
    setModalEndpoint(undefined);
    if (app && modalEndpointId) {
      navigate({ pathname: `/traffic/${app.slug}`, search: searchParams.toString() });
    }
  };

  const sortData = (data: TrafficEndpointsTableItem[], sortBy: SortBy) => {
    const sort = (a: TrafficEndpointsTableItem, b: TrafficEndpointsTableItem) => {
      const sign = sortBy.direction === "asc" ? 1 : -1;

      if (a.excluded && !b.excluded) {
        return 1;
      } else if (!a.excluded && b.excluded) {
        return -1;
      }

      if (sortBy.column === "endpoint") {
        return a.path.localeCompare(b.path) * sign;
      }

      if (a.total_request_count > 0 && b.total_request_count === 0) {
        return 1 * sign;
      } else if (a.total_request_count === 0 && b.total_request_count > 0) {
        return -1 * sign;
      }

      if (sortBy.column === "requests") {
        return (a.total_request_count - b.total_request_count) * sign;
      } else if (sortBy.column === "error_rate") {
        return (a.error_rate - b.error_rate) * sign;
      } else if (sortBy.column === "data_transferred") {
        return (a.data_transferred - b.data_transferred) * sign;
      } else {
        return 0;
      }
    };
    return data?.slice().sort(sort);
  };

  const allItemsExcluded = searchResult?.every((item) => item.excluded);
  let tableFooter;
  if (query.isSuccess && searchResult) {
    if (query.data.length > itemsPerPage) {
      const showingItemsFrom = itemsPerPage * (page - 1) + 1;
      const showingItemsTo = Math.min(itemsPerPage * page, searchResult.length);
      const numberOfPages = Math.ceil(searchResult.length / itemsPerPage);
      tableFooter = (
        <Stack direction="horizontal">
          <div className="small text-muted">
            Showing endpoints {showingItemsFrom.toLocaleString()}-{showingItemsTo.toLocaleString()} of{" "}
            {searchResult.length.toLocaleString()}
          </div>
          <div className="ms-auto">
            <Pagination page={page} numberOfPages={numberOfPages} setPage={setPage} />
          </div>
        </Stack>
      );
    } else {
      const excludedItems = searchResult.filter((item) => item.excluded);
      if (excludedItems.length > 0 && !allItemsExcluded) {
        tableFooter = (
          <div className="small">
            {!showExcluded ? (
              <Button variant="link" onClick={() => setShowExcluded(true)}>
                <FontAwesomeIcon icon={faEye} className="me-icon" />
                Show {excludedItems.length} excluded endpoint
                {excludedItems.length > 1 ? "s" : ""}
              </Button>
            ) : (
              <Button variant="link" onClick={() => setShowExcluded(false)}>
                <FontAwesomeIcon icon={faEyeSlash} className="me-icon" />
                Hide {excludedItems.length} excluded endpoint
                {excludedItems.length > 1 ? "s" : ""}
              </Button>
            )}
          </div>
        );
      }
    }
  }

  const searchHeader = !noDataReceivedYet ? (
    <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search endpoints" />
  ) : undefined;

  if (!app) {
    return;
  }

  return (
    <div className="TrafficEndpointsTable">
      <EndpointModal endpoint={modalEndpoint} onHide={closeEndpointModal} />
      <EndpointConfigModal
        app={app}
        endpoint={endpointConfigModalEndpoint}
        setEndpoint={setEndpointConfigModalEndpoint}
        refresh={refresh}
      />
      <TableCard
        header={searchHeader}
        footer={tableFooter}
        hover={searchResult && searchResult.length > 0}
        responsive
        className="align-middle"
      >
        <thead>
          <tr>
            <th style={{ width: 40 }}></th>
            <ColumnHeader name="endpoint" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Endpoint
            </ColumnHeader>
            <ColumnHeader name="requests" sortBy={sortBy} setSortBy={setSortBy}>
              Requests
            </ColumnHeader>
            <ColumnHeader name="error_rate" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              Error rate
            </ColumnHeader>
            <ColumnHeader name="data_transferred" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              Data transferred
            </ColumnHeader>
            <th style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>
          {searchResult
            ?.filter(
              (item) =>
                !item.excluded ||
                showExcluded ||
                allItemsExcluded ||
                (query.isSuccess && query.data.length > itemsPerPage),
            )
            ?.slice(itemsPerPage * (page - 1), itemsPerPage * page)
            .map((item) => (
              <tr
                key={`${item.method} ${item.path}`}
                className={classNames("cursor-pointer", { excluded: item.excluded })}
                onClick={(e) => {
                  const cell = (e.target as HTMLElement).closest("td");
                  if (!cell?.classList.contains("TableCellDropdown")) {
                    openEndpointModal(item);
                  }
                }}
              >
                <td style={{ width: 40 }}>
                  <Tooltip condition={item.excluded} tooltip="Excluded">
                    <FontAwesomeIcon icon={item.excluded ? faBan : faAngleRight} className="ms-2 text-very-muted" />
                  </Tooltip>
                </td>
                <td className="endpoint-column">
                  <MethodPath method={item.method} path={item.path} />
                </td>
                <TableCellWithBar
                  showBar={!item.excluded && (sortBy.column === "requests" || sortBy.column === "endpoint")}
                  value={item.total_request_count}
                  maxValue={maxRequestCount}
                >
                  {item.total_request_count.toLocaleString()}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "error_rate"}
                  value={item.error_rate}
                  maxValue={maxErrorRate}
                >
                  {item.total_request_count > 0
                    ? item.error_rate
                        .toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
                        .replace("%", " %")
                    : "-"}
                  {item.server_error_count > 0 ? (
                    <Tooltip
                      tooltip={`${item.server_error_count} server error${item.server_error_count > 1 ? "s" : ""}`}
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} className="ms-2 text-danger" />
                    </Tooltip>
                  ) : undefined}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "data_transferred"}
                  value={item.data_transferred}
                  maxValue={maxDataTransferred}
                >
                  {formatDataSize(item.data_transferred)}
                </TableCellWithBar>
                <TableCellDropdown>
                  <Dropdown.Item as="button" onClick={() => openEndpointModal(item)}>
                    <FontAwesomeIcon icon={faChartSimple} fixedWidth className="text-secondary" />
                    Endpoint insights
                  </Dropdown.Item>
                  {item.id && (
                    <>
                      <Dropdown.Item as="button" onClick={() => setEndpointId(item.id || undefined)}>
                        <FontAwesomeIcon icon={faFilter} fixedWidth className="text-secondary" />
                        Filter to endpoint
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => navigate(`/request-log/${app?.slug}?endpoint=${item.id}`)}
                      >
                        <FontAwesomeIcon icon={faScroll} fixedWidth className="text-secondary" />
                        Request log
                      </Dropdown.Item>
                    </>
                  )}
                  {isTeamAdminOrDemo && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setEndpointConfigModalEndpoint({
                            id: item.id,
                            method: item.method,
                            path: item.path,
                            excluded: item.excluded,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" />
                        Endpoint settings
                      </Dropdown.Item>
                    </>
                  )}
                </TableCellDropdown>
              </tr>
            ))}
          {query.isSuccess && query.data.length > 0 && search && searchResult && searchResult.length === 0 && (
            <tr>
              <td style={{ width: 40 }}></td>
              <td colSpan={4} className="text-center py-6">
                Your search didn't match any endpoints.
              </td>
              <td style={{ width: 40 }}></td>
            </tr>
          )}
          {query.isPending &&
            [...Array(endpointCount)].map((e, i) => (
              <Placeholder key={i} as="tr" animation="glow">
                <td style={{ width: 40 }}>
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td style={{ width: "40%" }}>
                  <Placeholder
                    xs={3}
                    size="lg"
                    bg="primary"
                    className="me-4"
                    style={{ width: "3.5rem", borderRadius: "0.375rem" }}
                  />
                  <Placeholder xs={8} />
                </td>
                <td>
                  <Placeholder xs={6} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td style={{ width: 40 }}></td>
              </Placeholder>
            ))}
          {noDataReceivedYet && (
            <tr>
              <td colSpan={6} className="text-center py-6">
                <div>
                  We haven't received any data from this {env ? "environment" : "application"} yet.
                  <br />
                  It could take up to 1 minute to show up.
                </div>
                <div className="mt-4">
                  <Button variant="primary" onClick={refresh} disabled={query.isFetching}>
                    Try again now
                  </Button>
                </div>
                <div className="mt-4 small text-muted">
                  Want to see the <Link to={`/apps/${app.slug}/setup`}>setup instructions</Link> again?
                  <br />
                  Still no luck? Please{" "}
                  <a
                    href={`mailto:support@apitally.io?subject=${encodeURIComponent(
                      `I'm having trouble setting up my ${app.framework} app`,
                    )}`}
                  >
                    send us an email
                  </a>
                  . We're here to help.
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </TableCard>
    </div>
  );
}

function TrafficTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <div className="TrafficTeaser">
        <PageHeader buttons={pageHeaderButtons}>
          <>Traffic</>
          <span className="text-muted">No apps set up yet</span>
        </PageHeader>
        <TeaserCard icon={faChartSimple} iconStyle={{ right: "-40px" }}>
          <h2>Analyze usage of your API</h2>
          <p className="mt-4">
            The traffic dashboard provides actionable usage insights about your API and each endpoint, allowing you to
            make informed, data-driven engineering and product decisions.
          </p>
          <ul className="my-4">
            <li>
              <b>Requests:</b> Track number of successful and failed requests over time
            </li>
            <li>
              <b>Throughput:</b> Measure the number of requests per minute and identify peaks
            </li>
            <li>
              <b>Payload sizes:</b> Keep track of request & response sizes and total data transferred
            </li>
          </ul>
          <p>
            The dashboard provides various filtering options, allowing you to focus on specific consumers or endpoint
            groups.
          </p>
        </TeaserCard>
      </div>
    </MainLayout>
  );
}

function Traffic() {
  const { apps, activeApp, backendClient } = useGlobal();
  const [showFilterPane, setShowFilterPane] = useState(false);
  const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [metricTab, setMetricTab] = useState("requests");
  const {
    app,
    appSlug,
    env,
    setEnv,
    period,
    setPeriod,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
  } = useFilters();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;

  const metricsQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
    period,
  };
  const metricsQuery = useQuery({
    queryKey: ["trafficMetrics", metricsQueryParams],
    queryFn: () => backendClient!.traffic.getTrafficMetrics(metricsQueryParams),
    enabled: !!backendClient && !!app,
  });
  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  useEffect(() => {
    if (app) {
      document.title = `Traffic - ${app.name} - Apitally`;
    } else {
      document.title = `Traffic - Apitally`;
    }
  }, [app]);

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <TrafficTeaser />;
  } else if (!appSlug) {
    if (activeApp) {
      return (
        <MainLayout>
          <Navigate to={`/traffic/${activeApp.slug}`} />
        </MainLayout>
      );
    } else {
      return (
        <MainLayout>
          <Navigate to={`/traffic/${apps[0].slug}`} />
        </MainLayout>
      );
    }
  } else if (!app) {
    return (
      <MainLayout>
        <Navigate to="/traffic" />
      </MainLayout>
    );
  }

  const showUpgradeClientAlert =
    metricsQuery.isSuccess &&
    !metricsQuery.data.total_data_transferred &&
    env?.versions &&
    env.versions.apitally &&
    ((env.client?.startsWith("python:") && semver.lt(env.versions.apitally, "0.4.0")) ||
      (env.client?.startsWith("js:") && semver.lt(env.versions.apitally, "0.2.0")));

  const filterCount =
    (consumerId ? 1 : 0) +
    (consumerGroupId ? 1 : 0) +
    (endpointId ? 1 : 0) +
    (endpointGroupId ? 1 : 0) +
    (method ? 1 : 0) +
    (statusCode ? 1 : 0);
  const filterCountBadge =
    filterCount > 0 ? (
      <Badge pill bg="secondary" className="ms-3">
        {filterCount}
      </Badge>
    ) : undefined;
  const pageHeaderButtons = [
    <PeriodDropdown
      key="period-dropdown"
      align="end"
      period={period}
      setPeriod={setPeriod}
      setShowCustomPeriodModal={setShowCustomPeriodModal}
    />,
    <AppEnvDropdown key="app-env-dropdown" appEnv={env} setAppEnv={setEnv} />,
    <Button key="filter-button" variant="outline-light" onClick={() => setShowFilterPane(true)}>
      <CustomIcon src="/icons/filter-regular.svg" className="me-lg-icon text-secondary" />
      <span className="d-none d-lg-inline">Filter</span>
      {filterCountBadge}
    </Button>,
    <Button key="export-button" variant="outline-light" onClick={() => setShowExportModal(true)}>
      <CustomIcon src="/icons/file-csv-regular.svg" className="text-secondary me-lg-icon" />
      <span className="d-none d-lg-inline">Export</span>
    </Button>,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Item as="button" disabled={isLoading} onClick={() => setShowExportModal(true)}>
        <FontAwesomeIcon icon={faFileCsv} fixedWidth />
        Export
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => setShowFilterPane(true)}>
        <FontAwesomeIcon icon={faFilter} fixedWidth />
        Filter
        {filterCountBadge}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Select period</Dropdown.Header>
      <PeriodDropdownItems period={period} setPeriod={setPeriod} />
      <Dropdown.Item active={isCustomPeriod(period)} onClick={() => setShowCustomPeriodModal(true)}>
        Custom
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Filter by environment</Dropdown.Header>
      <AppEnvDropdownItems appEnv={env} setAppEnv={setEnv} />
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Traffic">
        <FilterOffcanvas show={showFilterPane} setShow={setShowFilterPane} />
        <CustomPeriodModal show={showCustomPeriodModal} setShow={setShowCustomPeriodModal} />
        <TrafficExportModal show={showExportModal} onHide={() => setShowExportModal(false)} />
        <PageHeader breakpoint="lg" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Traffic</>
          <AppDropdown onSelect={(key) => key !== null && navigate(`/traffic/${key}`)} />
        </PageHeader>
        <PlanLimitsExceededAlert />
        <FilterBadges />
        <Card className="my-4 mb-lg-6 bt">
          <Card.Body className="p-0">
            <Tab.Container
              activeKey={metricTab}
              onSelect={(key) => {
                if (key) {
                  setMetricTab(key);
                }
              }}
            >
              <Nav>
                <Container fluid>
                  <Row>
                    <MetricNavItem
                      eventKey="requests"
                      label="Total requests"
                      value={metricsQuery.data?.total_request_count.toLocaleString()}
                      icon={faChartColumn}
                      sm={6}
                      lg={3}
                    />
                    <MetricNavItem
                      eventKey="requests-per-minute"
                      label="Requests per minute"
                      value={metricsQuery.data?.requests_per_minute.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                      icon={faChartArea}
                      sm={6}
                      lg={3}
                    />
                    <MetricNavItem
                      eventKey="error-rate"
                      label="Error rate"
                      value={
                        (metricsQuery.data?.total_request_count ?? 0 > 0)
                          ? metricsQuery.data?.error_rate
                              .toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
                              .replace("%", " %")
                          : "-"
                      }
                      description="Percentage of failed requests (4xx and 5xx responses)"
                      icon={faChartArea}
                      sm={6}
                      lg={3}
                    />
                    <MetricNavItem
                      eventKey="data-transferred"
                      label="Data transferred"
                      value={
                        metricsQuery.isSuccess ? (
                          <>
                            {formatDataSize(metricsQuery.data.total_data_transferred)}
                            {showUpgradeClientAlert && (
                              <Tooltip
                                tooltip="Please update the Apitally SDK in your project to capture request and response payload sizes."
                                placement="bottom"
                              >
                                <FontAwesomeIcon icon={faCircleExclamation} className="ms-2 small text-danger" />
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          "-"
                        )
                      }
                      description="Sum of the request and response payload sizes"
                      icon={faChartColumn}
                      sm={6}
                      lg={3}
                    />
                  </Row>
                  <Row>
                    <Col sm={12} className="py-2 text-center chart">
                      <Tab.Content>
                        <Tab.Pane eventKey="requests">
                          <RequestsBarChart />
                        </Tab.Pane>
                        <Tab.Pane eventKey="requests-per-minute">
                          <RequestsPerMinuteLineChart enabled={metricTab === "requests-per-minute"} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="error-rate">
                          <ErrorRatesLineChart enabled={metricTab === "error-rate"} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="data-transferred">
                          <DataTransferredBarChart enabled={metricTab === "data-transferred"} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Container>
              </Nav>
            </Tab.Container>
          </Card.Body>
        </Card>
        <TrafficEndpointsTable />
      </div>
    </MainLayout>
  );
}

function TrafficWithFilterContext() {
  return (
    <FilterContextProvider>
      <Traffic />
    </FilterContextProvider>
  );
}

export default TrafficWithFilterContext;
