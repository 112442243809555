import {
  faAngleRight,
  faBan,
  faBoltLightning,
  faChartSimple,
  faCog,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFilter,
  faPenToSquare,
  faQuestionCircle,
  faRotateRight,
  faScroll,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";

import "./Performance.scss";
import { PerformanceEndpointsTableItem } from "./backend";
import ApdexScoreLineChart from "./charts/ApdexScoreLineChart";
import ResponseTimeLineChart from "./charts/ResponseTimeLineChart";
import ApdexScoreFace from "./components/ApdexScoreFace";
import AppDropdown from "./components/AppDropdown";
import AppEnvDropdown, { AppEnvDropdownItems } from "./components/AppEnvDropdown";
import CustomIcon from "./components/CustomIcon";
import FilterBadges from "./components/FilterBadges";
import FilterOffcanvas from "./components/FilterOffcanvas";
import MainLayout from "./components/MainLayout";
import MethodPath from "./components/MethodPath";
import Metric from "./components/Metric";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import Pagination from "./components/Pagination";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import TableCard, { ColumnHeader, SortBy } from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellDropdown from "./components/TableCellDropdown";
import TableCellWithBar from "./components/TableCellWithBar";
import TeaserCard from "./components/TeaserCard";
import Tooltip from "./components/Tooltip";
import FilterContextProvider, { useFilters } from "./contexts/FilterContext";
import { useGlobal } from "./contexts/GlobalContext";
import ApdexScoreExplanationModal from "./modals/ApdexScoreExplanationModal";
import CustomPeriodModal from "./modals/CustomPeriodModal";
import EndpointConfigModal from "./modals/EndpointConfigModal";
import EndpointModal from "./modals/EndpointModal";
import { Endpoint } from "./types/Endpoint";
import { formatResponseTime } from "./utils/numbers";
import { isCustomPeriod } from "./utils/period";

function PerformanceMetricDropdown({ children }: { children: React.ReactNode[] }) {
  return (
    <Dropdown align="end" className="PerformanceMetricDropdown ms-auto no-caret">
      <Dropdown.Toggle variant="outline-light border-0">
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </Dropdown.Toggle>
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
}

function PerformanceEndpointsTable() {
  const { backendClient, isTeamAdmin, isDemo } = useGlobal();
  const {
    app,
    env,
    period,
    consumerId,
    consumerGroupId,
    endpointId,
    setEndpointId,
    endpointGroupId,
    method,
    statusCode,
  } = useFilters();
  const [showExcluded, setShowExcluded] = useState(false);
  const [modalEndpoint, setModalEndpoint] = useState<Endpoint>();
  const [endpointConfigModalEndpoint, setEndpointConfigModalEndpoint] = useState<Endpoint>();
  const [sortedData, setSortedData] = useState<PerformanceEndpointsTableItem[]>();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<PerformanceEndpointsTableItem[]>();
  const [sortBy, setSortBy] = useState<SortBy>({ column: "apdex_score", direction: "asc" });
  const [page, setPage] = useState(1);
  const { endpointId: modalEndpointId } = useParams();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const itemsPerPage = 50;

  let endpointCount = 1;
  if (endpointId) {
    endpointCount = 1;
  } else if (endpointGroupId) {
    endpointCount = 3;
  } else if (app && env) {
    endpointCount = app.envs.find((e) => e.slug === env.slug)?.endpoint_count || 1;
  } else {
    const envEndpointCounts = app?.envs.map((env) => env.endpoint_count) || [];
    endpointCount = Math.max(1, ...envEndpointCounts);
  }

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
    period,
  };
  const query = useQuery({
    queryKey: ["performanceEndpointsTable", queryParams],
    queryFn: () => backendClient!.performance.getPerformanceEndpointsTable(queryParams),
    enabled: !!backendClient && !!app,
  });
  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  const includedData = query.data?.filter((item) => !item.excluded);
  const maxInverseApdexScore = includedData?.reduce((max, item) => Math.max(max, 1 - item.apdex_score), 0);
  const maxSlowRequestCount = includedData?.reduce(
    (max, item) => Math.max(max, item.apdex_tolerated_count + item.apdex_frustrated_count),
    0,
  );
  const maxResponseTimeP50 = includedData?.reduce((max, item) => Math.max(max, item.response_time_p50), 0);
  const maxResponseTimeP75 = includedData?.reduce((max, item) => Math.max(max, item.response_time_p75), 0);
  const maxResponseTimeP95 = includedData?.reduce((max, item) => Math.max(max, item.response_time_p95), 0);
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  useEffect(() => {
    setSortedData(query.data ? sortData(query.data, sortBy) : undefined);
  }, [query.data, sortBy]);

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        sortedData?.filter((item) =>
          searchTerms.every(
            (term) => item.path.toLowerCase().includes(term) || item.method.toLowerCase().includes(term),
          ),
        ),
      );
    } else {
      setSearchResult(sortedData);
    }
    setPage(1);
  }, [search, sortedData]);

  useEffect(() => {
    setSearch("");
    setPage(1);
  }, [app]);

  useEffect(() => {
    if (modalEndpointId && query.data) {
      const endpoint = query.data.find((item) => item.id === parseInt(modalEndpointId));
      if (endpoint?.id !== modalEndpoint?.id) {
        setModalEndpoint(endpoint);
      }
    }
  }, [modalEndpointId, query.data]);

  const openEndpointModal = (endpoint: Endpoint) => {
    setModalEndpoint(endpoint);
    if (app && endpoint.id) {
      navigate({ pathname: `/performance/${app.slug}/${endpoint.id}`, search: searchParams.toString() });
    }
  };

  const closeEndpointModal = () => {
    setModalEndpoint(undefined);
    if (app && modalEndpointId) {
      navigate({ pathname: `/performance/${app.slug}`, search: searchParams.toString() });
    }
  };

  const sortData = (data: PerformanceEndpointsTableItem[], sortBy: SortBy) => {
    const sort = (a: PerformanceEndpointsTableItem, b: PerformanceEndpointsTableItem) => {
      const sign = sortBy.direction === "asc" ? 1 : -1;

      if (a.excluded && !b.excluded) {
        return 1;
      } else if (!a.excluded && b.excluded) {
        return -1;
      }

      if (sortBy.column === "endpoint") {
        return a.path.localeCompare(b.path) * sign;
      }

      if (a.total_request_count > 0 && b.total_request_count === 0) {
        return 1 * sign;
      } else if (a.total_request_count === 0 && b.total_request_count > 0) {
        return -1 * sign;
      }

      if (sortBy.column === "apdex_score") {
        return (a.apdex_score - b.apdex_score) * sign;
      } else if (sortBy.column === "slow_requests") {
        return (
          (a.apdex_tolerated_count + a.apdex_frustrated_count - b.apdex_tolerated_count - b.apdex_frustrated_count) *
          sign
        );
      } else if (sortBy.column === "response_time_p50") {
        return (a.response_time_p50 - b.response_time_p50) * sign;
      } else if (sortBy.column === "response_time_p75") {
        return (a.response_time_p75 - b.response_time_p75) * sign;
      } else if (sortBy.column === "response_time_p95") {
        return (a.response_time_p95 - b.response_time_p95) * sign;
      } else {
        return 0;
      }
    };
    return data?.slice().sort(sort);
  };

  const allItemsExcluded = searchResult?.every((item) => item.excluded);
  let tableFooter;
  if (query.isSuccess && searchResult) {
    if (query.data.length > itemsPerPage) {
      const showingItemsFrom = itemsPerPage * (page - 1) + 1;
      const showingItemsTo = Math.min(itemsPerPage * page, searchResult.length);
      const numberOfPages = Math.ceil(searchResult.length / itemsPerPage);
      tableFooter = (
        <Stack direction="horizontal">
          <div className="small text-muted">
            Showing endpoints {showingItemsFrom.toLocaleString()}-{showingItemsTo.toLocaleString()} of{" "}
            {searchResult.length.toLocaleString()}
          </div>
          <div className="ms-auto">
            <Pagination page={page} numberOfPages={numberOfPages} setPage={setPage} />
          </div>
        </Stack>
      );
    } else {
      const excludedItems = searchResult.filter((item) => item.excluded);
      if (excludedItems.length > 0 && !allItemsExcluded) {
        tableFooter = (
          <div className="small">
            {!showExcluded ? (
              <Button variant="link" onClick={() => setShowExcluded(true)}>
                <FontAwesomeIcon icon={faEye} className="me-icon" />
                Show {excludedItems.length} excluded endpoint
                {excludedItems.length > 1 ? "s" : ""}
              </Button>
            ) : (
              <Button variant="link" onClick={() => setShowExcluded(false)}>
                <FontAwesomeIcon icon={faEyeSlash} className="me-icon" />
                Hide {excludedItems.length} excluded endpoint
                {excludedItems.length > 1 ? "s" : ""}
              </Button>
            )}
          </div>
        );
      }
    }
  }

  const searchHeader = !(query.isSuccess && query.data.length === 0) ? (
    <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search endpoints" />
  ) : undefined;

  if (!app) {
    return;
  }

  return (
    <div className="PerformanceEndpointsTable">
      <EndpointModal endpoint={modalEndpoint} onHide={closeEndpointModal} initialTab="response-times" />
      <EndpointConfigModal
        app={app}
        endpoint={endpointConfigModalEndpoint}
        setEndpoint={setEndpointConfigModalEndpoint}
        refresh={refresh}
      />
      <TableCard
        header={searchHeader}
        footer={tableFooter}
        hover={searchResult && searchResult.length > 0}
        responsive
        className="align-middle"
      >
        <thead>
          <tr>
            <th style={{ width: 40 }}></th>
            <ColumnHeader name="endpoint" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Endpoint
            </ColumnHeader>
            <ColumnHeader name="apdex_score" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
              Apdex score
            </ColumnHeader>
            <ColumnHeader name="slow_requests" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
              Slow requests
            </ColumnHeader>
            <ColumnHeader name="response_time_p50" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              p50
            </ColumnHeader>
            <ColumnHeader name="response_time_p75" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              p75
            </ColumnHeader>
            <ColumnHeader name="response_time_p95" className="text-nowrap" sortBy={sortBy} setSortBy={setSortBy}>
              p95
            </ColumnHeader>
            <th style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>
          {searchResult
            ?.filter(
              (item) =>
                !item.excluded ||
                showExcluded ||
                allItemsExcluded ||
                (query.isSuccess && query.data.length > itemsPerPage),
            )
            ?.slice(itemsPerPage * (page - 1), itemsPerPage * page)
            .map((item) => (
              <tr
                key={`${item.method} ${item.path}`}
                className={classNames("cursor-pointer", { excluded: item.excluded })}
                onClick={(e) => {
                  const cell = (e.target as HTMLElement).closest("td");
                  if (!cell?.classList.contains("TableCellDropdown")) {
                    openEndpointModal(item);
                  }
                }}
              >
                <td style={{ width: 40 }}>
                  <FontAwesomeIcon icon={item.excluded ? faBan : faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td className="endpoint-column">
                  <MethodPath method={item.method} path={item.path} />
                </td>
                <TableCellWithBar
                  showBar={
                    !item.excluded &&
                    item.total_request_count > 0 &&
                    (sortBy.column === "apdex_score" || sortBy.column === "endpoint")
                  }
                  value={1 - item.apdex_score}
                  maxValue={maxInverseApdexScore}
                >
                  {item.total_request_count > 0
                    ? item.apdex_score.toLocaleString(undefined, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })
                    : "-"}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "slow_requests"}
                  value={item.apdex_tolerated_count + item.apdex_frustrated_count}
                  maxValue={maxSlowRequestCount}
                >
                  <Tooltip
                    tooltip={
                      <div className="text-start">
                        <div>
                          <div className="very-small text-muted-tooltip">
                            {item.target_response_time_ms.toLocaleString()} -{" "}
                            {(4 * item.target_response_time_ms).toLocaleString()} ms
                          </div>
                          <div>
                            {item.apdex_tolerated_count.toLocaleString()} request
                            {item.apdex_tolerated_count !== 1 ? "s" : ""}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div className="very-small text-muted-tooltip">
                            More than {(4 * item.target_response_time_ms).toLocaleString()} ms
                          </div>
                          <div>
                            {item.apdex_frustrated_count.toLocaleString()} request
                            {item.apdex_frustrated_count !== 1 ? "s" : ""}
                          </div>
                        </div>
                      </div>
                    }
                    condition={item.apdex_tolerated_count > 0 || item.apdex_frustrated_count > 0}
                    placement="left"
                  >
                    <span className="px-1 mx-n1">
                      {(item.apdex_tolerated_count + item.apdex_frustrated_count).toLocaleString()}{" "}
                      <span className="text-muted very-small">/ {item.total_request_count.toLocaleString()}</span>
                    </span>
                  </Tooltip>
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "response_time_p50"}
                  value={item.response_time_p50}
                  maxValue={maxResponseTimeP50}
                >
                  {item.total_request_count > 0 ? formatResponseTime(item.response_time_p50) : "-"}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "response_time_p75"}
                  value={item.response_time_p75}
                  maxValue={maxResponseTimeP75}
                >
                  {item.total_request_count > 0 ? formatResponseTime(item.response_time_p75) : "-"}
                </TableCellWithBar>
                <TableCellWithBar
                  showBar={!item.excluded && sortBy.column === "response_time_p95"}
                  value={item.response_time_p95}
                  maxValue={maxResponseTimeP95}
                >
                  {item.total_request_count > 0 ? formatResponseTime(item.response_time_p95) : "-"}
                </TableCellWithBar>
                <TableCellDropdown>
                  <Dropdown.Item as="button" onClick={() => openEndpointModal(item)}>
                    <FontAwesomeIcon icon={faChartSimple} fixedWidth className="text-secondary" />
                    Endpoint insights
                  </Dropdown.Item>
                  {item.id && (
                    <>
                      <Dropdown.Item as="button" onClick={() => setEndpointId(item.id || undefined)}>
                        <FontAwesomeIcon icon={faFilter} fixedWidth className="text-secondary" />
                        Filter to endpoint
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `/request-log/${app?.slug}?endpoint=${item.id}&min_response_time=${item.target_response_time_ms}`,
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faScroll} fixedWidth className="text-secondary" />
                        Slow request log
                      </Dropdown.Item>
                    </>
                  )}
                  {isTeamAdminOrDemo && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setEndpointConfigModalEndpoint({
                            method: item.method,
                            path: item.path,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" />
                        Endpoint settings
                      </Dropdown.Item>
                    </>
                  )}
                </TableCellDropdown>
              </tr>
            ))}
          {query.isSuccess && query.data.length > 0 && search && searchResult && searchResult.length === 0 && (
            <tr>
              <td style={{ width: 40 }}></td>
              <td colSpan={6} className="text-center py-6">
                Your search didn't match any endpoints.
              </td>
              <td style={{ width: 40 }}></td>
            </tr>
          )}
          {query.isSuccess && query.data.length === 0 && (
            <tr>
              <td colSpan={8} className="text-center py-6">
                There haven't been any requests in the selected period.
              </td>
            </tr>
          )}
          {query.isPending &&
            [...Array(endpointCount)].map((e, i) => (
              <Placeholder key={i} as="tr" animation="glow">
                <td style={{ width: 40 }}>
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2 text-very-muted" />
                </td>
                <td style={{ width: "40%" }}>
                  <Placeholder
                    xs={3}
                    size="lg"
                    bg="primary"
                    className="me-4"
                    style={{ width: "3.5rem", borderRadius: "0.375rem" }}
                  />
                  <Placeholder xs={8} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td>
                  <Placeholder xs={7} />
                </td>
                <td>
                  <Placeholder xs={7} />
                </td>
                <td>
                  <Placeholder xs={7} />
                </td>
                <td style={{ width: 40 }}></td>
              </Placeholder>
            ))}
        </tbody>
      </TableCard>
    </div>
  );
}

function PerformanceTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <div className="PerformanceTeaser">
        <PageHeader buttons={pageHeaderButtons}>
          <>Performance</>
          <span className="text-muted">No apps set up yet</span>
        </PageHeader>
        <TeaserCard icon={faBoltLightning} iconStyle={{ right: "-40px" }}>
          <h2>Understand the performance of your API</h2>
          <p className="mt-4">
            The performance dashboard provides insights into response times of your API and each endpoint. It uses the
            industry-standard Apdex score to measure user satisfaction with response times, allowing you to set
            thresholds for satisfactory response times for different API endpoints.
          </p>
          <ul className="my-4">
            <li>
              <b>Apdex score:</b> Measure user satisfaction with response times, based on your defined thresholds
            </li>
            <li>
              <b>Response times:</b> Track percentiles of response times for the whole API and each endpoint
            </li>
          </ul>
        </TeaserCard>
      </div>
    </MainLayout>
  );
}

function Performance() {
  const { apps, activeApp, backendClient } = useGlobal();
  const [showFilterPane, setShowFilterPane] = useState(false);
  const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
  const {
    app,
    appSlug,
    env,
    setEnv,
    period,
    setPeriod,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
  } = useFilters();
  const [showApdexScoreExplanationModal, setShowApdexScoreExplanationModal] = useState(false);
  const [responseTimeMetricPercentile, setResponseTimeMetricPercentile] = useState(
    localStorage.getItem("performanceResponseTimeMetricPercentile") || "p95",
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;

  const metricsQueryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
    period,
  };
  const metricsQuery = useQuery({
    queryKey: ["performanceMetrics", metricsQueryParams],
    queryFn: () => backendClient!.performance.getPerformanceMetrics(metricsQueryParams),
    enabled: !!backendClient && !!app,
  });
  const responseTimeMetric =
    responseTimeMetricPercentile === "p50"
      ? metricsQuery.data?.response_time_p50
      : responseTimeMetricPercentile === "p75"
        ? metricsQuery.data?.response_time_p75
        : metricsQuery.data?.response_time_p95;
  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  useEffect(() => {
    if (app) {
      document.title = `Performance - ${app.name} - Apitally`;
    } else {
      document.title = `Performance - Apitally`;
    }
  }, [app]);

  useEffect(() => {
    localStorage.setItem("performanceResponseTimeMetricPercentile", responseTimeMetricPercentile);
  }, [responseTimeMetricPercentile]);

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <PerformanceTeaser />;
  } else if (!appSlug) {
    if (activeApp) {
      return (
        <MainLayout>
          <Navigate to={`/performance/${activeApp.slug}`} />
        </MainLayout>
      );
    } else {
      return (
        <MainLayout>
          <Navigate to={`/performance/${apps[0].slug}`} />
        </MainLayout>
      );
    }
  } else if (!app) {
    return (
      <MainLayout>
        <Navigate to="/performance" />
      </MainLayout>
    );
  }

  const filterCount =
    (consumerId ? 1 : 0) +
    (consumerGroupId ? 1 : 0) +
    (endpointId ? 1 : 0) +
    (endpointGroupId ? 1 : 0) +
    (method ? 1 : 0) +
    (statusCode ? 1 : 0);
  const filterCountBadge =
    filterCount > 0 ? (
      <Badge pill bg="secondary" className="ms-3">
        {filterCount}
      </Badge>
    ) : undefined;
  const pageHeaderButtons = [
    <PeriodDropdown
      key="period-dropdown"
      align="end"
      period={period}
      setPeriod={setPeriod}
      setShowCustomPeriodModal={setShowCustomPeriodModal}
    />,
    <AppEnvDropdown key="app-env-dropdown" appEnv={env} setAppEnv={setEnv} />,
    <Button key="filter-button" variant="outline-light" onClick={() => setShowFilterPane(true)}>
      <CustomIcon src="/icons/filter-regular.svg" className="me-lg-icon text-secondary" />
      <span className="d-none d-lg-inline">Filter</span>
      {filterCountBadge}
    </Button>,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => setShowFilterPane(true)}>
        <FontAwesomeIcon icon={faFilter} fixedWidth />
        Filter
        {filterCountBadge}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Select period</Dropdown.Header>
      <PeriodDropdownItems period={period} setPeriod={setPeriod} />
      <Dropdown.Item active={isCustomPeriod(period)} onClick={() => setShowCustomPeriodModal(true)}>
        Custom
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Filter by environment</Dropdown.Header>
      <AppEnvDropdownItems appEnv={env} setAppEnv={setEnv} />
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Performance">
        <ApdexScoreExplanationModal show={showApdexScoreExplanationModal} setShow={setShowApdexScoreExplanationModal} />
        <FilterOffcanvas show={showFilterPane} setShow={setShowFilterPane} />
        <CustomPeriodModal show={showCustomPeriodModal} setShow={setShowCustomPeriodModal} />
        <PageHeader breakpoint="lg" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Performance</>
          <AppDropdown onSelect={(key) => key !== null && navigate(`/performance/${key}`)} />
        </PageHeader>
        <PlanLimitsExceededAlert />
        <FilterBadges />
        <Card className="my-4 mb-lg-6 bt">
          <Card.Body className="p-0">
            <Container fluid>
              <Row>
                <Col sm={12} lg={6} className="metric-item">
                  <div className="d-flex">
                    <Metric
                      label="Apdex score"
                      value={
                        metricsQuery.data?.total_request_count ? (
                          <>
                            {metricsQuery.data?.apdex_score.toLocaleString(undefined, {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })}
                            <ApdexScoreFace apdexScore={metricsQuery.data?.apdex_score} className="ms-2 small" />
                          </>
                        ) : (
                          "-"
                        )
                      }
                      help={
                        <>
                          Measures user satisfaction with response times
                          <br />
                          <small>
                            <i>Click to learn more</i>
                          </small>
                        </>
                      }
                      helpOnClick={() => setShowApdexScoreExplanationModal(true)}
                    />
                    <PerformanceMetricDropdown>
                      <Dropdown.Item onClick={() => setShowApdexScoreExplanationModal(true)}>
                        <FontAwesomeIcon icon={faQuestionCircle} fixedWidth className="text-secondary" />
                        What is an Apdex score?
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate(`/apps?editApp=${app.id}`)}>
                        <FontAwesomeIcon icon={faPenToSquare} fixedWidth className="text-secondary" />
                        Configure threshold
                      </Dropdown.Item>
                    </PerformanceMetricDropdown>
                  </div>
                  <div className="mt-3">
                    <ApdexScoreLineChart displayTitle={false} style={{ height: "200px" }} />
                  </div>
                </Col>
                <Col sm={12} lg={6} className="metric-item">
                  <div className="d-flex">
                    <Metric
                      label={`Response time (${responseTimeMetricPercentile})`}
                      value={
                        metricsQuery.data?.total_request_count && responseTimeMetric !== undefined
                          ? formatResponseTime(responseTimeMetric)
                          : "-"
                      }
                      help="Percentile of response times across all requests"
                    />
                    <PerformanceMetricDropdown>
                      <Dropdown.Header>Switch percentile</Dropdown.Header>
                      {["p50", "p75", "p95"].map((percentile) => (
                        <Dropdown.Item
                          key={percentile}
                          active={responseTimeMetricPercentile === percentile}
                          onClick={() => setResponseTimeMetricPercentile(percentile)}
                        >
                          {percentile}
                        </Dropdown.Item>
                      ))}
                    </PerformanceMetricDropdown>
                  </div>
                  <div className="mt-3">
                    <ResponseTimeLineChart displayTitle={false} style={{ height: "200px" }} />
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <PerformanceEndpointsTable />
      </div>
    </MainLayout>
  );
}

function PerformanceWithFilterContext() {
  return (
    <FilterContextProvider>
      <Performance />
    </FilterContextProvider>
  );
}

export default PerformanceWithFilterContext;
