import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { toast } from "react-toastify";

type ApiKeyReadOnlyFormFieldProps = {
  apiKey: string;
};

function ApiKeyReadOnlyFormField({ apiKey }: ApiKeyReadOnlyFormFieldProps) {
  const copyApiKey = async () => {
    await navigator.clipboard.writeText(apiKey);
    toast.success("API key copied to clipboard!");
  };

  return (
    <InputGroup>
      <BootstrapForm.Control
        type="text"
        value={apiKey}
        readOnly
        onFocus={(e) => e.currentTarget.select()}
        onClick={(e) => {
          e.currentTarget.select();
          e.preventDefault();
        }}
      />
      <Button variant="light" onClick={() => copyApiKey()}>
        <FontAwesomeIcon icon={faCopy} />
      </Button>
    </InputGroup>
  );
}

export default ApiKeyReadOnlyFormField;
