import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import Placeholder from "react-bootstrap/Placeholder";

import SentryIconButton from "../components/SentryIconButton";
import TableCard from "../components/TableCard";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import "./ServerErrorsTable.css";

type ServerErrorsTableProps = {
  endpoint: Endpoint;
  limit?: number;
  autoExpand?: boolean;
};

function ServerErrorsTable({ endpoint, limit = 100, autoExpand = false }: ServerErrorsTableProps) {
  const { backendClient } = useGlobal();
  const { app, period, env, consumerId, consumerGroupId, statusCode } = useFilters();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    method: endpoint.method,
    path: endpoint.path,
    consumerId,
    consumerGroupId,
    statusCode,
    period,
    limit,
  };
  const query = useQuery({
    queryKey: ["serverErrorsTable", queryParams],
    queryFn: () => backendClient!.errors.getServerErrorsTable(queryParams),
    enabled: !!backendClient && !!app,
  });

  useEffect(() => {
    if (autoExpand && query.data) {
      setExpandedIndex(query.data.length === 1 ? 0 : null);
    }
  }, [query.data]);

  return (
    <TableCard
      hover={query.isSuccess && query.data.length > 0}
      borderTop={false}
      className="ServerErrorsTable border-light"
    >
      <thead>
        <tr>
          <th style={{ width: 34 }}></th>
          <th>Exception</th>
          <th style={{ width: 52 }}></th>
        </tr>
      </thead>
      <tbody>
        {query.data?.map((item, index) => {
          const typeName = item.type.split(".").slice(-1)[0];
          const expanded = expandedIndex === index;
          return (
            <React.Fragment key={index}>
              <tr key={index} className="cursor-pointer" onClick={() => setExpandedIndex(!expanded ? index : null)}>
                <td style={{ width: 34 }}>
                  <FontAwesomeIcon
                    icon={expanded ? faCaretDown : faCaretRight}
                    className="ms-2 text-very-muted"
                    style={{ position: "relative", top: 1 }}
                  />
                </td>
                <td>
                  <div>
                    <span title={item.type}>{typeName}</span>
                    {item.error_count > 0 && <span className="ms-2 small text-very-muted">{item.error_count}x</span>}
                  </div>
                  <div className="small text-muted">{item.msg}</div>
                </td>
                <td style={{ width: 52 }} className="align-middle">
                  {!!item.sentry_event_id_captured && <SentryIconButton url={item.sentry_url} />}
                </td>
              </tr>
              {expanded && (
                <tr key={`${index}-expanded`} className="no-hover">
                  <td colSpan={3} className="p-0">
                    <div className="traceback">
                      <pre className="p-4 my-0 code">{item.traceback}</pre>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
        {query.isPending && (
          <Placeholder as="tr" animation="glow">
            <td style={{ width: 34 }}></td>
            <td>
              <div>
                <Placeholder xs={3} />
              </div>
              <div className="small text-muted">
                <Placeholder xs={6} />
              </div>
            </td>
            <td style={{ width: 40 }}></td>
          </Placeholder>
        )}
        {query.isSuccess && query.data.length === 0 && (
          <tr>
            <td colSpan={3} className="py-6 text-center">
              No exceptions captured in the selected period.
            </td>
          </tr>
        )}
      </tbody>
    </TableCard>
  );
}

export default ServerErrorsTable;
