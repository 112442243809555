import { ReactSVG } from "react-svg";

import "./CustomIcon.css";

type CustomIconProps = {
  src: string;
  className?: string;
  style?: React.CSSProperties;
  fixedWidth?: boolean;
};

function CustomIcon({ src, className, fixedWidth = false, style }: CustomIconProps) {
  return (
    <ReactSVG
      src={src}
      className={`CustomIcon ${fixedWidth ? "fw" : ""} ${className || ""}`}
      wrapper="span"
      style={style}
    />
  );
}

export default CustomIcon;
