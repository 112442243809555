import Button from "react-bootstrap/Button";

import CustomIcon from "./CustomIcon";
import "./SentryIconButton.css";
import Tooltip from "./Tooltip";

type SentryIconButtonProps = {
  url?: string | null;
};

function SentryIconButton({ url }: SentryIconButtonProps) {
  return (
    <Tooltip
      tooltip={url ? "View issue in Sentry" : "Configure the Sentry project name for this app to view issue in Sentry"}
      placement="left"
      delay={250}
    >
      <span>
        <Button
          variant="link"
          className="SentryIconButton"
          onClick={(e) => {
            if (url) {
              window.open(url, "_blank");
            }
            e.stopPropagation();
          }}
          disabled={!url}
        >
          <CustomIcon src="/icons/sentry-logo.svg" className="text-secondary" />
        </Button>
      </span>
    </Tooltip>
  );
}

export default SentryIconButton;
