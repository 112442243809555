import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { TeamUserItem } from "../backend";
import DetailsModalContent from "../components/DetailsModalContent";

type TeamUserDetailsModalProps = {
  user?: TeamUserItem;
  setUser: React.Dispatch<React.SetStateAction<TeamUserItem | undefined>>;
};

function TeamUserDetailsModal({ user, setUser }: TeamUserDetailsModalProps) {
  const lastLoginDate = user?.last_login_at ? DateTime.fromISO(user?.last_login_at) : undefined;
  const memberSinceDate = user?.member_since ? DateTime.fromISO(user?.member_since) : undefined;

  return (
    <Modal show={user !== undefined} onHide={() => setUser(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>User details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DetailsModalContent
          labelValueMap={{
            "ID": user?.id,
            "Name": user?.name,
            "Email address": user?.email,
            "Role": capitalize(user?.role),
            "Last login": (
              <span title={lastLoginDate?.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                {lastLoginDate?.toRelative()}
              </span>
            ),
            "Member since": (
              <span className="text-nowrap" title={memberSinceDate?.toLocaleString(DateTime.DATETIME_FULL)}>
                {memberSinceDate?.toLocaleString(DateTime.DATE_FULL)}
              </span>
            ),
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setUser(undefined)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TeamUserDetailsModal;
