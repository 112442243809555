import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleRight,
  faDownload,
  faEye,
  faFingerprint,
  faHeading,
  faQuestion,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, startCase } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

import { RequestLogItem, RequestLogItemDetails } from "../backend";
import CodeBlock from "../components/CodeBlock";
import CustomIcon from "../components/CustomIcon";
import FilterBadge from "../components/FilterBadge";
import Method from "../components/Method";
import MetricChip from "../components/MetricChip";
import ModalSection from "../components/ModalSection";
import Path from "../components/Path";
import SentryIconButton from "../components/SentryIconButton";
import StatusCodeBadge from "../components/StatusCodeBadge";
import TableCard from "../components/TableCard";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { formatDataSize } from "../utils/numbers";
import "./RequestLogItemModal.css";

type ParametersTableProps = {
  pathParameters?: [string, string][];
  searchParameters?: [string, string][];
};

function ParametersTable({ pathParameters, searchParameters }: ParametersTableProps) {
  return (
    <TableCard borderTop={false} className="align-middle">
      <tbody>
        {pathParameters?.map(([key, value], i) => (
          <tr key={`path-${i}`}>
            <td className="ps-3 text-nowrap" style={{ width: 220 }}>
              <CustomIcon src="/icons/slash-forward-solid.svg" fixedWidth className="me-2 text-very-muted" />
              {key}
            </td>
            <td className="text-wrap">{value}</td>
          </tr>
        ))}
        {searchParameters?.map(([key, value], i) => (
          <tr key={`search-${i}`}>
            <td className="ps-3 text-nowrap" style={{ width: 220 }}>
              <FontAwesomeIcon icon={faQuestion} fixedWidth className="me-2 text-very-muted" />
              {key}
            </td>
            <td className="text-wrap">{value}</td>
          </tr>
        ))}
      </tbody>
    </TableCard>
  );
}

function HeadersTable({ headers }: { headers: [string, string][] }) {
  const formatHeaderName = (name: string) => {
    return name
      .split("-")
      .map((part) => startCase(part))
      .join("-");
  };

  return (
    <TableCard borderTop={false} className="align-middle overflow-auto" style={{ maxHeight: 368 }}>
      <tbody>
        {headers.map(([key, value], i) => (
          <tr key={i}>
            <td className="ps-3 text-nowrap" style={{ width: 290 }}>
              {formatHeaderName(key)}:
            </td>
            <td className="text-wrap">{value}</td>
          </tr>
        ))}
      </tbody>
    </TableCard>
  );
}

type CodeBlockProps = {
  children?: string;
  language?: string;
};

function LightCodeBlock({ children, language }: CodeBlockProps) {
  const style = {
    ...vscDarkPlus,
    punctuation: {
      color: "#adb5bd",
    },
    operator: {
      color: "#adb5bd",
    },
    property: {
      color: "#6c757d",
    },
    string: {
      color: "#cc3333",
    },
    number: {
      color: "#0b9797",
    },
    boolean: {
      color: "#186faf",
    },
  };
  return (
    <CodeBlock language={language} className="border rounded" style={style}>
      {children || ""}
    </CodeBlock>
  );
}

type RequestLogItemModalTabProps = {
  item?: RequestLogItem;
  itemDetails?: RequestLogItemDetails;
};

function RequestLogItemModalDetailsTab({ item }: RequestLogItemModalTabProps) {
  const splitPath = (path: string) => {
    return path.replace(/(^\/|\/$)/, "").split("/");
  };

  const extractPathParameters = (pathPattern: string, actualPath: string) => {
    const patternSegments = splitPath(pathPattern).reverse();
    const actualSegments = splitPath(actualPath).reverse();
    const params: [string, string][] = [];
    patternSegments.forEach((segment, index) => {
      if (segment.startsWith("{") && segment.endsWith("}")) {
        const paramName = segment.slice(1, -1);
        const paramValue = actualSegments[index];
        if (!paramName.includes("{") && !paramName.includes("}") && paramValue) {
          params.unshift([paramName, paramValue]);
        }
      }
    });
    return params;
  };

  const url = item ? new URL(item.url) : undefined;
  const pathParameters = url && item?.path ? extractPathParameters(item.path, url.pathname) : undefined;
  const searchParameters = url ? Array.from(url.searchParams.entries()) : undefined;

  return (
    <>
      <ModalSection title="Metrics">
        <div className="mb-n4 me-n4">
          <MetricChip
            label="Request size"
            icon="/icons/download-regular.svg"
            value={item ? formatDataSize(item.request_size / 1000) : "-"}
          />
          <MetricChip
            label="Response size"
            icon="/icons/upload-regular.svg"
            value={item ? formatDataSize(item.response_size / 1000) : "-"}
          />
          <MetricChip
            label="Response time"
            icon="/icons/stopwatch-regular.svg"
            value={
              item ? `${(item.response_time * 1000).toLocaleString(undefined, { maximumFractionDigits: 0 })} ms` : "-"
            }
          />
        </div>
      </ModalSection>
      {(!isEmpty(pathParameters) || !isEmpty(searchParameters)) && (
        <ModalSection title="Parameters" className="mt-6">
          <ParametersTable pathParameters={pathParameters} searchParameters={searchParameters} />
        </ModalSection>
      )}
    </>
  );
}

function RequestLogItemModalHeadersTab({ itemDetails }: RequestLogItemModalTabProps) {
  return (
    <>
      {itemDetails && itemDetails.request_headers.length > 0 && (
        <ModalSection title="Request headers">
          <HeadersTable headers={itemDetails?.request_headers} />
        </ModalSection>
      )}
      {itemDetails && itemDetails.response_headers.length > 0 && (
        <ModalSection title="Response headers" className="mt-6">
          <HeadersTable headers={itemDetails?.response_headers} />
        </ModalSection>
      )}
    </>
  );
}

function RequestLogItemModalRequestBodyTab({ itemDetails }: RequestLogItemModalTabProps) {
  return <LightCodeBlock language="json">{itemDetails?.request_body || "<empty>"}</LightCodeBlock>;
}

function RequestLogItemModalResponseBodyTab({ itemDetails }: RequestLogItemModalTabProps) {
  return <LightCodeBlock language="json">{itemDetails?.response_body || "<empty>"}</LightCodeBlock>;
}

function RequestLogItemModalExceptionTab({ itemDetails }: RequestLogItemModalTabProps) {
  const typeName = itemDetails?.exception_type?.split(".").slice(-1)[0] || "";
  return (
    <Card className="border-light">
      <Card.Header className="border-light d-flex flex-row align-items-center">
        <div>
          <div>
            <span className="fw-bold" title={itemDetails?.exception_type}>
              {typeName}
            </span>
          </div>
          <div className="small text-muted">{itemDetails?.exception_message}</div>
        </div>
        {itemDetails?.sentry_event_id_captured && (
          <div className="ms-auto pe-2">
            <SentryIconButton url={itemDetails?.sentry_url} />
          </div>
        )}
      </Card.Header>
      <Card.Body className="p-0">
        <pre className="p-4 my-0 code">{itemDetails?.exception_stacktrace}</pre>
      </Card.Body>
    </Card>
  );
}

type RequestLogItemModalProps = {
  item?: RequestLogItem | RequestLogItemDetails;
  setItem: (item?: RequestLogItem | RequestLogItemDetails) => void;
};

function RequestLogItemModal({ item, setItem }: RequestLogItemModalProps) {
  const { backendClient } = useGlobal();
  const { app } = useFilters();
  const [activeTab, setActiveTab] = useState("details");

  const itemHasDetails = !!item && "request_body" in item;
  const queryParams = {
    requestUuid: item?.request_uuid || "",
    appId: app?.id || 0,
    timestamp: item?.timestamp || "",
  };
  const query = useQuery({
    queryKey: ["requestLogItemDetails", queryParams],
    queryFn: () => backendClient!.requestLog.getRequestLogItemDetails(queryParams),
    enabled: !!backendClient && !!app && !!item && !itemHasDetails,
    placeholderData: undefined,
  });
  const itemDetails = itemHasDetails ? item : query.data;

  useEffect(() => {
    setActiveTab("details");
  }, [item]);

  const selectTab = useCallback((key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  }, []);

  const tabs = [
    {
      eventKey: "details",
      title: "Details",
      icon: faEye,
      content: <RequestLogItemModalDetailsTab item={item} itemDetails={itemDetails} />,
    },
    {
      eventKey: "headers",
      title: "Headers",
      icon: faHeading,
      content: itemDetails && (itemDetails.request_headers.length > 0 || itemDetails.response_headers.length > 0) && (
        <RequestLogItemModalHeadersTab item={item} itemDetails={itemDetails} />
      ),
    },
    {
      eventKey: "request-body",
      title: "Request body",
      icon: faDownload,
      content: itemDetails?.request_body && <RequestLogItemModalRequestBodyTab item={item} itemDetails={itemDetails} />,
    },
    {
      eventKey: "response-body",
      title: "Response body",
      icon: faUpload,
      content: itemDetails?.response_body && (
        <RequestLogItemModalResponseBodyTab item={item} itemDetails={itemDetails} />
      ),
    },
    {
      eventKey: "exception",
      title: "Exception",
      icon: faUpload,
      content: itemDetails?.exception_type && <RequestLogItemModalExceptionTab item={item} itemDetails={itemDetails} />,
    },
  ].filter((tab) => tab.content);

  const url = item ? new URL(item.url) : undefined;
  const dt = item ? DateTime.fromISO(item.timestamp) : undefined;

  return (
    <Modal className="RequestLogItemModal tabs" size="xl" show={item !== undefined} onHide={() => setItem(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="app-env">
            {app?.name}
            <FontAwesomeIcon icon={faAngleRight} className="ms-2 me-2 text-very-muted small" />
            {item?.app_env}
          </div>
          <div className="d-flex flex-row flex-nowrap justify-content-start align-items-center gap-3 mt-1">
            <StatusCodeBadge code={item?.status_code || 0} text={item?.status_text} />
            <span className="method-path-search text-muted">
              <Method method={item?.method} />{" "}
              <span className="text-body">
                <Path path={url?.pathname} />
              </span>{" "}
              <span>{url?.search}</span>
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-1 px-0">
        <Tab.Container activeKey={activeTab} onSelect={selectTab}>
          <Nav variant="tabs">
            {tabs.map((tab) => (
              <Nav.Item key={tab.eventKey} title={tab.title}>
                <Nav.Link eventKey={tab.eventKey}>
                  <FontAwesomeIcon icon={tab.icon} fixedWidth className="d-inline d-sm-none" />
                  <span className="d-none d-sm-inline">{tab.title}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            <div className="d-flex flex-wrap gap-2 p-4 pb-0">
              <FilterBadge icon={faClock} value={dt?.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)} />
              <FilterBadge icon="/icons/server-regular.svg" label="Host" value={url?.hostname} />
              {item?.client_ip && (
                <FilterBadge
                  icon="/icons/globe-regular.svg"
                  label="Client"
                  value={
                    <>
                      {item?.client_ip} <span className="fw-normal">({item?.client_country_name})</span>
                    </>
                  }
                />
              )}
              {item?.consumer_name && <FilterBadge icon={faFingerprint} label="Consumer" value={item.consumer_name} />}
            </div>
            {tabs.map((tab) => (
              <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey}>
                {tab.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
}

export default RequestLogItemModal;
