import { useQuery } from "@tanstack/react-query";
import {
  ChartData,
  Chart as ChartJS,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-luxon";
import { rgba } from "polished";
import { memo } from "react";
import { Line } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import ChartContainer from "../components/ChartContainer";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(Filler, LinearScale, TimeSeriesScale, LineController, LineElement, PointElement, Title, Tooltip);

type ErrorRatesLineChartProps = {
  endpoint?: Endpoint;
  enabled?: boolean;
  displayTitle?: boolean;
};

function ErrorRatesLineChart({ endpoint, enabled = true, displayTitle = true }: ErrorRatesLineChartProps) {
  const { backendClient } = useGlobal();
  const {
    app,
    period,
    setPeriod,
    resetPeriod,
    env,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
  } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method: endpoint?.method || method,
    path: endpoint?.path,
    statusCode,
    period,
  };
  const query = useQuery({
    queryKey: ["errorRatesChart", queryParams],
    queryFn: () => backendClient!.errors.getErrorRatesChart(queryParams),
    enabled: !!backendClient && !!app && enabled,
  });

  let chart;
  if (query.isSuccess && query.data.length > 0) {
    const dangerColor = getColor("danger");
    const colors = new Map([
      ["Client error", rgba(dangerColor, 0.6)],
      ["Server error", rgba(dangerColor, 1.0)],
    ]);
    const chartOptions = merge(
      getChartOptions({
        labels: query.data[0].time_windows,
        title: displayTitle ? "Client and server error rates" : undefined,
        setPeriod,
        resetPeriod,
      }),
      {
        scales: {
          y: {
            ticks: {
              format: {
                style: "percent" as const,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              },
            },
          },
        },
        tooltips: { intersect: false },
        interaction: { mode: "index" as const, intersect: false },
        plugins: {
          tooltip: {
            position: "nearest" as const,
            callbacks: {
              label: function (context: TooltipItem<"line">) {
                const value = context.parsed.y.toLocaleString(undefined, {
                  style: "percent",
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 2,
                });
                return ` ${context.dataset.label}: ${value}`;
              },
            },
          },
        },
      },
    );
    const chartData: ChartData<"line"> = {
      labels: query.data[0].time_windows,
      datasets: query.data.map((dataset) => ({
        label: dataset.error_type,
        data: dataset.error_rates,
        borderColor: colors.get(dataset.error_type),
        backgroundColor: colors.get(dataset.error_type),
        pointStyle: false,
        borderWidth: 0,
        fill: true,
      })),
    };
    chart = <Line data={chartData} options={chartOptions} />;
  }

  return <ChartContainer>{chart}</ChartContainer>;
}

export default memo(ErrorRatesLineChart);
