import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/esm/languages/prism/bash";
import csharp from "react-syntax-highlighter/dist/esm/languages/prism/csharp";
import java from "react-syntax-highlighter/dist/esm/languages/prism/java";
import javascript from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import json from "react-syntax-highlighter/dist/esm/languages/prism/json";
import python from "react-syntax-highlighter/dist/esm/languages/prism/python";
import rust from "react-syntax-highlighter/dist/esm/languages/prism/rust";
import yaml from "react-syntax-highlighter/dist/esm/languages/prism/yaml";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

import "./CodeBlock.css";
import CopyToClipboardWithTooltip from "./CopyToClipboardWithTooltip";

SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("csharp", csharp);
SyntaxHighlighter.registerLanguage("java", java);
SyntaxHighlighter.registerLanguage("javascript", javascript);
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("rust", rust);
SyntaxHighlighter.registerLanguage("yaml", yaml);

export type CodeBlockProps = {
  title?: string;
  language?: string;
  copyButton?: boolean;
  style?: { [key: string]: React.CSSProperties };
  className?: string;
  children: string;
};

const CodeBlock = ({
  title,
  language = "plaintext",
  copyButton = true,
  style = vscDarkPlus,
  className,
  children,
}: CodeBlockProps) => {
  return (
    <div className={classNames("CodeBlock", className)} title={title}>
      <SyntaxHighlighter language={language} style={style}>
        {children}
      </SyntaxHighlighter>
      {copyButton && (
        <span className="copy-button-container">
          <CopyToClipboardWithTooltip text={children} tooltipPlacement="left">
            <button className="copy-button">
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </CopyToClipboardWithTooltip>
        </span>
      )}
    </div>
  );
};

export default CodeBlock;
