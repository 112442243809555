import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faEye, faFileCsv, faFilter, faFingerprint, faRotateRight, faScroll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InfiniteData, useInfiniteQuery, useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { GetRequestLogResponse, RequestLogItem } from "./backend";
import RequestLogTimelineBarChart from "./charts/RequestLogTimelineBarChart";
import AppDropdown from "./components/AppDropdown";
import AppEnvDropdown, { AppEnvDropdownItems } from "./components/AppEnvDropdown";
import CustomIcon from "./components/CustomIcon";
import FilterBadges from "./components/FilterBadges";
import FilterOffcanvas from "./components/FilterOffcanvas";
import MainLayout from "./components/MainLayout";
import Method from "./components/Method";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import Path from "./components/Path";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import StatusCodeBadge from "./components/StatusCodeBadge";
import TableCard from "./components/TableCard";
import TableCellDropdown from "./components/TableCellDropdown";
import TeaserCard from "./components/TeaserCard";
import UpgradePlanAlert from "./components/UpgradePlanAlert";
import FilterContextProvider, { useFilters } from "./contexts/FilterContext";
import { useGlobal } from "./contexts/GlobalContext";
import CustomPeriodModal from "./modals/CustomPeriodModal";
import RequestLogExportModal from "./modals/RequestLogExportModal";
import RequestLogItemModal from "./modals/RequestLogItemModal";
import "./RequestLog.css";
import { formatRelativeDate } from "./utils/datetime";
import { formatDataSize } from "./utils/numbers";
import { getStartOfPeriod, isCustomPeriod, parseCustomPeriod } from "./utils/period";

function RequestLogTeaser() {
  const { launchDemo, teamPlan, apps } = useGlobal();
  const { app } = useFilters();
  const navigate = useNavigate();
  const requestLoggingAllowed =
    !!teamPlan && (teamPlan.monthly_request_log_quota === null || teamPlan.monthly_request_log_quota > 0);
  const appFrameworkSlug = app?.framework ? app.framework.toLowerCase().replace(" ", "-") : undefined;

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <div className="TrafficTeaser">
        <PageHeader buttons={pageHeaderButtons}>
          <>Request log</>
          {apps?.length ? (
            <AppDropdown onSelect={(key) => key !== null && navigate(`/request-log/${key}`)} />
          ) : (
            <span className="text-muted">No apps set up yet</span>
          )}
        </PageHeader>
        {!requestLoggingAllowed && (
          <UpgradePlanAlert className="mb-4 mb-lg-6">
            Request log is a premium feature that is not included in the {teamPlan?.name}.
            <br />
            Please upgrade your team's plan to use this feature and enjoy additional benefits!
          </UpgradePlanAlert>
        )}
        <TeaserCard icon={faScroll} iconStyle={{ right: "-120px" }}>
          <h2>Log, find and inspect each request and response</h2>
          <p className="mt-4">
            Drill down from insights to individual requests or use powerful filtering to understand exactly how
            consumers have interacted with your API. Take troubleshooting and customer support for your API to the next
            level.
          </p>
          <p>Logged requests include:</p>
          <ul className="my-4">
            <li>Full request URLs</li>
            <li>Headers (with masking)</li>
            <li>Response status</li>
            <li>Request & response body (up to 50 KB each)</li>
            <li>Consumer identifier</li>
            <li>Metrics (such as response time)</li>
          </ul>
          <p className="mb-0">
            Our SDKs allow you to control exactly what information is included in the logs and easily apply masking
            where needed. You can log up to 5 million requests per month with a 15 day retention period.
          </p>
          {requestLoggingAllowed && appFrameworkSlug && (
            <>
              <p className="mt-4">
                To use this feature, enable request logging in your application. Check out our setup guide to get
                started.
              </p>
              <div className="mt-6">
                <Button
                  variant="primary"
                  onClick={() =>
                    window.open(
                      `https://docs.apitally.io/frameworks/${appFrameworkSlug}#configure-request-logging`,
                      "_blank",
                    )
                  }
                >
                  Setup guide
                </Button>
              </div>
            </>
          )}
        </TeaserCard>
      </div>
    </MainLayout>
  );
}

type RequestLogTableRowProps = {
  item: RequestLogItem;
  env?: string;
  openItemModal: (item: RequestLogItem) => void;
};

export function RequestLogTableRow({ item, env, openItemModal }: RequestLogTableRowProps) {
  const timestamp = DateTime.fromISO(item.timestamp);
  return (
    <tr
      className="cursor-pointer"
      onClick={(e) => {
        const cell = (e.target as HTMLElement).closest("td");
        if (!cell?.classList.contains("TableCellDropdown")) {
          openItemModal(item);
        }
      }}
    >
      <td style={{ width: 40 }}>
        <FontAwesomeIcon icon={faClock} className="ms-2 text-very-muted" />
      </td>
      <td style={{ width: 110 }}>
        <div className="text-nowrap" title={timestamp.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}>
          {timestamp.toLocaleString(DateTime.TIME_WITH_SECONDS)}
        </div>
        <div className="small text-muted text-nowrap">{formatRelativeDate(timestamp)}</div>
      </td>
      <td style={{ width: 100 }}>
        <StatusCodeBadge code={item.status_code} text={item.status_text} />
      </td>
      <td className="request-column">
        <div className="text-nowrap url">
          <Method method={item.method} /> <Path path={new URL(item.url).pathname} />{" "}
          <span className="text-muted">{new URL(item.url).search}</span>
        </div>
        <div className="small text-muted d-flex flex-row gap-3">
          {!(env === item.app_env) && (
            <span className="text-nowrap">
              <CustomIcon src="/icons/layer-group-regular.svg" className="text-very-muted me-icon" />
              {item.app_env}
            </span>
          )}
          {item.consumer_name && (
            <span className="text-nowrap">
              <FontAwesomeIcon icon={faFingerprint} className="text-very-muted me-icon" />
              {item.consumer_name}
            </span>
          )}
          {!item.consumer_name && item.client_country_name && (
            <span className="text-nowrap">
              <CustomIcon src="/icons/globe-regular.svg" className="text-very-muted me-icon" />
              {item.client_country_name}
            </span>
          )}
          {item.request_size > 0 && (
            <span className="text-nowrap">
              <CustomIcon src="/icons/download-regular.svg" className="text-very-muted me-icon" />
              {formatDataSize(item.request_size / 1000)}
            </span>
          )}
          {item.response_size > 0 && (
            <span className="text-nowrap">
              <CustomIcon src="/icons/upload-regular.svg" className="text-very-muted me-icon" />
              {formatDataSize(item.response_size / 1000)}
            </span>
          )}
          <span className="text-nowrap">
            <CustomIcon src="/icons/stopwatch-regular.svg" className="text-very-muted me-icon" />
            {(item.response_time * 1000).toFixed(0)} ms
          </span>
        </div>
      </td>
      <TableCellDropdown>
        <Dropdown.Item as="button" onClick={() => openItemModal(item)}>
          <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
          Request details
        </Dropdown.Item>
      </TableCellDropdown>
    </tr>
  );
}

function RequestLog() {
  const { backendClient, apps, activeApp, teamPlan } = useGlobal();
  const {
    app,
    appSlug,
    env,
    setEnv,
    period,
    setPeriod,
    resetPeriod,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
    url,
    requestBody,
    responseBody,
    minRequestSize,
    maxRequestSize,
    minResponseSize,
    maxResponseSize,
    minResponseTime,
    maxResponseTime,
  } = useFilters();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showFilterPane, setShowFilterPane] = useState(false);
  const [modalItem, setModalItem] = useState<RequestLogItem>();
  const timestampFromSearchParams = DateTime.fromMillis(parseFloat(searchParams.get("t") || ""));
  const [timestamp, setTimestamp] = useState<DateTime | undefined>(
    timestampFromSearchParams.isValid ? timestampFromSearchParams : undefined,
  );
  const navigate = useNavigate();
  const { requestUuid } = useParams();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;
  const itemsPerPage = 100;
  const startOfPeriod = getStartOfPeriod(period);
  const requestLoggingAllowed =
    !!teamPlan && (teamPlan.monthly_request_log_quota === null || teamPlan.monthly_request_log_quota > 0);

  useEffect(() => {
    if (app) {
      document.title = `Request log - ${app.name} - Apitally`;
    } else {
      document.title = `Request log - Apitally`;
    }
  }, [app]);

  useEffect(() => {
    if (appSlug && timestamp?.toMillis().toString() !== (searchParams.get("t") || undefined)) {
      setSearchParams((searchParams) => {
        if (timestamp?.isValid) {
          searchParams.set("t", timestamp.toMillis().toString());
        } else {
          searchParams.delete("t");
        }
        return searchParams;
      });
    }
  }, [appSlug, timestamp]);

  const effectiveTimestamp = timestamp || parseCustomPeriod(period)?.end;
  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
    url,
    requestBody,
    responseBody,
    minRequestSize,
    maxRequestSize,
    minResponseSize,
    maxResponseSize,
    minResponseTime,
    maxResponseTime,
    maxTimestamp: effectiveTimestamp,
  };
  const query = useInfiniteQuery({
    queryKey: ["requestLogTable", queryParams],
    queryFn: ({ pageParam }) => {
      return backendClient!.requestLog.getRequestLog({
        ...queryParams,
        page: pageParam,
        limit: itemsPerPage,
        maxTimestamp: effectiveTimestamp?.toISO(),
      });
    },
    getNextPageParam: (lastPage, allPages, lastPageParam) =>
      lastPage.length === itemsPerPage ? lastPageParam + 1 : undefined,
    initialPageParam: 1,
    enabled: !!backendClient && !!app && requestLoggingAllowed,
  });

  const itemQueryParams = {
    requestUuid: requestUuid || "",
    appId: app?.id || 0,
  };
  const itemQuery = useQuery({
    queryKey: ["requestLogItemDetails", queryParams],
    queryFn: () => backendClient!.requestLog.getRequestLogItemDetails(itemQueryParams),
    enabled: !!backendClient && !!app && !!requestUuid,
    placeholderData: undefined,
  });

  useEffect(() => {
    if (requestUuid && requestUuid !== modalItem?.request_uuid && itemQuery.data) {
      setModalItem(itemQuery.data);
    }
  }, [requestUuid, itemQuery.data]);

  useEffect(() => {
    if (app && requestUuid && !modalItem) {
      navigate({ pathname: `/request-log/${app.slug}`, search: searchParams.toString() });
    }
  }, [modalItem, requestUuid, app]);

  const openItemModal = (item: RequestLogItem) => {
    setModalItem(item);
    if (app) {
      navigate({ pathname: `/request-log/${app.slug}/${item.request_uuid}`, search: searchParams.toString() });
    }
  };

  const refresh = async () => {
    queryClient.setQueryData(
      ["requestLogTable", queryParams],
      (oldData: InfiniteData<GetRequestLogResponse, unknown> | undefined) => {
        if (!oldData) return undefined;
        return {
          ...oldData,
          pages: oldData.pages.slice(0, 1),
          pageParams: oldData.pageParams.slice(0, 1),
        };
      },
    );
    await queryClient.refetchQueries({ type: "active" });
  };

  const resetTimestampAndPeriod = () => {
    setTimestamp(undefined);
    resetPeriod();
  };

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <RequestLogTeaser />;
  } else if (!appSlug) {
    if (activeApp) {
      return (
        <MainLayout>
          <Navigate to={`/request-log/${activeApp.slug}`} />
        </MainLayout>
      );
    } else {
      return (
        <MainLayout>
          <Navigate to={`/request-log/${apps[0].slug}`} />
        </MainLayout>
      );
    }
  } else if (!app) {
    return (
      <MainLayout>
        <Navigate to="/request-log" />
      </MainLayout>
    );
  } else if (!requestLoggingAllowed || !app.has_request_log) {
    return <RequestLogTeaser />;
  }

  const filterCount =
    (consumerId ? 1 : 0) +
    (consumerGroupId ? 1 : 0) +
    (endpointId ? 1 : 0) +
    (endpointGroupId ? 1 : 0) +
    (method ? 1 : 0) +
    (statusCode ? 1 : 0) +
    (url ? 1 : 0) +
    (requestBody ? 1 : 0) +
    (responseBody ? 1 : 0) +
    (minRequestSize || maxRequestSize ? 1 : 0) +
    (minResponseSize || maxResponseSize ? 1 : 0) +
    (minResponseTime || maxResponseTime ? 1 : 0);
  const filterCountBadge =
    filterCount > 0 ? (
      <Badge pill bg="secondary" className="ms-3">
        {filterCount}
      </Badge>
    ) : undefined;
  const periods = ["1h", "3h", "6h", "12h", "24h", "2d", "7d"];
  const pageHeaderButtons = [
    <PeriodDropdown
      key="period-dropdown"
      align="end"
      period={period}
      setPeriod={setPeriod}
      periods={periods}
      setShowCustomPeriodModal={setShowCustomPeriodModal}
    />,
    <AppEnvDropdown key="app-env-dropdown" appEnv={env} setAppEnv={setEnv} />,
    <Button key="filter-button" variant="outline-light" onClick={() => setShowFilterPane(true)}>
      <CustomIcon src="/icons/filter-regular.svg" className="me-lg-icon text-secondary" />
      <span className="d-none d-lg-inline">Filter</span>
      {filterCountBadge}
    </Button>,
    <Button key="export-button" variant="outline-light" onClick={() => setShowExportModal(true)}>
      <CustomIcon src="/icons/file-csv-regular.svg" className="text-secondary me-lg-icon" />
      <span className="d-none d-lg-inline">Export</span>
    </Button>,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Item as="button" disabled={isLoading} onClick={() => setShowExportModal(true)}>
        <FontAwesomeIcon icon={faFileCsv} fixedWidth />
        Export
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => setShowFilterPane(true)}>
        <FontAwesomeIcon icon={faFilter} fixedWidth />
        Filter
        {filterCountBadge}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Select period</Dropdown.Header>
      <PeriodDropdownItems period={period} setPeriod={setPeriod} />
      <Dropdown.Item active={isCustomPeriod(period)} onClick={() => setShowCustomPeriodModal(true)}>
        Custom
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Filter by environment</Dropdown.Header>
      <AppEnvDropdownItems appEnv={env} setAppEnv={setEnv} />
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="RequestLog">
        <FilterOffcanvas show={showFilterPane} setShow={setShowFilterPane} requestLogFilters />
        <CustomPeriodModal show={showCustomPeriodModal} setShow={setShowCustomPeriodModal} />
        <RequestLogExportModal show={showExportModal} onHide={() => setShowExportModal(false)} />
        <RequestLogItemModal item={modalItem} setItem={setModalItem} />
        <PageHeader breakpoint="lg" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Request log</>
          <AppDropdown onSelect={(key) => key !== null && navigate(`/request-log/${key}`)} />
        </PageHeader>
        <PlanLimitsExceededAlert />
        {teamPlan?.request_log_quota_status === "ok" && teamPlan.request_log_quota_percent_used > 0.8 && (
          <UpgradePlanAlert dismissible dismissKey="requestLogQuotaAlertDismissed" className="mb-4 mb-lg-6">
            Your team has used more than 80% of the monthly request log quota of the {teamPlan?.name}.
          </UpgradePlanAlert>
        )}
        {teamPlan?.request_log_quota_status === "exceeded" && (
          <UpgradePlanAlert variant="danger" className="mb-4 mb-lg-6">
            Your team has exceeded the monthly request log quota of the {teamPlan?.name}. Ingestion of logged requests
            will be suspended until the quota resets at the beginning of next month or you upgrade your plan.
          </UpgradePlanAlert>
        )}
        <FilterBadges />
        <TableCard
          header={<RequestLogTimelineBarChart timestamp={timestamp} setTimestamp={setTimestamp} />}
          secondHeader={
            effectiveTimestamp ? (
              <div className="small text-muted text-center">
                <span>
                  Showing requests until {effectiveTimestamp?.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}.
                </span>
                <a role="button" className="btn-link ms-2" onClick={resetTimestampAndPeriod}>
                  Reset
                </a>
              </div>
            ) : undefined
          }
          fetchNextPage={query.hasNextPage ? query.fetchNextPage : undefined}
          isFetchingNextPage={query.isFetchingNextPage}
          responsive
          className="my-4 align-top"
        >
          <thead>
            <tr>
              <th style={{ width: 40 }}></th>
              <th style={{ width: 110 }}>Time</th>
              <th style={{ width: 100 }}>Status</th>
              <th>Request</th>
              <th style={{ width: 40 }}></th>
            </tr>
          </thead>
          <tbody>
            {query.data?.pages.map((group, i) => (
              <React.Fragment key={i}>
                {group.map((item, index) => {
                  const timestamp = DateTime.fromISO(item.timestamp);
                  const showDivider =
                    index > 0 &&
                    startOfPeriod &&
                    DateTime.fromISO(group[index - 1].timestamp) >= startOfPeriod &&
                    timestamp < startOfPeriod;

                  return (
                    <React.Fragment key={item.request_uuid}>
                      {showDivider && (
                        <tr style={{ height: "unset", background: "var(--main-bg-color)" }}>
                          <td colSpan={5} className="small text-muted text-center py-2">
                            Older requests
                          </td>
                        </tr>
                      )}
                      <RequestLogTableRow item={item} env={env?.slug} openItemModal={openItemModal} />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ))}
            {query.isSuccess && query.data.pages[0].length === 0 && (
              <tr className="no-hover">
                <td style={{ width: 40 }}></td>
                <td colSpan={3} className="text-center py-6">
                  There are no logged requests yet.
                </td>
                <td style={{ width: 40 }}></td>
              </tr>
            )}
            {query.isPending &&
              [...Array(3)].map((e, i) => (
                <Placeholder key={i} as="tr" animation="glow">
                  <td style={{ width: 40 }}>
                    <FontAwesomeIcon icon={faClock} className="ms-2 text-very-muted" />
                  </td>
                  <td style={{ width: 120 }}>
                    <div>
                      <Placeholder xs={8} />
                    </div>
                    <div className="small text-muted">
                      <Placeholder xs={6} />
                    </div>
                  </td>
                  <td>
                    <Placeholder bg="primary" style={{ width: 40 }} />
                  </td>
                  <td>
                    <div>
                      <Placeholder xs={6} />
                    </div>
                    <div className="small text-muted">
                      <Placeholder xs={4} />
                    </div>
                  </td>
                  <td style={{ width: 40 }}></td>
                </Placeholder>
              ))}
          </tbody>
        </TableCard>
      </div>
    </MainLayout>
  );
}

function RequestLogWithFilterContext() {
  return (
    <FilterContextProvider>
      <RequestLog />
    </FilterContextProvider>
  );
}

export default RequestLogWithFilterContext;
