import { Field, FieldProps, Form, Formik } from "formik";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import FilterBadges from "../components/FilterBadges";
import Select from "../components/Select";
import UpgradePlanAlert from "../components/UpgradePlanAlert";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { parseCustomPeriod } from "../utils/period";

type TrafficExportModalProps = {
  show: boolean;
  onHide: () => void;
};

function TrafficExportModal({ show, onHide }: TrafficExportModalProps) {
  const { backendClient, teamPlan } = useGlobal();
  const { app, env, period, consumerId, consumerGroupId, endpointId, endpointGroupId, method, statusCode } =
    useFilters();
  const exportAllowed = !!teamPlan?.features.data_export;

  const parsedCustomPeriod = parseCustomPeriod(period);
  const periodIsLong =
    period === "12m" || (parsedCustomPeriod && parsedCustomPeriod.end.diff(parsedCustomPeriod.start, "days").days > 31);
  const initialIntervals = periodIsLong ? "days" : "hours";
  const intervalOptions = [
    { label: "Hours", value: "hours", isDisabled: periodIsLong },
    { label: "Days", value: "days" },
    { label: "Months", value: "months" },
  ];
  const groupByOptions = [
    { label: "Endpoint", value: "endpoint" },
    { label: "Consumer", value: "consumer" },
    { label: "Status code", value: "status_code" },
  ];

  return (
    <Formik
      initialValues={{
        intervals: initialIntervals,
        group_by: "",
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          if (backendClient && app) {
            const promise = backendClient.traffic.exportTrafficCsv({
              appId: app.id,
              intervals: values.intervals as "hours" | "days" | "months",
              groupBy: values.group_by
                ? (values.group_by.split(",") as ("endpoint" | "consumer" | "status_code")[])
                : [],
              appEnv: env?.slug,
              period,
              consumerId,
              consumerGroupId,
              endpointId,
              endpointGroupId,
              method,
              statusCode,
            });
            toast.promise(promise, {
              pending: "Exporting data...",
              success: "Data exported!",
              error: "Failed to export data.",
            });
            const downloadUrl = await promise;
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.click();
          }
          onHide();
          resetForm();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ touched, handleSubmit, isSubmitting, resetForm, isValid }) => (
        <Modal
          show={show}
          onHide={onHide}
          onExited={() => resetForm()}
          backdrop={Object.keys(touched).length > 0 ? "static" : true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Export traffic data as CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!exportAllowed && (
              <UpgradePlanAlert>
                Data export is a premium feature that is not included in the {teamPlan?.name}. Please upgrade your
                team's plan to export data and enjoy additional benefits!
              </UpgradePlanAlert>
            )}
            <div className="mb-4">
              <FilterBadges showAll />
            </div>
            <Form onSubmit={handleSubmit}>
              <BootstrapForm.Group controlId="formIntervals">
                <BootstrapForm.Label>Intervals</BootstrapForm.Label>
                <Field name="intervals">
                  {({ field }: FieldProps<string>) => (
                    <Select options={intervalOptions} isDisabled={!exportAllowed} {...field} />
                  )}
                </Field>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formGroupBy" className="mt-4">
                <BootstrapForm.Label>Group by</BootstrapForm.Label>
                <Field name="group_by">
                  {({ field }: FieldProps<string>) => (
                    <Select<string, true>
                      options={groupByOptions}
                      placeholder="Select fields"
                      isClearable
                      isMulti
                      isDisabled={!exportAllowed}
                      {...field}
                    />
                  )}
                </Field>
              </BootstrapForm.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()} disabled={isSubmitting || !isValid || !exportAllowed}>
              Export
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

export default TrafficExportModal;
