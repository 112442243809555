import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { ApiError } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";

type TeamFormModalProps = {
  teamId?: number;
  setTeamId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

interface TeamFormValues {
  name: string;
}

function TeamFormModal({ teamId, setTeamId }: TeamFormModalProps) {
  const { backendClient, refreshTeams, setActiveTeam } = useGlobal();
  const [initialFormValues, setInitialFormValues] = useState<TeamFormValues>();
  const queryClient = useQueryClient();

  const queryParams = { teamId: teamId || 0 };
  const query = useQuery({
    queryKey: ["team", queryParams],
    queryFn: () => backendClient!.teams.getTeam(queryParams),
    enabled: !!backendClient && !!teamId,
    placeholderData: undefined,
  });

  useEffect(() => {
    if (teamId === 0) {
      setInitialFormValues({ name: "" });
    } else if (teamId && query.data) {
      setInitialFormValues({ name: query.data.name });
    } else {
      setInitialFormValues(undefined);
    }
  }, [teamId, query.data]);

  const validate = async (values: TeamFormValues) => {
    const errors: Partial<TeamFormValues> = {};
    if (!values.name || values.name.trim() === "") {
      errors.name = "Required";
    }
    return errors;
  };

  const createOrUpdateTeam = async (values: TeamFormValues) => {
    if (backendClient) {
      if (teamId) {
        const promise = backendClient.teams.updateTeam({
          teamId,
          requestBody: {
            name: values.name,
          },
        });
        toast.promise(promise, {
          pending: "Updating team...",
          success: "Team updated!",
          error: "Failed to update team.",
        });
        await promise;
      } else {
        const promise = backendClient.teams.createTeam({
          requestBody: {
            name: values.name,
          },
        });
        toast.promise(promise, {
          pending: "Creating team...",
          success: "Team created!",
          error: "Failed to create team.",
        });
        return await promise;
      }
    }
  };

  if (!initialFormValues) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validate}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        try {
          const newTeamId = await createOrUpdateTeam(values);
          if (teamId !== 0) {
            queryClient.invalidateQueries({ queryKey: ["team", { teamId }] });
          }
          setTeamId(undefined);
          const refreshedTeams = await refreshTeams();
          const newTeam = refreshedTeams?.find((team) => team.id === newTeamId);
          if (newTeam) {
            setActiveTeam(newTeam);
          }
        } catch (e) {
          if (e instanceof ApiError && e.status === 409) {
            setFieldError("name", "A team with this name already exists.");
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched, resetForm }) => (
        <Modal
          show={teamId !== undefined}
          onHide={() => setTeamId(undefined)}
          onExited={() => resetForm()}
          backdrop={Object.keys(touched).length > 0 ? "static" : true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{teamId ? "Rename team" : "Create team ✨"}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <BootstrapForm.Group controlId="formName">
                <BootstrapForm.Label>
                  Name <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="name">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Name of your team"
                      maxLength={64}
                      autoComplete="off"
                      isInvalid={!!errors.name && !!touched.name}
                      autoFocus={teamId === 0}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.name}</BootstrapForm.Control.Feedback>
                <BootstrapForm.Text muted>The team name must be unique.</BootstrapForm.Text>
              </BootstrapForm.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setTeamId(undefined)}>
                Discard
              </Button>
              <Button onClick={() => handleSubmit()} disabled={isSubmitting}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default TeamFormModal;
