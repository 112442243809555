import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import ApiKeyReadOnlyFormField from "../components/ApiKeyReadOnlyFormField";

type ApiKeyModalProps = {
  apiKey?: string;
  onHide: () => void;
};

function ApiKeyModal({ apiKey, onHide }: ApiKeyModalProps) {
  return (
    <Modal show={!!apiKey} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>API key created</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Below is your new API key. Please copy & store it securely.</p>
        <ApiKeyReadOnlyFormField apiKey={apiKey || ""} />
        <p className="small text-danger mt-4 mb-0">
          <strong>Important:</strong> This is the only time you'll see this API key. It cannot be retrieved again later.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ApiKeyModal;
